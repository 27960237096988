import { Component, Input, OnInit } from "@angular/core";
import { UIBoundingBox } from "@features/labeling/models/annotation";
import { BoundingBoxGroup } from "@features/labeling/models/annotation-group";
import { ObjectDetectionUILabel } from "@features/labeling/models/label";
import { AnnotationFactory } from "@features/labeling/services/annotation.factory";
import { ImageState, LabelingService } from "@features/labeling/services/labeling.service";
import { getAverageRectangleFromAnnotations } from "@features/labeling/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { ColorMeaning, LabelingColorService } from "../../services/labeling-color.service";
import { LabelingReviewAnnotationGroupService } from "../services/labeling-review-annotation-group.service";
import { LabelingReviewUpdateService } from "../services/labeling-review-update.service";


enum DisplayMode {
    REGIONS,
    BBOXES
}
@UntilDestroy()
@Component({
    selector: 'object-detection-region-selector',
    templateUrl: './object-detection-region-selector.component.html',
})
export class ObjectDetectionRegionSelectorComponent implements OnInit {
    @Input() imagePath: string;
    label: ObjectDetectionUILabel;
    onLabelChange$ = new Subject<ObjectDetectionUILabel>();
    displayMode: DisplayMode;

    constructor(
        private labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService,
        private labelingReviewUpdateService: LabelingReviewUpdateService,
        private labelingService: LabelingService,
        private labelingColorService: LabelingColorService,
        private annotationFactory: AnnotationFactory
    ) {
    }

    ngOnInit(): void {
        this.labelingReviewAnnotationGroupService.annotationGroupList$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.labelingReviewUpdateService.updatingReview$),
        ).subscribe(([regions, updatingReview]) => this.updateBboxToDisplay(regions as BoundingBoxGroup[], updatingReview))

        this.onLabelChange$.pipe(
            untilDestroyed(this),
            withLatestFrom(
                this.labelingReviewAnnotationGroupService.annotationGroupList$,
                this.labelingService.labelingTaskInfo$
            ),
        ).subscribe(([annotation, regions, task]) => {
            const bboxes = annotation.annotations as UIBoundingBox[];
            if (this.displayMode == DisplayMode.REGIONS) {
                this.labelingReviewAnnotationGroupService.selectAnnotationGroup(bboxes.map(l => l.selected).indexOf(true))
                if (bboxes.length > regions.length) {
                    this.labelingReviewAnnotationGroupService.addAnnotationGroup(new BoundingBoxGroup([bboxes[bboxes.length - 1]], task.minNbAnnotatorsPerRecord, task.objectDetectionIOUConflictThreshold,  true, true))
                }
            } else if (this.displayMode == DisplayMode.BBOXES) {
                if (bboxes.filter(bbox => bbox.annotator == undefined).length > 0) {
                    // if the reviewer draws a new box in a region we delete all other boxes of that region
                    this.labelingReviewAnnotationGroupService.updateAnnotationGroup([bboxes[bboxes.length-1]], true);
                } else {
                    const modifiedByReviewer = bboxes.length != regions.filter(r => r.selected)[0].annotations.length;
                    this.labelingReviewAnnotationGroupService.updateAnnotationGroup(bboxes, modifiedByReviewer);
                }
            }
        })
    }

    private updateBboxToDisplay(regions: BoundingBoxGroup[], updatingReview: boolean) {
        const selectedRegion = regions.filter(r => r.selected)[0];
        this.displayMode = selectedRegion && selectedRegion.annotations.length ? DisplayMode.BBOXES : DisplayMode.REGIONS;
        if (!updatingReview) {
            this.labelingColorService.setCurrentColorMeaning(
                this.displayMode == DisplayMode.BBOXES ? ColorMeaning.ANNOTATOR : ColorMeaning.IMAGE_STATE)
        }
        this.label = this.annotationFactory.fromAnnotations(
            this.displayMode == DisplayMode.BBOXES ?
                selectedRegion.annotations :
                regions.map(
                    (region, index) => this.createBboxRepresentingRegion(region, index + 1)
                )
        ) as ObjectDetectionUILabel;
    }

    private createBboxRepresentingRegion(region: BoundingBoxGroup, index: number): UIBoundingBox {
        if (region.annotations.length > 0) {
            return new UIBoundingBox(
                {
                    category: (region.hasConflictingCategory() || region.hasMissingCategory()) ? 'Object ' + index : region.getConsensusCategory(),
                    annotator: '',
                    pinned: true,
                    state: region.hasConflict() ? ImageState.CONFLICTING.toString() : region.hasMissingCategory() ? ImageState.MISSING_CATEGORY.toString(): ImageState.CONSENSUS.toString(),
                    ...getAverageRectangleFromAnnotations(region.annotations)
                })
        } else {
            return {} as UIBoundingBox;
        }
    }
}
