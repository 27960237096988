<ng-container *ngIf="!(this.labelingService.identifier$ | async)">
    <form [formGroup]="reviewForm" class="review-image-feed-header__controls">
        <div class="review-image-feed-header__input std-list-search-box">
            <span class="add-on"><i class="icon-dku-search"></i></span>
            <input type="text" formControlName="searchQuery" placeholder="Search by image path...">
        </div>
        <div class="btn-toggle">
            <label class="review-image-feed-header__toggle-label">
                <input type="radio" class="control" formControlName="reviewFetchType" [value]="ReviewFetchType.RECORDS_TO_REVIEW">
                <span class="btn btn--secondary w100">
                    To Review
                </span>
            </label>
            <label class="review-image-feed-header__toggle-label">
                <input type="radio" class="control" formControlName="reviewFetchType" [value]="ReviewFetchType.REVIEWED_RECORDS">
                <span class="btn btn--secondary w100">
                    Validated
                </span>
            </label>
        </div>
    </form>
    <div class="review-image-feed-header__wrapper" *ngIf="(labelingReviewImagePathService.reviewFetchType$ | async) as reviewFetchType">
        <ng-container *ngIf="(nbLabeledImages$ | async) as nbLabeledImages">
            <div class="review-image-feed-header__image-number">
                {{ nbLabeledImages }} {{ 'image' | plurify: nbLabeledImages }}
                <ng-container ng-switch-case [ngSwitch]="reviewFetchType">
                    <ng-container *ngSwitchCase="ReviewFetchType.REVIEWED_RECORDS">validated</ng-container>
                    <ng-container *ngSwitchCase="ReviewFetchType.RECORDS_TO_REVIEW">to review</ng-container>
                </ng-container>
            </div>
            <div class="mright16" *ngIf="reviewFetchType === ReviewFetchType.RECORDS_TO_REVIEW" [matTooltip]="'Validate annotations without conflicts'" [matTooltipPosition]="'above'">
                <button class="btn btn--outline btn--primary review-image-feed-header__button" (click)="openResolveDialog()" [disabled]="(resolveClass$ | async) != defaultAutoResolveClass || (resolvingConsensualRecords$ | async) || nbLabeledImages === 0">
                    <i class="dku-loader icon-spin dku-loader--inlined review-image-feed__loader"  *ngIf="resolvingConsensualRecords$ | async"></i>
                    <i [class]="resolveClass$ | async" class="review-image-feed-header__validate-icon" *ngIf="!(resolvingConsensualRecords$ | async)"></i>
                    <div *ngIf="(resolveClass$ | async) == defaultAutoResolveClass">Auto validate</div>
                    <div *ngIf="(resolveClass$ | async) != defaultAutoResolveClass">Validated</div>
                </button>
            </div>
        </ng-container>
    </div>
</ng-container>
