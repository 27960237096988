import { Injectable } from '@angular/core';
import { ChartCoordinates, LegendItemMapper } from '../../../interfaces';
import { EChartPrepareDataContext, PrepareData } from '../../echarts';
import { ChartScatterDataWrapper } from './chart-scatter-data-wrapper.model';
import { EChartBubblePoint } from './echart-bubble-point.interface';
import { ChartCoordinatesScatterExtras } from './chart-coordinates-scatter-extras.interface';

@Injectable({
    providedIn: 'root'
})
export class ScatterPrepareDataService extends PrepareData {
    public symbolLegendMapping: LegendItemMapper;
    public colorsLegendMapping: LegendItemMapper;

    constructor() {
        super();
    }

    private addLegendItemToCache(cache: LegendItemMapper, key: string, item: { index: number, label: string }) {
        if (cache.has(key)) {
            cache.get(key)!.push(item);
        } else {
            cache.set(key, [item]);
        }
    }

    prepareData(context: EChartPrepareDataContext<ChartScatterDataWrapper>): Array<EChartBubblePoint> {
        const dataSize = context.chartData.data.afterFilterRecords;
        const shapeScale = context.chartData.makeShapeScale();
        const hasUAColor = context.chartData.hasUAColor(context.chartDef);

        this.symbolLegendMapping = new Map();
        this.colorsLegendMapping = new Map();

        const data = Array.from(new Array(dataSize).keys()).map((_, index) => {
            const coord: ChartCoordinates<ChartCoordinatesScatterExtras> = {
                color: index,
                x: index,
                y: index,
                animation: 0,
                facet: 0,
                measure: 0,
                extras: {
                    size: index,
                    shape: index
                }
            };

            const colorLabel: string = context.chartData.getColorLabel(index, context.colorScale);

            // add point color to legend cache
            if (hasUAColor && context.chartData.data.values.color.str) {
                const colorData = context.chartData.data.values.color.str.data[index];
                const colorMap = context.chartData.data.values.color.str.sortedMapping[colorData];
                this.addLegendItemToCache(this.colorsLegendMapping, colorLabel, { index, label: colorMap.label });
            }

            // add point symbol to legend cache
            if (shapeScale) {
                const shapeValue = context.chartData.data.values.shape.str.data[index];
                const shapeUnicode = shapeScale && shapeScale(shapeValue).unicode;
                this.addLegendItemToCache(this.symbolLegendMapping, shapeUnicode, { index: index, label: context.chartData.data.values.shape.str.sortedMapping[shapeValue].label });
            }

            return {
                coord,
                value: context.chartData.getXValue(index),
                axisValues: context.chartData.getYValue(index),
                colorLabel,
                bubblesOptions: !context.chartDef.uaShape[0] && context.chartDef.bubblesOptions
            };
        }) as any;

        return data;
    }
}
