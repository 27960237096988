import { NgModule } from '@angular/core';
import { ComponentsModule } from '@shared/components/components.module';
import { DatasetViewExploreImagesComponent } from './explore-images/image-feed/dataset-view-explore-images.component';
import { ImageClassificationDatasetViewExploreImagesComponent } from './explore-images/image-feed/image-classification/image-classification-dataset-view-explore-images.component';
import { ObjectDetectionDatasetViewExploreImagesComponent } from './explore-images/image-feed/object-detection/object-detection-dataset-view-explore-images.component';
import { SimpleDatasetViewExploreImagesComponent } from './explore-images/image-feed/simple/simple-dataset-view-explore-images.component';
import { DatasetViewImagePreview } from './image-preview/dataset-view-image-preview.component';

@NgModule({
    imports: [
        ComponentsModule
    ],
    declarations: [
        DatasetViewExploreImagesComponent,
        DatasetViewImagePreview,
        ObjectDetectionDatasetViewExploreImagesComponent,
        ImageClassificationDatasetViewExploreImagesComponent,
        SimpleDatasetViewExploreImagesComponent
    ],
    exports: [],
    providers: []
})
export class DatasetViewModule { }
