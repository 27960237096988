import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { WT1Service, SerializedError } from 'dku-frontend-core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Workspace } from '@model-main/workspaces/workspace';
import { InterestsService } from '@shared/services';
import { ErrorMessage,
    EditTabs,
    WorkspacesService,
    WorkspaceDisplayService,
    WorkspaceModalService,
    WorkspaceSecurityService,
    WorkspacesNavigationService,
    WorkspaceLocalStorageService
} from '../../shared';
import { WorkspaceFilteringService } from './services';
import { ObjectFilters, ObjectSort, ObjectSortProperties } from './models';
import { WorkspaceListType } from '../../shared';


@UntilDestroy()
@Component({
    selector: 'workspace-page',
    templateUrl: './workspace-page.component.html',
    styleUrls: ['./workspace-page.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspacePageComponent {

    displayType: WorkspaceListType;
    canShareToWorkspace = false;
    objectFilters: ObjectFilters = {
        search: '',
        types: [],
        starred: false
    };
    objectSort: ObjectSort = {
        by: ObjectSortProperties.DISPLAY_NAME,
        reverse: false
    };

    readonly WorkspaceListType = WorkspaceListType;
    readonly workspace$ = this.workspacesService.getCurrentWorkspace().pipe(
        tap(workspace => this.onUpdateWorkspace(workspace))
    );
    readonly timeline$ = this.workspacesService.getCurrentTimeline();
    readonly filteredObjects$ = new BehaviorSubject<Workspace.WorkspaceObject[]>([]);
    readonly errorMessage$: Observable<ErrorMessage | null>;

    constructor(
        private workspacesService: WorkspacesService,
        private workspacesNavigation: WorkspacesNavigationService,
        private workspaceModalService: WorkspaceModalService,
        private workspaceFilteringService: WorkspaceFilteringService,
        private workspaceDisplayService: WorkspaceDisplayService,
        private workspaceSecurityService: WorkspaceSecurityService,
        private workspaceLocalStorageService: WorkspaceLocalStorageService,
        private WT1: WT1Service,
        private interestsService: InterestsService
    ) {
        this.errorMessage$ = this.workspacesService.getError().error$.pipe(
            map(error => {
                if (error?.httpCode === 403) {
                    const message = { title: 'Sorry', message: '', instructions: '' };
                    if ((<SerializedError>error).detailedMessage.includes('does not exist')) {
                        message.message = 'This workspace does not exist.';
                    } else {
                        message.message = 'You need to be a user of this workspace to see what \'s inside.';
                        message.instructions = 'Please ask the workspace administrator to grant you access.';
                    }
                    return message;
                }
                return null;
            })
        );

        this.displayType = workspaceLocalStorageService.getListType();

        const workspaceKey = this.workspacesNavigation.getParam('workspaceKey');
        if (workspaceKey) {
            this.workspaceLocalStorageService.saveLastOpenedWorkspace(workspaceKey);
            this.workspacesService.fetchWorkspace(workspaceKey).pipe(untilDestroyed(this)).subscribe();
        }
    }

    onUpdateWorkspace(workspace: Workspace | undefined): void {
        if (workspace) {
            this.updateObjects(workspace.workspaceObjects);
            this.canShareToWorkspace = this.workspaceSecurityService.canShareToWorkspace(workspace);
        }
    }

    onFiltersChanged(workspace: Workspace, filters: ObjectFilters): void {
        this.objectFilters = filters;
        this.updateObjects(workspace.workspaceObjects);
    }

    onSortChanged(workspace: Workspace, sort: ObjectSort): void {
        this.objectSort = sort;
        this.updateObjects(workspace.workspaceObjects);
    }

    updateObjects(objects: Workspace.WorkspaceObject[]): void {
        this.filteredObjects$.next(
            this.workspaceFilteringService.sortAndFilterObjects(objects, this.objectFilters, this.objectSort)
        );
    }

    openMembersModal(workspace: Workspace): void {
        this.workspaceModalService.editWorkspace(workspace, 'empty-workspace', [EditTabs.PERMISSIONS]);
    }

    openAddObjectModal(workspace: Workspace): void {
        this.workspaceModalService.addWorkspaceObject(workspace);
    }

    openRemoveObjectModal(workspace: Workspace, objectToDelete: Workspace.WorkspaceObject): void {
        this.workspaceModalService.removeWorkspaceObject(workspace, objectToDelete);
    }

    switchListType(type: WorkspaceListType): void {
        this.workspaceLocalStorageService.saveListType(type);
        this.displayType = type;
    }

    toggleStar(workspace: Workspace, object: Workspace.WorkspaceObject, status: boolean): void {
        if (!object.reference) {
            throw new Error('Invalid object to favorite');
        }
        this.interestsService.star([object.reference], status)
            .subscribe(() => {
                const index = workspace.workspaceObjects.indexOf(object);
                if (index >= 0) {
                    workspace.workspaceObjects[index] = { ...workspace.workspaceObjects[index], starred: status };
                }
                this.workspacesService.updateCurrentWorkspace(workspace);
                this.WT1.event('workspaces-star-object', {
                    from: `${this.displayType}-view`,
                    star: status,
                    objectType: this.workspaceDisplayService.getObjectType(object)
                });
            });
    }

}
