import { Injectable } from '@angular/core';
import { AxisDef, ChartFilter, FilterFacet } from 'generated-sources';
import { DATE_PART_TO_QUERY_STRING, DAYS_OF_WEEK_LABELS, QUERY_PARAM_PARSING_ERROR } from '../../constants';
import { DashboardFiltersUrlQueryParamParser } from './dashboard-filters-url-query-param-parser';

interface AugmentedChartFilter extends ChartFilter {
    $isFromUrlQuery?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class DashboardFiltersUrlParamsService {
    private filters: string | null;

    /**
     * Decodes an alphanumerical value from a `filters` URL query param.
     * @param value
     * @returns
     */
    static decodeAlphanumValue(value: string): string {
        return value.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
    }

    /**
     * Decodes a column name from a `filters` URL query param.
     * @param columnName
     * @returns
     */
    static decodeColumnName(columnName: string): string {
        return columnName.replace(/\\:/g, ':');
    }

    /**
     * Encodes an alphanumeric value to be used in a `filters` URL query param.
     * @param value    - the value to encode
     * @returns
     */
    private static encodeAlphanumValue(value: string): string {
        return `"${value.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}"`;
    }

    /**
     * Encode a column name to be used in a `filters` URL query param.
     * @param columnName
     * @returns
     */
    private static encodeColumnName(columnName: string): string {
        return columnName.replace(/:/g, '\\:');
    }

    setFiltersFromUrlParams(searchParams?: Record<string, string>) {
        // angular-ui encodes `/` as `~2F`
        // while waiting for an update to 3.1.0, we blacklist `~2F` values by replacing them with `/`
        const filters = (searchParams || {}).filters;
        if (filters) {
            this.filters = filters.replace(/~2F/g, '/');
        }
    }

    getFilters() {
        return this.filters;
    }

    setFilters(newFilters: string | null) {
        this.filters = newFilters;
    }

    /**
     * Returns all parsed filters from searchParams or error message
     * @param filtersQueryParamValue
     * @returns
     */
    getParsedFilters(filtersQueryParamValue: string): Partial<AugmentedChartFilter>[] {
        try {
            const parser = new DashboardFiltersUrlQueryParamParser();
            return parser.parse(filtersQueryParamValue);
        } catch {
            throw QUERY_PARAM_PARSING_ERROR;
        }
    }

    /**
     * Parses URL query parameters and retrieve compatible filters
     * @param filtersQueryParamValue    - Filters queried from URL
     * @param tileFilters               - Initial filters on the tile from current dashboard view
     * @return                          - return error message or the list of newly applicable filters
     */
    getFiltersTileFromURLParams(filtersQueryParamValue: string, tileFilters: ChartFilter[]): { filters: AugmentedChartFilter[], error: string } {
        let parsedFilters;
        const res = {
            filters: [] as AugmentedChartFilter[],
            error: ''
        };

        try {
            parsedFilters = this.getParsedFilters(filtersQueryParamValue);
        } catch (e) {
            res.error = e as string;
            return res;
        }

        const usableFilters: AugmentedChartFilter[] = [...tileFilters];
        for (const filter of parsedFilters) {
            const matchingFilterIdx = tileFilters.findIndex(tileFilter => this.isMatchingFilter(filter, tileFilter));
            if (matchingFilterIdx === -1) {
                res.error = `${filter.column || 'undefined'} does not match any existing filter and has been ignored`;
            } else {
                // make sure selectedValues & excludedValues do not live together.
                if (filter.excludeOtherValues !== usableFilters[matchingFilterIdx].excludeOtherValues) {
                    delete usableFilters[matchingFilterIdx].selectedValues;
                    delete usableFilters[matchingFilterIdx].excludedValues;
                }
                // override matching tile filter with data from parsing
                usableFilters[matchingFilterIdx] = { ...usableFilters[matchingFilterIdx], ...filter };
            }
        }

        res.filters = usableFilters;
        return res;
    }

    /**
     * Converts a list of filters into a string to be used as query param value.
     * @param tileFilters
     * @param areAllFiltersDeactivated
     * @param responseFacets
     * @returns
     */
    getFiltersQueryStringValue(tileFilters: ChartFilter[], areAllFiltersDeactivated = false, responseFacets: FilterFacet[] = []) {
        if (!tileFilters || !tileFilters.length) {
            return '';
        }
        const queryStringList = [];
        for (let i = 0, n = tileFilters.length; i < n; i++) {
            const queryString = this.getFacetQueryString(tileFilters[i], areAllFiltersDeactivated, responseFacets.length > i ? responseFacets[i] : undefined);
            queryStringList.push(queryString);
        }

        return queryStringList.join(';');
    }

    private getFacetQueryString(filter: ChartFilter, areAllFiltersDeactivated: boolean, responseFacet?: FilterFacet) {
        switch (filter.filterType) {
            case ChartFilter.FilterType.ALPHANUM_FACET:
                return this.getAlphanumFacetQueryString(filter, areAllFiltersDeactivated);
            case ChartFilter.FilterType.NUMERICAL_FACET:
                return this.getRangeFacetQueryString(filter, areAllFiltersDeactivated, responseFacet);
            case ChartFilter.FilterType.DATE_FACET:
                return this.getDateFacetQueryString(filter, areAllFiltersDeactivated, responseFacet);
            default:
                throw `Unknown filter facet type ${filter.filterType}`;
        }
    }

    /**
     * Returns the query string corresponding to the given alphanumerical filter.
     * @param filter
     * @param areAllFiltersDeactivated
     * @returns
     */
    private getAlphanumFacetQueryString(filter: ChartFilter, areAllFiltersDeactivated: boolean) {
        const key = DashboardFiltersUrlParamsService.encodeColumnName(filter.column);

        if (!filter.active || areAllFiltersDeactivated) {
            return `${key}:OFF`;
        }

        // Get the values list to use.
        const entries = filter.excludeOtherValues ? filter.selectedValues : filter.excludedValues;
        // Filter unselected values and encode values.
        const encodedValues: string[] = [];
        for (const [value, selected] of Object.entries(entries || {})) {
            if (selected) {
                encodedValues.push(DashboardFiltersUrlParamsService.encodeAlphanumValue(value));
            }
        }
        const joinedEncodedValues = encodedValues.join(',');
        // Add the 'not' operator if needed.
        return `${key}:${filter.excludeOtherValues ? joinedEncodedValues : `not(${joinedEncodedValues})`}`;
    }

    /**
     * Returns the query string corresponding to the given range filter (numerical or date).
     * @param filter
     * @param areAllFiltersDeactivated
     * @param responseFacets
     * @returns
     */
    private getRangeFacetQueryString(filter: ChartFilter, areAllFiltersDeactivated: boolean, responseFacet?: FilterFacet) {
        const key = `range(${DashboardFiltersUrlParamsService.encodeColumnName(filter.column)})`;

        if (!filter.active || areAllFiltersDeactivated) {
            return `${key}:OFF`;
        }

        const shouldIncludeMin = filter.minValue != null && (!responseFacet || filter.minValue > responseFacet.minValue);
        const minValue = shouldIncludeMin ? filter.minValue : null;
        const shouldIncludeMax = filter.maxValue != null && (!responseFacet || filter.maxValue < responseFacet.maxValue);
        const maxValue = shouldIncludeMax ? filter.maxValue : null;
        const queryStringMinValue = minValue == null ? '' : String(filter.filterType === ChartFilter.FilterType.DATE_FACET ? new Date(minValue).toISOString() : minValue);
        const queryStringMaxValue = maxValue == null ? '' : String(filter.filterType === ChartFilter.FilterType.DATE_FACET ? new Date(maxValue).toISOString() : maxValue);
        return `${key}:${queryStringMinValue}${maxValue !== null ? ',' : ''}${queryStringMaxValue}`;
    }

    /**
     * Returns the query string corresponding to the given date filter.
     * @param filter
     * @param areAllFiltersDeactivated
     * @returns
     */
    private getDateFacetQueryString(filter: ChartFilter, areAllFiltersDeactivated: boolean, responseFacet?: FilterFacet) {
        if (filter.dateFilterType === ChartFilter.DateFilterType.RANGE) {
            return this.getRangeFacetQueryString(filter, areAllFiltersDeactivated, responseFacet);
        } else if (filter.dateFilterType === ChartFilter.DateFilterType.YEAR || filter.dateFilterType === ChartFilter.DateFilterType.MONTH_OF_YEAR || filter.dateFilterType === ChartFilter.DateFilterType.WEEK_OF_YEAR || filter.dateFilterType === ChartFilter.DateFilterType.DAY_OF_MONTH || filter.dateFilterType === ChartFilter.DateFilterType.DAY_OF_WEEK || filter.dateFilterType === ChartFilter.DateFilterType.HOUR_OF_DAY || filter.dateFilterType === ChartFilter.DateFilterType.QUARTER_OF_YEAR) {
            return this.getDatePartQueryString(filter, areAllFiltersDeactivated);
        }
        throw `Unknown date filter type ${filter.dateFilterType || 'undefined'}`;
    }

    /**
     * Returns the query string corresponding to the given date range filter.
     * @param filter
     * @param areAllFiltersDeactivated
     * @returns
     */
    private getDatePartQueryString(filter: ChartFilter, areAllFiltersDeactivated: boolean) {
        if (!filter.dateFilterType) {
            return '';
        }
        // Due to an unfinished implementation of dateFilterPart the field may not be defined or outdated in the filter, but the date part can be retrieved in dateFilterType.
        const dateFilterPart = filter.dateFilterType as unknown as ChartFilter.DateFilterPart;
        const datePartQueryString = DATE_PART_TO_QUERY_STRING[dateFilterPart];
        if (!datePartQueryString) {
            return '';
        }
        const key = `${datePartQueryString}(${DashboardFiltersUrlParamsService.encodeColumnName(filter.column)})`;

        if (!filter.active || areAllFiltersDeactivated) {
            return `${key}:OFF`;
        }

        let values = this.getDatePartQueryValues(
            Object.keys((filter.excludeOtherValues ? filter.selectedValues : filter.excludedValues) || {}), dateFilterPart
        ).join(',');
        values = filter.excludeOtherValues ? values : `not(${values})`;
        return `${key}:${values}`;
    }

    private isMatchingFilter(parsedFilter: Partial<ChartFilter>, tileFilter: ChartFilter) {
        if (parsedFilter.column !== tileFilter.column) {
            return false;
        }

        let isValidFilterType;
        if (!parsedFilter.filterType) {
            // for empty date part and alphanum facets the filter type cannot be determined by the parser
            parsedFilter.filterType = tileFilter.filterType;
            isValidFilterType = parsedFilter.minValue == null && parsedFilter.maxValue == null;
        } else if (parsedFilter.columnType === AxisDef.Type.NUMERICAL) {
            // numerical column type can be filtered as range or text
            isValidFilterType = [ChartFilter.FilterType.NUMERICAL_FACET, ChartFilter.FilterType.ALPHANUM_FACET].includes(tileFilter.filterType);
        } else {
            isValidFilterType = parsedFilter.filterType === tileFilter.filterType;
        }

        return isValidFilterType;
    }

    getDatePartQueryValues(values: string[], datePart: ChartFilter.DateFilterPart) {
        return values.map(value => {
            let formattedValue = value;
            if (datePart === ChartFilter.DateFilterPart.DAY_OF_WEEK) {
                formattedValue = DAYS_OF_WEEK_LABELS[parseInt(value)];
            } else if (this.checkDatePartNeedsMapping(datePart)) {
                formattedValue = `${Number.parseInt(value) + 1}`;
            }
            return DashboardFiltersUrlParamsService.encodeAlphanumValue(formattedValue);
        });
    }

    getDatePartParsedFilterValue(filter: Partial<ChartFilter>) {
        if (filter.dateFilterType === ChartFilter.DateFilterType.DAY_OF_WEEK) {
            if (filter.selectedValues) {
                filter.selectedValues = this.mapDayOfWeekDatePartUrlValues(filter.selectedValues);
            }
            if (filter.excludedValues) {
                filter.excludedValues = this.mapDayOfWeekDatePartUrlValues(filter.excludedValues);
            }
        } else if (filter.dateFilterPart && this.checkDatePartNeedsMapping(filter.dateFilterPart)) {
            if (filter.selectedValues) {
                filter.selectedValues = this.mapNumericalDatePartUrlValues(filter.selectedValues);
            }
            if (filter.excludedValues) {
                filter.excludedValues = this.mapNumericalDatePartUrlValues(filter.excludedValues);
            }
        }
        return filter;
    }

    mapDayOfWeekDatePartUrlValues(values: Record<string, boolean>): Record<string, boolean> {
        return Object.keys(values).map(val => DAYS_OF_WEEK_LABELS.indexOf(val))
            .filter(index => index !== -1)
            .reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
    }

    mapNumericalDatePartUrlValues(values: Record<string, boolean>): Record<string, boolean> {
        const newEntries = Object.entries(values).map(([key, value]) => [`${Number.parseInt(key) - 1}`, value]);
        return Object.fromEntries(newEntries);
    }

    checkDatePartNeedsMapping(datePart: ChartFilter.DateFilterPart): boolean {
        return ![ChartFilter.DateFilterPart.DAY_OF_WEEK, ChartFilter.DateFilterPart.YEAR, ChartFilter.DateFilterPart.HOUR_OF_DAY, ChartFilter.DateFilterPart.INDIVIDUAL].includes(datePart);
    }
}
