import { Inject, Injectable } from '@angular/core';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { PTScatterResponse } from '@model-main/pivot/backend/model/scatter/ptscatter-response';
import { ChartScatterDataWrapper, ScatterAxesDef } from '../modules';
import { ChartTensorDataWrapper } from '../models';
import { NumberFormatterService } from './number-formatter.service';

@Injectable({
    providedIn: 'root'
})
export class ChartDataWrapperFactoryService {
    constructor(
        private numberFormatterService: NumberFormatterService,
        @Inject('ChartColorUtils') private chartColorUtilsService: any,
        @Inject('ChartDataUtils') private chartDataUtilsService: any
    ) {
    }

    public chartScatterDataWrapper(data: PTScatterResponse, axesDef: ScatterAxesDef) {
        return new ChartScatterDataWrapper(data, axesDef, this.chartColorUtilsService, this.numberFormatterService, this.chartDataUtilsService);
    }

    public chartTensorDataWrapper(data: PivotTableTensorResponse, axesDef: Record<string, number>) {
        return new ChartTensorDataWrapper(data, axesDef);
    }
}
