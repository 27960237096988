import { Inject, Injectable } from '@angular/core';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { LinkedList } from '@shared/models';
import { EChartsOption, TreemapSeriesOption } from 'echarts';
import { ChartCoordinates, FrontendChartDef, FrontendDimensionDef, Legend } from '../../../interfaces';
import { ChartAxisTypes, ChartLabels } from '../../../enums';
import { ChartTensorDataWrapper } from '../../../models';
import { EChartSeriesContext, EChartOptionsContext, EChartLegendContext, EChartDrawContext, PrepareDataService, NonCartesian2DEChartDef, ChartColorContext } from '../../echarts';
import { ChartDimensionService, ChartFormattingService } from '../../../services';
import { TreemapBFSNodeData } from './treemap-bfs-node-data.interface';

@Injectable({
    providedIn: 'root'
})
export class TreemapEChartDefService extends NonCartesian2DEChartDef {
    chartData: ChartTensorDataWrapper;

    private chartDef: FrontendChartDef;
    private data: PivotTableTensorResponse;
    private measureIdx: number;
    private cellFormatters: Array<any>;
    private chartStore: any;
    private colorProperties: {
        colorMeasureIndex: number;
        hasColorMeasure: boolean;
        binsToInclude: Set<any> | null;
        colorScale: (value: any) => any;
    };
    private hasTreeStructureChanged = false;
    private ignoreLabels: Set<string>;

    /**
     * Highlights a node on mouseover and mouseout on a legend item
     * Has to be done manually due to echarts' bug of dispatchType 'highlight'
     * Github issue: https://github.com/apache/echarts/issues/17056
     */
    onLegendHover = (legendContext: EChartLegendContext) => {
        const opacityFaded = (legendContext.transparency || 1) / 2;
        const opacityNormal = legendContext.transparency || 1;
        const options = legendContext.echartInstance.getOption();
        const data = options.series[0].data;
        if (data) {
            data.forEach((item: any) => {
                if (item.originalName !== legendContext.item.label) {
                    const rgbArr = this.chartColorUtils.getRGBAArray(item.itemStyle.color);
                    const newOpacity = legendContext.mouseEnter ? opacityFaded : opacityNormal;
                    item.itemStyle.color = 'rgba(' + rgbArr[0] + ',' + rgbArr[1] + ',' + rgbArr[2] + ',' + newOpacity + ')';
                }
            });
            legendContext.echartInstance.setOption(options);
        }
    };

    constructor(
        @Inject('HierarchicalChartsUtils') private hierarchicalChartsUtils: any,
        @Inject('ChartStoreFactory') protected chartStoreFactory: any,
        @Inject('ChartColorScales') private chartColorScales: any,
        @Inject('ChartLegendUtils') private chartLegendUtils: any,
        @Inject('ChartDataUtils') private chartDataUtils: any,
        @Inject('ChartColorUtils') private chartColorUtils: any,
        @Inject('ChartLabels') private chartLabels: any,
        @Inject('CHART_FORMATTING_OPTIONS') protected chartFormattingOptions: any,
        @Inject('StringUtils') protected stringUtils: any,
        private chartDimension: ChartDimensionService,
        private chartFormatting: ChartFormattingService,
        prepareDataService: PrepareDataService
    ) {
        super(stringUtils, chartFormattingOptions, prepareDataService, chartFormattingOptions, prepareDataService);
    }

    protected wrapData(data: PivotTableTensorResponse, axesDef?: Record<string, number>): ChartTensorDataWrapper {
        return new ChartTensorDataWrapper(data, axesDef);
    }

    /**
     * resizes the echartInstance to avoid the scattered nodes bug
     * @param {Object} echartInstance
     */
    afterChange = (echartInstance: any) => {
        echartInstance.resize({ silent: true });
    };

    private fixupSorts() {
        this.chartDef.yDimension.forEach((dimension: FrontendDimensionDef) => {
            if (dimension.isSortDefault) {
                delete dimension.isSortDefault;
                dimension.possibleSorts.forEach((sort) => {
                    if (!sort.sortAscending) {
                        dimension.sort = sort;
                    }
                });
            }
        });
    }

    /**
     * Computes color properties of the chart
     * @param {colorScale} colorScale
     * @param {Legend[]} legends
     * @returns { { hasColorMeasure: boolean, colorMeasureIndex: number, colorScale: colorScale, binsToInclude: Set | null } }
     */
    private computeColorProperties(colorScale: any, legends?: Array<Legend> | null) {
        const hasColorMeasure = this.chartDef.colorMeasure.length > 0;
        const colorMeasureIndex: number = this.chartDef.colorMeasure.length > 0 ? this.data.aggregations.length - 1 : -1;

        //  This extends Array prototype
        (this.chartDef.colorMeasure as any).$mIdx = colorMeasureIndex;

        /** @type {ColorSpec} datastructure containing the information necessary to create the colorScale */
        let colorSpec: any;

        let continuousColorScale;
        if (hasColorMeasure) {
            const yDimensionIds = this.chartDef.yDimension.map(yDim => this.chartStore.getDimensionId(yDim));
            colorSpec = {
                type: ChartAxisTypes.MEASURE,
                measureIdx: this.data.aggregations.length - 1,
                withRgba: true,
                binsToInclude: this.hierarchicalChartsUtils.getBinsToIncludeInColorScale(
                    this.chartData,
                    [],
                    yDimensionIds,
                    colorMeasureIndex,
                    false,
                    false
                )
            };
            const colorContext: ChartColorContext<ChartTensorDataWrapper> = {
                chartData: this.chartData,
                colorOptions: this.chartDef.colorOptions,
                genericMeasures: this.chartDef.genericMeasures,
                colorSpec
            }
            continuousColorScale = this.chartColorScales.createColorScale(colorContext);
            this.chartLegendUtils.initLegend(this.chartDef, this.chartData, legends, continuousColorScale);

            if (legends && legends.length > 0) {
                const extent = colorSpec.domain || this.chartDataUtils.getMeasureExtent(this.chartData.data, colorSpec.measureIdx, true);
                legends[0].formatter = this.chartFormatting.createNumberFormatter(this.chartDef.colorMeasure[0], extent, 10);
            }
        }
        return {
            hasColorMeasure,
            colorMeasureIndex,
            colorScale: hasColorMeasure ? continuousColorScale : colorScale,
            binsToInclude: hasColorMeasure ? colorSpec.binsToInclude : null
        };
    }

    /**
     * Computes continous color of a treemap node
     * @param {TreemapBFSNodeData} node      treemap node formatted to match the format expected by Echarts
     * @param {boolean} isSubTotal           true if the node is top- or middle-level, false if the node is bottom-level
     */
    private computeContinuousColor(node: TreemapBFSNodeData, isSubTotal = false) {
        const cellCoords = node.yCoordDict;
        let measure;
        if (isSubTotal) {
            measure = this.chartData.getSubtotalPoint(this.colorProperties.colorMeasureIndex, cellCoords);
        } else {
            measure = this.chartData.getAggrPoint(this.colorProperties.colorMeasureIndex, cellCoords);
        }
        const color = this.colorProperties.colorScale(measure);
        node.label.color = this.hierarchicalChartsUtils.getContrastYIQ(color);
        node.itemStyle.color = color;
        node.coord.color = { value: color, measure };
    }

    /**
     * Computes discrete colors of the top level nodes
     * @param {TreemapBFSNodeData[]} tree treemap data formatted to match the format expected by Echarts
     */
    private computeDiscreteColor(tree: Array<TreemapBFSNodeData>) {
        for (let i = 0; i < tree.length; i++) {
            const color = this.colorProperties.colorScale(i);
            const rgbaColor = this.chartColorUtils.toRgba(color, this.chartDef.colorOptions.transparency);
            tree[i].itemStyle = { color: rgbaColor };
            tree[i].label.color = this.hierarchicalChartsUtils.getContrastYIQ(rgbaColor);
        }
    }

    /**
     * Recomputes nodes' color coords after removing first dimension nodes from the root due to filtering
     * @param {TreemapBFSNodeData[]} tree treemap data formatted to match the format expected by Echarts
     */
    private recomputeColorCoords(tree: Array<TreemapBFSNodeData>) {
        const queue = new LinkedList<TreemapBFSNodeData>(tree);

        for (let i = 0; i < tree.length; i++) {
            tree[i].coord.color = i;
        }

        while (queue.length) {
            const curr = queue.shift();
            if (curr?.children) {
                curr.children.forEach((child) => {
                    if (curr.coord.color !== undefined) {
                        child.coord.color = curr.coord.color;
                    }
                    queue.push(child);
                });
            }
        }
    }


    /**
     * @param {Record <string, number>} cellCoords
     * @returns {number}
     */
    private getSubtotalValue(cellCoords: Record<string, number>) {
        return this.chartData.getSubtotalPoint(0, cellCoords);
    }

    /**
     * Updates color axis indexes to always reflect the indexes of the 1st dimension axis
     * @param {TreemapBFSNodeData} node treemap node formatted to match the format expected by Echarts
     */
    private updateColorAxis(node: TreemapBFSNodeData) {
        const axes = Object.keys(node.yCoordDict);
        const axisName = axes[0];
        /** @type {Record<string, number>} */
        const coords = node.yCoordDict;
        coords.color = node.yCoordDict[axisName];
    }

    /**
     * Computes the value of a bottom level node
     * @param {TreemapBFSNodeData} node     treemap node formatted to match the format expected by Echarts
     * @returns {boolean}                   a boolean indicating whether the node is empty
     */
    private computeLastChildValue(node: TreemapBFSNodeData): boolean {
        let isEmpty = true;
        if (this.chartDef.genericMeasures && this.chartDef.genericMeasures.length > 0) {
            const cellCoords = { ...node.yCoordDict };
            const aggregationValue = this.chartData.getAggrPoint(0, cellCoords);
            const isTupleExistingInDataset = this.chartData.getCount(cellCoords) !== 0;
            if (isTupleExistingInDataset) {
                isEmpty = false;
                this.removeUnusedProps(node);
            }
            node.value = aggregationValue;
        }
        return isEmpty;
    }

    /**
     * Removes the node props needed only for navigation while traversing the tree
     * @param {TreemapBFSNodeData} node treemap node formatted to match the format expected by Echarts
     */
    private removeUnusedProps(node: TreemapBFSNodeData) {
        if (node.parent) {
            this.removeUnusedProps(node.parent);
            delete node.parent;
        }
        delete node.head;
        delete node.tail;
    }

    /**
     * Removes an empty node from the tree, and its parents if they become empty too
     * @param {TreemapBFSNodeData} node     treemap node formatted to match the format expected by Echarts
     * @param {TreemapBFSNodeData[]} tree   treemap data formatted to match the format expected by Echarts
     */
    private removeEmpty(node: TreemapBFSNodeData, tree: Array<TreemapBFSNodeData>) {
        if (node.parent) {
            node.parent.children.splice(node.parent.children.indexOf(node), 1);
            if (node.parent.children.length === 0) {
                this.removeEmpty(node.parent, tree);
            }
        } else {
            tree.splice(tree.indexOf(node), 1);
            this.hasTreeStructureChanged = true;
            this.ignoreLabels.add(node.originalName);
        }
    }

    /**
     * Formats the node display name taking into account the formatting applied by the user
     * @param {Record<string, number> | {}} yCoordDict      positions of a node in the dimensions (axes) included in the chart
     * @returns {string}                                    formatted display name
     */
    private getFormattedNodeName(yCoordDict: Record<string, number> = {}): string {
        const axes = Object.keys(yCoordDict);
        if (axes.indexOf('color') >= 0) {
            axes.splice(axes.indexOf('color'), 1);
        }
        const axisName = axes[axes.length - 1];
        const labels = (this.chartData.getAxisLabels(axisName) || [])[yCoordDict[axisName]];
        const dimension = this.chartDef.yDimension[axes.length - 1];
        return this.chartLabels.getFormattedLabel(
            labels,
            this.chartDimension.getNumberFormattingOptions(dimension),
            this.chartData.getMinValue(axisName),
            this.chartData.getMaxValue(axisName),
            this.chartData.getNumValues(axisName)
        );
    }

    /**
     * Retrieves the node display name
     * @param {Record<string, number> | {}} yCoordDict      positions of a node in the dimensions (axes) included in the chart
     * @param {TreemapBFSNodeData} nodeParent               parent of the node
     * @returns                                             node display name
     */
    private getNodeName(yCoordDict: Record<string, number> = {}, nodeParent?: TreemapBFSNodeData) {
        let name = this.getFormattedNodeName(yCoordDict);
        let parent = nodeParent;
        const depth = nodeParent ? nodeParent.depth + 1 : 0;
        if (depth === this.chartDef.yDimension.length - 1) {
            while (parent) {
                if (this.chartDef.yDimension[parent.depth].showDimensionValuesInChart) {
                    name = this.getFormattedNodeName(parent.yCoordDict) + '\n' + name;
                }
                parent = parent.parent;
            }
        }
        return name;
    }

    /**
     * Adds value to the node display name if 'Display values in chart' is selected'
     * @param {TreemapBFSNodeData} node
     */
    private addValueToNodeLabel(node: TreemapBFSNodeData) {
        const formattedValue: string = this.cellFormatters[this.measureIdx] ? this.cellFormatters[this.measureIdx](node.value) : node.value;
        node.name += '\n' + formattedValue;
    }

    /**
     * Computes level options based on the number of group dimensions
     * @returns level options
     */
    private getLevels() {
        const levels = [{
            itemStyle: {
                gapWidth: 6
            }
        },
        {
            itemStyle: {
                gapWidth: 5
            }
        },
        {
            itemStyle: {
                gapWidth: 3
            }
        }];
        const dimNumber = this.chartDef.yDimension.length;
        for (let i = 0; i < dimNumber - 3; i++) {
            levels.push({
                itemStyle: {
                    gapWidth: 1
                }
            });
        }
        return levels;
    }

    /**
     * Builds nested tree using the BFS algorithm and a queue
     * @returns {TreemapBFSNodeData[]} treemap data formatted to match the format expected by Echarts
     */
    private buildTree(): Array<TreemapBFSNodeData> {
        const [head, ...tail] = this.chartDef.yDimension;
        const queue = new LinkedList<TreemapBFSNodeData>();
        const tree: Array<TreemapBFSNodeData> = [];
        const dimensionId = this.chartStore.getDimensionId(head);
        const axisLabels = this.chartData.getAxisLabels(dimensionId) || [];

        let j = 0;
        for (let i = 0; i < axisLabels.length; i++) {
            if (i !== this.chartData.getSubtotalLabelIndex(dimensionId)) {
                const yCoordDict: { [id: string]: number } = {};
                yCoordDict[dimensionId] = i;
                const nodeData: TreemapBFSNodeData = {
                    name: this.getNodeName(yCoordDict),
                    originalName: axisLabels[i].label,
                    children: [],
                    coord: {
                        animation: 0,
                        facet: 0,
                        color: this.colorProperties.hasColorMeasure ? {} : j,
                        [dimensionId]: i,
                        measure: this.measureIdx
                    },
                    itemStyle: {},
                    yCoordDict,
                    head,
                    tail,
                    position: j,
                    label: {},
                    depth: 0
                };
                j++;

                if (!this.colorProperties.hasColorMeasure) {
                    this.updateColorAxis(nodeData);
                }

                queue.push(nodeData);
                tree.push(nodeData);
            }
        }

        while (queue.length !== 0) {
            const curr = queue.shift();

            if (curr) {
                if (curr.tail && curr.tail.length > 0) {
                    if (curr.value === undefined) {
                        curr.value = this.getSubtotalValue({ ...curr.yCoordDict });
                    }
                    if (this.colorProperties.hasColorMeasure) {
                        this.computeContinuousColor(curr, true);
                    }
                    const [childHead, ...childTail] = curr.tail;
                    const childDimensionId: 'x' | 'y' = this.chartStore.getDimensionId(childHead);
                    const childValues = this.chartData.getAxisLabels(childDimensionId) || [];

                    for (let j = 0; j < childValues.length; j++) {
                        if (j !== this.chartData.getSubtotalLabelIndex(childDimensionId)) {
                            const childYCoordDict = { ...curr.yCoordDict };
                            childYCoordDict[childDimensionId] = j;
                            const childNodeData: TreemapBFSNodeData = {
                                name: this.getNodeName(childYCoordDict, curr),
                                originalName: childValues[j].label,
                                children: [],
                                coord: { ...curr.coord },
                                parent: curr,
                                yCoordDict: childYCoordDict,
                                head: childHead,
                                tail: childTail,
                                itemStyle: {},
                                label: this.colorProperties.hasColorMeasure ? {} : curr.label,
                                depth: curr.depth + 1
                            };

                            if (!this.colorProperties.hasColorMeasure) {
                                this.updateColorAxis(childNodeData);
                            }

                            childNodeData.coord[childDimensionId] = j;
                            curr.children.push(childNodeData);
                            queue.push(childNodeData);
                        }
                    }
                } else {
                    const isEmpty = this.computeLastChildValue(curr);
                    if (isEmpty) {
                        this.removeEmpty(curr, tree);
                    } else {
                        if (this.chartDef.showInChartValues) {
                            this.addValueToNodeLabel(curr);
                        }
                        if (this.colorProperties.hasColorMeasure) {
                            this.computeContinuousColor(curr);
                        }
                    }
                }
            }
        }
        if (!this.colorProperties.hasColorMeasure) {
            if (this.hasTreeStructureChanged) {
                this.recomputeColorCoords(tree);
            }
        }
        return tree;
    }

    protected getSeriesId(coord: ChartCoordinates): string {
        return '';
    }

    protected getSeries({ chartPoints }: EChartSeriesContext): Array<TreemapSeriesOption> {
        const series: Array<TreemapSeriesOption> = [
            {
                name: 'ALL',
                type: 'treemap',
                nodeClick: undefined,
                data: chartPoints as Array<TreemapBFSNodeData>,
                levels: this.getLevels(),
                label: {
                    fontFamily: this.chartFormattingOptions.FONT_FAMILY,
                    fontSize: 13,
                    position: 'insideTopLeft'
                },
                breadcrumb: {
                    show: false
                }
            }
        ];

        return series;
    }

    protected getOptions({ series, gridOptions }: EChartOptionsContext): EChartsOption {
        const options = {
            grid: gridOptions,
            series
        };

        return options;
    }

    getColorSpec(chartDef: FrontendChartDef) {
        return {
            type: chartDef.colorMeasure.length ? ChartAxisTypes.MEASURE : ChartAxisTypes.DIMENSION,
            name: 'color',
            dimension: chartDef.colorMeasure.length ? chartDef.colorMeasure[0] : chartDef.yDimension[0],
            measureIdx: chartDef.colorMeasure.length ? 1 : -1
        };
    }

    /**
     * Checks whether the chart data contains negative values which will not be plotted in the treemap
     * @returns {{ valid: boolean, message: string, type: string }} valid object containing all the information necessary to display the warning toast
     */
    checkDataValidity() {
        const min = this.chartDataUtils.getMeasureExtent(this.chartData.data, this.measureIdx, true, this.colorProperties.binsToInclude)[0];
        let valid = true;
        const message = 'The negative values will not be displayed in the chart';
        const type = 'warning';
        if (min < 0) {
            valid = false;
        }
        return { valid, message, type };
    }

    draw(drawContext: EChartDrawContext<ChartTensorDataWrapper>): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        const gridOptions = drawContext.chartBase.margins;
        const colorScale = drawContext.chartBase.colorScale;

        this.chartData = drawContext.chartData;
        this.chartDef = drawContext.chartDef;
        this.data = drawContext.chartData.data;
        this.measureIdx = 0;
        this.ignoreLabels = new Set([ChartLabels.SUBTOTAL_BIN_LABEL]);

        const cellFormatterProperties = this.hierarchicalChartsUtils.computeFormatterProperties(drawContext.chartDef);
        /** @type {((value: number) => string)[]} */
        this.cellFormatters = cellFormatterProperties.cellFormatters;

        const { store } = this.chartStoreFactory.getOrCreate(drawContext.chartId);
        this.hierarchicalChartsUtils.updateChartStoreMeasureIDsAndDimensionIDs(store, drawContext.chartDef);
        this.chartStore = store;

        this.colorProperties = this.computeColorProperties(colorScale, drawContext.legends);

        this.fixupSorts();

        const chartPoints = this.buildTree();

        if (!this.colorProperties.hasColorMeasure) {
            if (this.hasTreeStructureChanged) {
                const colorContext: ChartColorContext<ChartTensorDataWrapper> = {
                    chartData: this.chartData,
                    colorOptions: this.chartDef.colorOptions,
                    genericMeasures: this.chartDef.genericMeasures,
                    colorSpec: this.getColorSpec(this.chartDef),
                    ignoreLabels: this.ignoreLabels
                }
                this.colorProperties.colorScale = this.chartColorScales.createColorScale(colorContext);
                this.chartLegendUtils.initLegend(this.chartDef, this.chartData, drawContext.legends, this.colorProperties.colorScale, true, this.ignoreLabels);
            }
            this.computeDiscreteColor(chartPoints);
        }

        if (drawContext.legends) {
            drawContext.legends[0].hideLegend = false;
        }

        const chartWidth = drawContext.chartBase.width - (gridOptions.left || 0) - (gridOptions.right || 0);
        const chartHeight = drawContext.chartBase.height - (gridOptions.top || 0) - (gridOptions.bottom || 0);

        const seriesContext: EChartSeriesContext = {
            chartDef: drawContext.chartDef,
            chartData: drawContext.chartData,
            chartWidth,
            chartHeight,
            colorScale: drawContext.chartBase.colorScale,
            chartPoints,
            frameIndex: drawContext.frameIndex || 0
        };

        const series = this.getSeries(seriesContext);

        const optionsContext: EChartOptionsContext = {
            series,
            gridOptions
        };

        const options = this.getOptions(optionsContext);

        const allCoords = this.getAllCoords(chartPoints);

        return {
            options,
            allCoords
        };
    }
}
