import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FacetMap, FeatureGroup } from "../feature-store-models";
import { FeatureStoreService } from "../feature-store.service";
import { formatDescription, formatShortDesc, getHighlightFor } from "../highlight-utils";
import { Hit } from "../query-result-models";
import { SearchResultsComponent } from "../search-results.directive";

@Component({
  selector: "feature-group-search-results",
  templateUrl: "./feature-group-search-results.component.html",
  styleUrls: ["./feature-group-search-results.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureGroupSearchResultsComponent extends SearchResultsComponent {
  @Input() totalItems!: number;
  @Input() items?: Hit<FeatureGroup>[];
  @Input() selectedItem?: Hit<FeatureGroup>;
  @Input() searchQuery!: string;
  @Input() facetMap!: FacetMap;
  @Input() projectKey?: string;
  @Input() datasetsInCurrentProject!: string[];
  @Output() resetFiltersEvent: EventEmitter<void> = new EventEmitter();
  @Output() featureGroupSelectedEvent: EventEmitter<Hit<FeatureGroup>> = new EventEmitter();

  constructor(private featureStoreService: FeatureStoreService) {
    super();
  }

  featureGroupCount() {
    const count = this.totalItems;
    return `<strong>${count}</strong> Feature Group${count < 2 ? "" : "s"}`;
  }

  formatFeatureGroupDescription(featureGroup: Hit<FeatureGroup>) {
    return formatShortDesc(
      featureGroup.highlight || {},
      formatDescription(
        featureGroup.highlight || {},
        featureGroup._source.shortDesc || featureGroup._source.description || ""
      )
    );
  }

  featureGroupHasDescription(featureGroup: Hit<FeatureGroup>) {
    return (
      featureGroup._source.shortDesc !== "" ||
      featureGroup._source.description !== ""
    );
  }

  featureGroupHasTags(featureGroup: Hit<FeatureGroup>) {
    return featureGroup._source.tag.length !== 0;
  }

  highlightedTags(featureGroup: Hit<FeatureGroup>) {
    if (featureGroup.highlight) {
      const tags: string[] = featureGroup.highlight.tag?.map((t) => this.removeEmphasis(t)) || [];
      const tagsPlaintext: string[] = featureGroup.highlight["tag.plaintext"]?.map((t) => this.removeEmphasis(t)) || [];
      tagsPlaintext.forEach((tag) => {
        if (tags.length < 5 && !tags.includes(tag)) {
          tags.push(tag);
        }
      });
      return tags;
    }
    return [];
  }

  featureGroupTags(featureGroup: Hit<FeatureGroup>) {
    const tags = this.highlightedTags(featureGroup);
    featureGroup._source.tag.forEach((tag) => {
      if (tags.length < 5 && !tags.includes(tag)) {
        tags.push(tag);
      }
    });
    return tags;
  }

  tagIsHighlighted(featureGroup: Hit<FeatureGroup>, tag: string) {
    if (featureGroup.highlight) {
      if (featureGroup.highlight.tag && featureGroup.highlight.tag.length > 0) {
        return featureGroup.highlight.tag.map((t) => this.removeEmphasis(t)).includes(tag);
      }
      if (featureGroup.highlight["tag.plaintext"] && featureGroup.highlight["tag.plaintext"].length > 0) {
        return featureGroup.highlight["tag.plaintext"].map((t) => this.removeEmphasis(t)).includes(tag);
      }
    }
    return false;
  }

  featureGroupHasHighlightedColumns(featureGroup: Hit<FeatureGroup>) {
    return (
      getHighlightFor(featureGroup.highlight || {}, "column") !== undefined ||
      getHighlightFor(featureGroup.highlight || {}, "column.plaintext") !== undefined
    );
  }

  featureGroupHighlightedColumns(featureGroup: Hit<FeatureGroup>) {
    const columns: string[] = [];
    if (featureGroup.highlight) {
      if (featureGroup.highlight.column && featureGroup.highlight.column.length > 0) {
        columns.push(...featureGroup.highlight.column);
      }
      if (featureGroup.highlight["column.plaintext"] && featureGroup.highlight["column.plaintext"].length > 0) {
        featureGroup.highlight["column.plaintext"].forEach((column) => {
          const strippedColumnName = column.replace(/<\/?em>/g, "");
          const index = columns.map((c) => c.replace(/<\/?em>/g, "")).indexOf(strippedColumnName);
          if (index === -1) {
            columns.push(column);
          }
        });
      }
    }
    return `feature${columns.length > 1 ? "s" : ""}: ${columns.join(", ")}`;
  }

  numberOfTagsNotDisplayed(featureGroup: Hit<FeatureGroup>) {
    const tagsWithHighlight = this.highlightedTags(featureGroup);
    const displayedTags = tagsWithHighlight.length > 5 ? tagsWithHighlight.length : 5;
    return featureGroup._source.tag.length - displayedTags;
  }

  removeEmphasis(emphazised: string) {
    return emphazised.replace(/<em>/g, "").replace(/<\/em>/g, "");
  }

  tagBackgroundColor(projectKey: string, tag: string) {
    return this.featureStoreService.tagBackgroundColor(projectKey, tag);
  }

  featureGroupIsAlreadyInCurrentProject(featureGroup: Hit<FeatureGroup>) {
    return this.datasetsInCurrentProject.includes(featureGroup._source.projectKey + "." + featureGroup._source.id);
  }

  selectFeatureGroup(featureGroup: Hit<FeatureGroup>) {
    this.featureGroupSelectedEvent.emit(featureGroup);
  }
}
