import { Inject, Injectable } from '@angular/core';
import { GridComponentOption, YAXisComponentOption } from 'echarts';
import { isNumber } from 'lodash';
import { ChartAxisTypes } from '../../enums';
import { FrontendChartDef } from '../../interfaces';
import { ChartDataWrapper } from '../../models';
import { ChartDimensionService, ChartFormattingService, ChartUADimensionService, NumberFormatterService } from '../../services';
import { EChartAxisTypes } from './enums';

@Injectable({
    providedIn: 'root'
})
export class EChartsAxesService {
    private LABELS_DEFAULT_FORMATTING_OPTIONS: {
        fontFamily: string,
        color: string,
        fontSize: number
    } = {
            fontFamily: this.CHART_FORMATTING_OPTIONS.FONT_FAMILY,
            color: this.CHART_FORMATTING_OPTIONS.COLOR,
            fontSize: this.CHART_FORMATTING_OPTIONS.LABELS_FONT_SIZE
        };

    private AXIS_NAME_DEFAULT_FORMATTING_OPTIONS: {
        fontWeight: string,
        fontFamily: string,
        color: string,
        fontSize: number,
    } = {
            fontWeight: this.CHART_FORMATTING_OPTIONS.AXIS_NAME_FONT_WEIGHT,
            fontFamily: this.CHART_FORMATTING_OPTIONS.FONT_FAMILY,
            color: this.CHART_FORMATTING_OPTIONS.COLOR,
            fontSize: this.CHART_FORMATTING_OPTIONS.AXIS_NAME_FONT_SIZE
        };

    private LABELS_DEFAULT_ROTATION = 30;

    //  Default gap between ticks in px
    private GAP_BETWEEN_TICKS = 50;

    //  Default margin to apply on a label in px (applied left/right or top/bottom depending on the rotation)
    private LABEL_MARGIN = 6;

    //  Default margin to apply on an axis name in px (to create space between the axis line and the name)
    private NAME_GAP_MARGIN = 15;

    //  Default margin to apply below x axis labels in px
    private BOTTOM_MARGIN = 25;

    constructor(
        @Inject('CHART_FORMATTING_OPTIONS') private CHART_FORMATTING_OPTIONS: any,
        @Inject('ChartUtils') private chartUtilsService: any,
        @Inject('ChartAxesUtils') private chartAxesUtilsService: any,
        @Inject('StringUtils') private stringUtils: any,
        private numberFormatterService: NumberFormatterService,
        private chartFormattingService: ChartFormattingService,
        private chartDimensionService: ChartDimensionService,
        private chartUADimensionService: ChartUADimensionService
    ) { }

    /**
     * Configure dimension axis.
     *
     * @param {ChartTensorDataWrapper} chartData
     * @param {AxisSpec} axisSpec
     * @param {Boolean} isLogScale
     * @param {Boolean} includeZero
     */
    private configureDimensionAxis(chartData: ChartDataWrapper, axisSpec: any, isLogScale: boolean, includeZero?: boolean) {
        const isNumerical = this.chartAxesUtilsService.isNumerical(axisSpec);
        let extent = this.chartAxesUtilsService.getDimensionExtent(chartData, axisSpec);

        //  Do not apply again if switching to manual mode
        if (!isLogScale && !this.chartUtilsService.isManualMode(axisSpec.customExtent)) {
            extent = this.chartAxesUtilsService.fixUnbinnedNumericalExtent(axisSpec, extent);
        }

        if (includeZero && isNumerical) {
            extent = this.chartAxesUtilsService.includeZero(extent);
        }

        if (isLogScale && isNumerical) {
            extent = this.chartAxesUtilsService.fixNumericalLogScaleExtent(axisSpec, extent, includeZero);
        }

        // Choose axis type
        if (this.chartDimensionService.isTimeline(axisSpec.dimension) || (axisSpec.type === ChartAxisTypes.UNAGGREGATED && this.chartUADimensionService.isDate(axisSpec.dimension))) {
            return { type: EChartAxisTypes.TIME, extent };
        } else if (this.chartDimensionService.isUnbinnedNumerical(axisSpec.dimension)) {
            return { type: EChartAxisTypes.VALUE, extent };
        } else if (this.chartDimensionService.isBinnedNumerical(axisSpec.dimension) || (axisSpec.type === ChartAxisTypes.UNAGGREGATED && this.chartUADimensionService.isTrueNumerical(axisSpec.dimension))) {
            if (axisSpec.dimension.oneTickPerBin) {
                return { originalType: EChartAxisTypes.VALUE, type: EChartAxisTypes.CATEGORY, extent };
            } else {
                return isLogScale ? { type: EChartAxisTypes.LOG, extent } : { type: EChartAxisTypes.VALUE, extent };
            }
        } else {
            return { type: EChartAxisTypes.CATEGORY, extent };
        }
    }

    /**
     * Configure measure axis.
     * Inspired by createMeasureAxis without d3 stuff.
     *
     * @param {ChartDataWrapper} chartData
     * @param {AxisSpec} axisSpec
     * @param {boolean} isLogScale
     * @param {boolean} includeZero
     */
    private configureMeasureAxis(chartData: ChartDataWrapper, axisSpec: any, isLogScale: boolean, includeZero?: boolean) {
        const domain = this.chartAxesUtilsService.getMeasureDomain(chartData, axisSpec, isLogScale, includeZero);

        if (domain === null) {
            return { type: null };
        }

        const extent = { values: [], min: domain[0], max: domain[1] };

        if (this.chartDimensionService.hasOneTickPerBin(axisSpec.dimension)) {
            return { type: EChartAxisTypes.CATEGORY, extent };
        }
        return isLogScale ? { type: EChartAxisTypes.LOG, extent } : { type: EChartAxisTypes.VALUE, extent };
    }

    /**
     * Configure dimension or measure axis.
     * Inspired by createAxis without d3 stuff.
     * @param {ChartDataWrapper} chartData
     * @param {AxisSpec} axisSpec
     * @param {boolean} isLogScale
     * @param {boolean} includeZero
     */
    private configureAndGetAxisType(chartData: ChartDataWrapper, axisSpec: any, isLogScale: boolean, includeZero?: boolean) {
        let axisType: any = {};

        switch (axisSpec.type) {
            case ChartAxisTypes.DIMENSION:
            case ChartAxisTypes.UNAGGREGATED:
                axisType = this.configureDimensionAxis(chartData, axisSpec, isLogScale, includeZero);
                break;
            case ChartAxisTypes.MEASURE:
                axisType = this.configureMeasureAxis(chartData, axisSpec, isLogScale, includeZero);
                break;
        }

        axisType.info = axisSpec.type;

        return axisType;
    }

    /**
     * Reset number of ticks on y axis once we've computed x axis margins
     * 1/ We compute y axis margins (with labels' widths)
     * 2/ We compute x axis margins (with labels' heights)
     * 3/ As x axis labels reduced the height of the y axis, we need to recompute the interval between
     * @param {echarts axis options} axisOptions
     * @param {number} height
     * @param {echarts grid options} gridOptions
     * @param {number} nbTicks
     */
    private reconcileYAxisOptions(axisOptions: YAXisComponentOption, height: number, gridOptions: GridComponentOption, nbTicks: number): void {
        const axisHeight = (height || 0) - (isNumber(gridOptions.top) && gridOptions.top || 0) - (isNumber(gridOptions.bottom) && gridOptions.bottom || 0);
        const heightBetweenTwoTicks = axisHeight / (nbTicks + 1);

        if (axisHeight > 0) {
            // interval: if not enough room for each label considering the fact a label is 12px of height, compute the interval that allows to position a maximum of labels without height overlap
            if (axisOptions.type === EChartAxisTypes.CATEGORY) {
                (axisOptions.axisLabel as any).interval = Math.floor(1 / (heightBetweenTwoTicks / this.LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize));
            } else {
                if (heightBetweenTwoTicks < this.LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize + this.LABEL_MARGIN) {
                    //  Create a split every {{GAP_BETWEEN_TICKS}}px if not in category (interval is used for category only)
                    (axisOptions as any).splitNumber = Math.ceil(axisHeight / this.GAP_BETWEEN_TICKS);
                } else {
                    (axisOptions as any).splitNumber = nbTicks;
                }
            }
        }
    }

    /**
     * Reset number of ticks on y axis once we've computed x axis margins
     * @param {echarts yAxes} yAxes
     * @param {exhart series} series
     * @param {string} mainAxis
     * @param {number} nbTicks
     */
    alignMultipleYAxes(yAxis: any, y2Axis: any, specs?: any, nbTicks: number = 6) {
        if (this.chartUtilsService.isManualMode(specs.ySpec.customExtent) || this.chartUtilsService.isManualMode(specs.y2Spec.customExtent)) {
            return;
        }

        const yMin = specs.ySpec.domain[0];
        const yMax = specs.ySpec.domain[1];

        const y2Min = specs.y2Spec.domain[0];
        const y2Max = specs.y2Spec.domain[1];

        if ([yMin, y2Min].some(v => v < 0) && [yMax, y2Max].some(v => v > 0) ) {

            const yAbsolute = Math.max(Math.abs(yMin), Math.abs(yMax));
            const y2Absolute = Math.max(Math.abs(y2Min), Math.abs(y2Max));

            const maxFactor = Math.max(
                Math.abs(Math.max(0, yMax) / yAbsolute),
                Math.abs(Math.max(0, y2Max) / y2Absolute)
            );

            const minFactor = Math.max(
                Math.abs(Math.min(0, yMin) / yAbsolute),
                Math.abs(Math.min(0, y2Min) / y2Absolute)
            );

            yAxis.max = yAbsolute * maxFactor;
            yAxis.min = -yAbsolute * minFactor;

            y2Axis.max = y2Absolute * maxFactor;
            y2Axis.min = -y2Absolute * minFactor;
        }

        /*
         * interval division might produce arbitrary-precision arithmetic issues (IEEE 754)
         * formatter should round axis origin to '0' (as it might be a value like 4e-14)
         */
        yAxis.interval = (yAxis.max - yAxis.min) / nbTicks || Math.abs(yAxis.max);
        y2Axis.interval = (y2Axis.max - y2Axis.min) / nbTicks || Math.abs(y2Axis.max);
    }

    private getOutsideAxisBandWidth(rotation: number, labelWidths: Array<number>, widthBetweenTwoTicks: number) {
        return labelWidths.reduce((acc, labelWidth, index) => {

            const radians = (rotation * Math.PI) / 180;

            //  Compute first label width when rotated
            const boxWidth = Math.cos(radians) * labelWidth;
            const widthFromFirstTick = widthBetweenTwoTicks * index;

            const outsideAxisBandWith = boxWidth - widthFromFirstTick;

            return Math.max(acc, outsideAxisBandWith);
        }, 0);
    }

    private rotateLabels(
        axisOptions: any,
        gridOptions: any,
        axisWidth: number,
        range: number,
        precision: number,
        labelWidths: Array<number>,
        longestLabelWidth: number,
        widthBetweenTwoTicks: number,
        labelBand: number
    ) {
        let outsideAxisBandWith = this.getOutsideAxisBandWidth(axisOptions.axisLabel.rotate, labelWidths, widthBetweenTwoTicks);

        //  Rotate by 30 degrees while computed boxes widths take too much space
        while (outsideAxisBandWith > gridOptions.left) {
            axisOptions.axisLabel.rotate += 30;
            outsideAxisBandWith = this.getOutsideAxisBandWidth(axisOptions.axisLabel.rotate, labelWidths, widthBetweenTwoTicks);
        }

        const radians = (axisOptions.axisLabel.rotate * Math.PI) / 180;

        //  Compute longest label height when rotated and apply the height to grid bottom margin
        const maxBoxHeight = Math.sin(radians) * longestLabelWidth;
        //  {{NAME_GAP_MARGIN}}px from closest axis label (thus the max box height)
        axisOptions.nameGap = Math.ceil(maxBoxHeight + this.NAME_GAP_MARGIN);
        /*
         *  axisLabels are rotated and axisName is displayed,
         *  so we have to create a margin which can handle rotated texts on top of the axis name with a gap in-between and below
         */
        gridOptions.bottom = maxBoxHeight + this.AXIS_NAME_DEFAULT_FORMATTING_OPTIONS.fontSize + this.BOTTOM_MARGIN;

        //  Basic Pythagorian algorithm to compute hypotenuse between two labels (we add a default margin)
        const minTickInterval = Math.sqrt(Math.pow(this.LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize + this.LABEL_MARGIN, 2) * 2);
        const defaultBand = Math.ceil((range * minTickInterval) / axisWidth) / Math.pow(10, precision);

        switch (axisOptions.type) {
            case EChartAxisTypes.CATEGORY:
                //  As we rotate, we check if there's enough space to fit all the ticks with a minimum margin, else, we set a bigger interval
                axisOptions.axisLabel.interval = Math.floor(1 / (widthBetweenTwoTicks / minTickInterval));
                break;
            case EChartAxisTypes.TIME:
                //  Semi-functional workaround, needs to be fixed : https://github.com/apache/echarts/issues/14266
                axisOptions.maxInterval = Math.max(labelBand, defaultBand);
                break;
        }
    }

    private transformLabels(
        axisOptions: any,
        gridOptions: any,
        axisWidth: number,
        range: number,
        precision: number,
        labelWidths: Array<number>,
        longestLabelWidth: number,
        labelBand: number
    ) {
        const defaultTickInterval = Math.max(this.GAP_BETWEEN_TICKS, longestLabelWidth + this.LABEL_MARGIN);
        const defaultBand = Math.ceil((range * defaultTickInterval) / axisWidth) / Math.pow(10, precision);

        switch (axisOptions.type) {
            case EChartAxisTypes.CATEGORY:
                //  Interval is used for category only
                axisOptions.axisLabel.interval = 0;
                break;
            case EChartAxisTypes.TIME:
                //  Semi-functional workaround, needs to be fixed : https://github.com/apache/echarts/issues/14266
                axisOptions.maxInterval = Math.min(labelBand, defaultBand);
                break;
        }

        //  Mainly used to push left margin if y axis is set to the right
        if (axisOptions.axisLabel.showMinLabel) {
            gridOptions.left = Math.ceil(Math.max(gridOptions.left, labelWidths[0] / 2 + this.LABEL_MARGIN));
        }

        //  Mainly used to push right margin if y axis is set to the left
        if (axisOptions.axisLabel.showMaxLabel) {
            gridOptions.right = Math.ceil(Math.max(gridOptions.right, labelWidths[labelWidths.length - 1] / 2 + this.LABEL_MARGIN));
        }

        // {{NAME_GAP_MARGIN}}px from closest axis label with no rotation (thus the font size)
        axisOptions.nameGap = Math.ceil(this.LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize + this.NAME_GAP_MARGIN);

        /*
         *  axisLabels are not rotated and axisName is displayed,
         *  so we have to create a margin which can handle two texts on top of each other with a gap in-between and below
         */
        gridOptions.bottom = this.LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize + this.AXIS_NAME_DEFAULT_FORMATTING_OPTIONS.fontSize + this.BOTTOM_MARGIN;
    }

    private transformXAxisOptions(axisOptions: any, width: number, gridOptions: any, labels: Array<string>, labelWidths: Array<number>) {
        const axisWidth = (width || 0) - (gridOptions.left || 0) - (gridOptions.right || 0);

        if (axisWidth > 0) {
            let numberOfTicks = labels.length;

            if (axisOptions.axisLabel.showMinLabel && axisOptions.min !== (axisOptions.data && axisOptions.data[0])) {
                numberOfTicks += 1;
            }

            if (axisOptions.axisLabel.showMaxLabel && axisOptions.max !== (axisOptions.data && axisOptions.data[axisOptions.data.length - 1])) {
                numberOfTicks += 1;
            }

            const widthBetweenTwoTicks = axisWidth / numberOfTicks;

            const areLabelsOverlapping = (leftLabelWidth: number, rightLabelWidth: number) => {
                return leftLabelWidth / 2 + rightLabelWidth / 2 > widthBetweenTwoTicks;
            };

            const isOverlapping = labelWidths.some((labelWidth, index) => {
                if (index + 1 < labelWidths.length) {
                    return areLabelsOverlapping(labelWidth, labelWidths[index + 1]);
                }
                return false;
            });

            axisOptions.axisLabel.rotate = isOverlapping ? this.LABELS_DEFAULT_ROTATION : 0;
            axisOptions.axisLabel.hideOverlap = true;

            const precision = labels.reduce((acc, label) => {
                const splitLabels = label.split('.');
                if (splitLabels && splitLabels[1]) {
                    acc = Math.max(acc, splitLabels[1].length);
                }
                return acc;
            }, 0);
            const range = (axisOptions.max - axisOptions.min) * Math.pow(10, precision);
            const labelBand = Math.ceil((range * widthBetweenTwoTicks) / axisWidth) / Math.pow(10, precision);

            const longestLabelWidth = Math.max(...labelWidths);

            if (axisOptions.axisLabel.rotate > 0) {
                //  Apply transformations on axis options with a rotation
                this.rotateLabels(axisOptions, gridOptions, axisWidth, range, precision, labelWidths, longestLabelWidth, widthBetweenTwoTicks, labelBand);
            } else {
                //  When not rotating, we don't need to set a max width for labels
                delete axisOptions.axisLabel.width;
                delete axisOptions.axisLabel.overflow;
                //  Apply transformations on axis options without a rotation
                this.transformLabels(axisOptions, gridOptions, axisWidth, range, precision, labelWidths, longestLabelWidth, labelBand);
            }
        }
    }

    private transformYAxisOptions(axisOptions: any, gridOptions: any, labelWidths: Array<number>, position = 'left') {
        const longestLabelWidth = Math.max(...labelWidths);
        axisOptions.nameGap = Math.ceil(longestLabelWidth + this.NAME_GAP_MARGIN); // {{NAME_GAP_MARGIN}}px from closest axis label
        gridOptions[position] = longestLabelWidth + this.AXIS_NAME_DEFAULT_FORMATTING_OPTIONS.fontSize + this.BOTTOM_MARGIN;
    }

    private transformAxisOptions(axis: string, axisOptions: any, width: number, gridOptions: any, labels?: Array<string>) {
        if (labels?.length) {
            const labelWidths = labels.map((label) => {
                const labelWidth = this.stringUtils.getTextWidth(label, `${this.LABELS_DEFAULT_FORMATTING_OPTIONS.fontSize}px ${this.LABELS_DEFAULT_FORMATTING_OPTIONS.fontFamily}`);
                return Math.min(labelWidth, axisOptions.axisLabel.width);
            });

            switch (axis) {
                case 'x':
                    this.transformXAxisOptions(axisOptions, width, gridOptions, labels, labelWidths);
                    break;
                case 'y':
                    this.transformYAxisOptions(axisOptions, gridOptions, labelWidths, 'left');
                    break;
                case 'y2':
                    this.transformYAxisOptions(axisOptions, gridOptions, labelWidths, 'right');
                    break;
            }
        }
    }

    private bindExtentAsLabels(labels: Array<string>, extent: any) {
        // Echarts displays numbers only, so we push min and max integer values as labels
        if (isNumber(extent.min)) {
            labels.push('' + Math.floor(extent.min));
        }

        if (isNumber(extent.max)) {
            labels.push('' + Math.ceil(extent.max));
        }

        return labels;
    }

    //  Updates extent to set new tick values and step between each tick based on d3.js
    private getLinearExtent(extent: { min: number, max: number, values: Array<any>, step?: number }) : { min: number, max: number, values: Array<any>, step?: number } {
        const updatedExtent = extent;
        updatedExtent.values = [];

        //  Default number of values (as in d3)
        const numValues = 10;

        const span = updatedExtent.max - updatedExtent.min;

        //  Computing the minimal step between two ticks
        let step = Math.pow(10, Math.floor(Math.log(span / numValues) / Math.LN10));

        if (step > 0) {
            const err = (numValues / span) * step;

            //  The lowest the error, the higher the step between two ticks
            if (err <= 0.15) {
                step *= 10;
            } else if (err <= 0.35) {
                step *= 5;
            } else if (err <= 0.75) {
                step *= 2;
            }

            updatedExtent.min = Math.floor(extent.min / step) * step;

            let value = updatedExtent.min;
            while (value <= updatedExtent.max) {
                updatedExtent.values.push(value);
                value += step;
            }
        }

        updatedExtent.step = step;

        return updatedExtent;
    }

    private getLogExtent(extent: { min: number, max: number, values: Array<string>, step?: number }): { min: number, max: number, values: Array<string>, step?: number } {
        const updatedExtent = extent;
        updatedExtent.values = [`${extent.min}`];

        let max = extent.min;
        let index = 1;
        while (max < extent.max) {
            max = Math.pow(10, index);
            updatedExtent.values.push(`${max}`);
            index++;
        }

        return updatedExtent;
    }

    private transformAxisOptionsForValue(
        axis: string,
        axisOptions: any,
        width: number,
        gridOptions: any,
        formattingOptions: any,
        axisType: any,
        isPercentScale: boolean,
        includeZero?: boolean
    ) {
        const extent = this.getLinearExtent(axisType.extent);
        const numValues = (extent.values || []).length || 10;
        const axisLabelFormatter = this.numberFormatterService.getForAxis(extent.min, extent.max, numValues, axisType.info, formattingOptions, isPercentScale);

        let labels = [extent.min, extent.max];

        if (isPercentScale) {
            labels = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        }

        axisOptions.axisTick = {
            alignWithLabel: true,
            show: true
        };

        axisOptions.scale = !includeZero;
        axisOptions.axisLabel.formatter = axisLabelFormatter;

        // echarts has bad displays if min == max
        if (extent.min !== extent.max) {
            axisOptions.min = extent.min;
            axisOptions.max = extent.max;
            axisOptions.interval = extent.step;
        }

        if (axis === 'x') {
            axisOptions.axisLabel.showMinLabel = true;
            /*
             * If the max is higher than highest value, we don't display it
             * Else if it equals the highest value, we display its label
             */
            if (axisOptions.max === extent.values[extent.values.length - 1]) {
                axisOptions.axisLabel.showMaxLabel = true;
            }
        }

        this.transformAxisOptions(axis, axisOptions, width, gridOptions, labels.map(axisLabelFormatter));
    }

    private transformAxisOptionsForLog(
        axis: string,
        axisOptions: any,
        width: number,
        gridOptions: any,
        formattingOptions: any,
        axisType: any,
        isPercentScale: boolean
    ) {
        const extent = this.getLogExtent(axisType.extent);
        const numValues = (extent.values || []).length || 10;
        const axisLabelFormatter = this.numberFormatterService.getForAxis(extent.min, extent.max, numValues, axisType.info, formattingOptions, isPercentScale);

        const labels = extent.values;

        axisOptions.axisTick = {
            alignWithLabel: true,
            show: true
        };

        axisOptions.min = extent.min;
        axisOptions.max = extent.max;
        axisOptions.axisLabel.formatter = axisLabelFormatter;

        if (axis === 'x') {
            axisOptions.axisLabel.showMinLabel = true;
            /*
             * If the max is higher than highest value, we don't display it
             * Else if it equals the highest value, we display its label
             */
            if (axisOptions.max === extent.values[extent.values.length - 1]) {
                axisOptions.axisLabel.showMaxLabel = true;
            }
        }

        this.transformAxisOptions(axis, axisOptions, width, gridOptions, labels.map(axisLabelFormatter));
    }

    private transformAxisOptionsForCategory(
        axis: string,
        axisOptions: any,
        axisLabels: Array<any>,
        width: number,
        gridOptions: any,
        formattingOptions: any,
        axisType: any
    ) {
        //  Sets default formatter
        let labelFormatter: (value: any, index: number) => string = (value: any) => this.numberFormatterService.getForOrdinalAxis(value);

        //  If we check 'One tick per bin', we need to switch to a 'category' axis but with the same formatting (for a value)
        if (axisType.originalType === EChartAxisTypes.VALUE) {
            labelFormatter = this.numberFormatterService.getForBinnedAxis(axisType.extent.values, formattingOptions);
        }

        const labels = (axisLabels || []).map((axisLabel) => axisLabel.label);

        if (labels.length) {
            axisOptions.data = labels;
        }

        axisOptions.axisTick = {
            alignWithLabel: true,
            show: true
        };
        axisOptions.axisLabel.showMinLabel = true;
        axisOptions.axisLabel.showMaxLabel = true;
        axisOptions.axisLabel.formatter = labelFormatter;

        this.transformAxisOptions(axis, axisOptions, width, gridOptions, labels.map(labelFormatter));
    }

    private transformAxisOptionsForTime(
        axis: string,
        axisOptions: any,
        axisLabels: Array<any>,
        width: number,
        gridOptions: any,
        axisType: any
    ) {
        const extent = axisType.extent;

        const labelFormatter = (value: number) => this.chartFormattingService.getDateFormatter()(value);

        let labels = (axisLabels || []).map((axisLabel) => axisLabel.tsValue);

        if (!labels.length) {
            labels = this.bindExtentAsLabels(labels, extent);
            axisOptions.data = [];
        } else {
            axisOptions.data = labels;
        }

        // echarts has bad displays if min == max
        if (extent.min !== extent.max) {
            axisOptions.min = extent.min;
            axisOptions.max = extent.max;
        }

        axisOptions.axisLabel.formatter = labelFormatter;

        this.transformAxisOptions(axis, axisOptions, width, gridOptions, labels.map(labelFormatter));
    }

    private setAxisData(
        axis: string,
        axisOptions: any,
        axisLabels: Array<any>,
        axisType: any,
        width: number,
        gridOptions: any,
        formattingOptions: any,
        isPercentScale: boolean,
        includeZero?: boolean
    ) {
        switch (axisOptions.type) {
            case EChartAxisTypes.VALUE:
                this.transformAxisOptionsForValue(axis, axisOptions, width, gridOptions, formattingOptions, axisType, isPercentScale, includeZero);
                break;
            case EChartAxisTypes.LOG:
                this.transformAxisOptionsForLog(axis, axisOptions, width, gridOptions, formattingOptions, axisType, isPercentScale);
                break;
            case EChartAxisTypes.CATEGORY:
                this.transformAxisOptionsForCategory(axis, axisOptions, axisLabels, width, gridOptions, formattingOptions, axisType);
                break;
            case EChartAxisTypes.TIME:
                this.transformAxisOptionsForTime(axis, axisOptions, axisLabels, width, gridOptions, axisType);
                break;
        }
    }

    private shouldShowMinLabel(extent: any, includeZero: boolean, isManualMode: boolean) {
        return (extent && extent.min === 0) || includeZero || isManualMode;
    }

    /**
     * Compute Echarts axis options for X Axis
     * @param {ChartTensorDataWrapper} chartData
     * @param {FrontendChartDef} chartDef
     * @param {AxisSpec} axisSpec
     * @param {Array} xLabels   -   Axis labels
     * @param {boolean} isLogScale
     * @param {number} width width of the chart (without margins)
     * @param {number} height height of the chart (without margins)
     * @param {echarts grid options} gridOptions
     * @param {boolean} noXAxis
     * @returns null if nothing to display, exception if wrong axis type or Echarts axis definition object + newest margins
     */
    private getXAxisOptions(
        chartData: ChartDataWrapper,
        chartDef: FrontendChartDef,
        axisSpec: any,
        xLabels: Array<any> | undefined,
        isLogScale: boolean,
        width: number,
        height: number,
        gridOptions: any,
        noXAxis = false
    ) {
        if (!axisSpec) {
            return;
        }

        let axisOptions = {};

        const axisType = this.configureAndGetAxisType(chartData, axisSpec, isLogScale);

        if (!axisType || !axisType.type) {
            return;
        }

        const isManualMode = this.chartUtilsService.isManualMode(axisSpec.customExtent);

        axisOptions = {
            name: this.chartAxesUtilsService.getXAxisLabel(axisSpec, chartDef) || undefined,
            type: axisType.type,
            nameLocation: 'middle',
            nameGap: 20,
            nameTextStyle: { ...this.AXIS_NAME_DEFAULT_FORMATTING_OPTIONS },
            axisLine: {
                show: true
            },
            splitLine: {
                show: false
            },
            show: chartDef.displayXAxis && !noXAxis,
            axisLabel: {
                //  We want x-axis labels to take at most 1/2 of chart space vertically
                width: height / 2 - this.AXIS_NAME_DEFAULT_FORMATTING_OPTIONS.fontSize - this.NAME_GAP_MARGIN - this.BOTTOM_MARGIN,
                overflow: 'break',
                showMinLabel: this.shouldShowMinLabel(axisType.extent, chartDef.includeZero, isManualMode),
                showMaxLabel: isManualMode,
                ...this.LABELS_DEFAULT_FORMATTING_OPTIONS
            }
        };

        this.setAxisData('x', axisOptions, xLabels || [], axisType, width, gridOptions, chartDef.xAxisNumberFormattingOptions, axisSpec.isPercentScale);

        return { axisOptions, gridOptions };
    }

    /**
     * Compute Echarts axis options for Y Axis
     * @param {ChartTensorDataWrapper} chartData
     * @param {FrontendChartDef} chartDef
     * @param {AxisSpec} axisSpec
     * @param {Array} yLabels
     * @param {boolean} isLogScale
     * @param {number} width width of the chart (without margins)
     * @param {echarts grid options} gridOptions
     * @param {boolean} noYAxis
     * @param {boolean} toTheRight
     * @returns null if nothing to display, exception if wrong axis type or Echarts axis definition object + newest margins
     */
    private getYAxisOptions(
        chartData: ChartDataWrapper,
        chartDef: FrontendChartDef,
        axisSpec: any,
        yLabels: Array<any> | undefined,
        isLogScale: boolean,
        width: number,
        gridOptions: any,
        noYAxis = false,
        toTheRight = false
    ) {
        if (!axisSpec) {
            return null;
        }

        let axisOptions: any = {};
        const includeZero = this.chartAxesUtilsService.shouldIncludeZero(chartDef);

        const axisType = this.configureAndGetAxisType(chartData, axisSpec, isLogScale, includeZero);

        if (!axisType && !axisType.type) {
            return;
        }

        const isManualMode = this.chartUtilsService.isManualMode(axisSpec.customExtent);

        axisOptions = {
            name: this.chartAxesUtilsService.getYAxisLabel(axisSpec, chartDef) || undefined,
            type: axisType.type,
            nameRotate: 90,
            nameLocation: 'middle',
            nameGap: 40,
            nameTextStyle: { ...this.AXIS_NAME_DEFAULT_FORMATTING_OPTIONS },
            axisLine: {
                show: true
            },
            show: chartDef.displayYAxis && !noYAxis,
            axisLabel: {
                //  We want y-axis labels to take at most 1/2 of chart space horizontally
                width: width / 2 - this.AXIS_NAME_DEFAULT_FORMATTING_OPTIONS.fontSize - this.NAME_GAP_MARGIN,
                overflow: 'break',
                hideOverlap: false,
                showMinLabel: this.shouldShowMinLabel(axisType.extent, chartDef.includeZero, isManualMode),
                showMaxLabel: isManualMode,
                ...this.LABELS_DEFAULT_FORMATTING_OPTIONS
            }
        };

        if (toTheRight) {
            axisOptions.position = 'right';
        }

        //  Y Axis is always latest measure
        this.setAxisData(toTheRight ? 'y2' : 'y', axisOptions, yLabels || [], axisType, width, gridOptions, chartDef.yAxisNumberFormattingOptions, axisSpec.isPercentScale, includeZero);

        return { axisOptions, gridOptions };
    }

    getAxesAndMargins(
        chartData: ChartDataWrapper,
        chartDef: FrontendChartDef,
        width: number,
        height: number,
        margins: any,
        specs: any,
        noXAxis?: boolean,
        noYAxis?: boolean
    ) {
        const result: any = {
            gridOptions: { ...margins }
        };

        const xLabels = chartData.getAxisLabels('x');
        const yLabels = chartData.getAxisLabels('y');
        const y2Labels = chartData.getAxisLabels('y2');


        //  Updating y-axes first to compute left/right margins to apply on x-axis
        const yUpdates = this.getYAxisOptions(chartData, chartDef, specs.ySpec, yLabels, chartDef.axis2LogScale, width, result.gridOptions, noYAxis);
        if (yUpdates) {
            result.yAxisOptions = yUpdates.axisOptions;
            result.gridOptions = yUpdates.gridOptions;
        }

        const y2Updates = this.getYAxisOptions(chartData, chartDef, specs.y2Spec, y2Labels, chartDef.axis2LogScale, width, result.gridOptions, noYAxis, true);
        if (y2Updates) {
            result.y2AxisOptions = y2Updates.axisOptions;
            result.gridOptions = y2Updates.gridOptions;
        }

        const xUpdates = this.getXAxisOptions(chartData, chartDef, specs.xSpec, xLabels, chartDef.axis1LogScale, width, height, result.gridOptions, noXAxis);
        if (xUpdates) {
            result.xAxisOptions = xUpdates.axisOptions;
            result.gridOptions = xUpdates.gridOptions;
        }

        if (result.yAxisOptions) {
            this.reconcileYAxisOptions(result.yAxisOptions, height, result.gridOptions, (yLabels || []).length);
        }

        if (result.y2AxisOptions) {
            this.reconcileYAxisOptions(result.y2AxisOptions, height, result.gridOptions, (y2Labels || []).length);
        }

        if (result.yAxisOptions && result.y2AxisOptions) {
            this.alignMultipleYAxes(result.yAxisOptions, result.y2AxisOptions, specs);
        }

        return result;
    }
}
