import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChartUADimensionService {
    isTrueNumerical(dimension: any) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'NUMERICAL' && !dimension.treatAsAlphanum;
    }

    isAlphanumLike(dimension: any) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'ALPHANUM' || (dimension.type == 'NUMERICAL' && dimension.treatAsAlphanum);
    }

    isDiscreteDate(dimension: any) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'DATE' && dimension.dateMode != 'RANGE';
    }

    isDateRange(dimension: any) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'DATE' && dimension.dateMode == 'RANGE';
    }

    isDate(dimension: any) {
        if (!dimension) {
            return;
        }
        return dimension.type == 'DATE';
    }
}
