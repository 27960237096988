import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { SimpleReportEchartComponent } from './simple-report-echart.component';

@NgModule({
    declarations: [
        SimpleReportEchartComponent
    ],
    imports: [
        CommonModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        })
    ],
    exports: [
        SimpleReportEchartComponent
    ]
})
export class SimpleReportEchartModule { }
