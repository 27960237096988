import { PTScatterResponse } from '@model-main/pivot/backend/model/scatter/ptscatter-response';
import { ScaleOrdinal, scaleOrdinal, ScalePower, scaleSqrt } from 'd3-scale';
import { timeFormat } from 'd3-time-format';
import { UADimensionProperty } from '../../../enums';
import { ChartCoordinates } from '../../../interfaces';
import { ChartDataWrapper, ChartIAE } from '../../../models';
import { ScatterAxesDef } from './scatter-axes-def.interface';
import { ChartCoordinatesScatterExtras } from './chart-coordinates-scatter-extras.interface';
import { SCATTER_SHAPES } from './scatter-shapes';

/**
 * A wrapper for easily access the data stored in a PTScatterResponse
 * @param {PTScatterResponse.java} data
 */
export class ChartScatterDataWrapper implements ChartDataWrapper {
    constructor(
        public data: PTScatterResponse,
        public axesDef: ScatterAxesDef,
        private chartColorUtilsService: any,
        private numberFormatterService: any,
        private chartDataUtilsService: any
    ) {
    }

    hasAnimations(): boolean {
        return false;
    }

    getFacets(): Array<any> {
        return [];
    }

    getAxisLabels(axisName: string): Array<any> | undefined {
        switch(axisName) {
            case 'x':
                return this.chartDataUtilsService.getUnaggregatedAxisExtentByType(this.axesDef.x, this.data.xAxis, this.data.afterFilterRecords).values.map((v: any) => ({ label: v }));
            case 'y':
                return this.chartDataUtilsService.getUnaggregatedAxisExtentByType(this.axesDef.y, this.data.yAxis, this.data.afterFilterRecords).values.map((v: any) => ({ label: v }));
            default:
                return;
        }
    }

    getXValue(index: number): number | undefined {
        if (!this.axesDef.x) {
            return;
        }
        let value = this.data.xAxis[this.axesDef.x].data[index];
        if (this.axesDef.x === UADimensionProperty.STR) {
            value = this.data.xAxis[this.axesDef.x].sortedMapping[value].sortOrder;
        }
        return value;
    }

    getYValue(index: number): number | undefined {
        if (!this.axesDef.y) {
            return;
        }
        let value = this.data.yAxis[this.axesDef.y].data[index];
        if (this.axesDef.y === UADimensionProperty.STR) {
            value = this.data.yAxis[this.axesDef.y].sortedMapping[value].sortOrder;
        }
        return value;
    }

    getColorLabel(index: number, colorScale: (color: any) => string) {
        if (this.axesDef.color) {
            return colorScale(this.data.values.color[this.axesDef.color].data[index]);
        } else {
            return this.chartColorUtilsService.toRgba(this.axesDef.colorOptions.singleColor, this.axesDef.colorOptions.transparency);
        }
    }

    getSymbolSize(pointIndex: number, sizeScale: (sizeValue?: number) => number): number {
        if (this.axesDef.size && this.axesDef.size !== UADimensionProperty.STR) {
            return sizeScale(this.data.values.size[this.axesDef.size].data[pointIndex]);
        }

        return this.axesDef.radius;
    }

    makeShapeScale(): ScaleOrdinal<number, any> | null {
        if (this.axesDef.shape) {
            return scaleOrdinal<number, any>().range(SCATTER_SHAPES);
        }

        return null;
    }

    makeSizeScale(pxlr: number): ScalePower<number, number> | null {
        if (!this.axesDef.size) {
            return null;
        }

        if (this.axesDef.size === UADimensionProperty.STR) {
            throw new ChartIAE('Cannot use ALPHANUM as size scale');
        }

        return scaleSqrt()
            .range([this.axesDef.radius * pxlr, this.axesDef.radius * 5 * pxlr])
            .domain([this.data.values.size[this.axesDef.size].min, this.data.values.size[this.axesDef.size].max]);
    }

    uaFormattedAxisValues(uaDimension: any, axisValues: Record<UADimensionProperty, any> | undefined, index: number, measuresFormatter: any): string {
        let value = 'NA';
        const uaDimensionType = this.chartDataUtilsService.getUnaggregatedDimensionType(uaDimension);

        if (uaDimensionType === UADimensionProperty.NUM && axisValues?.num) {
            const vf = this.numberFormatterService.getForGivenFormatterInAutoMode(uaDimension, measuresFormatter);
            value = vf(axisValues.num.data[index]);
        } else if (uaDimensionType === UADimensionProperty.TS && axisValues?.ts) {
            const ts = axisValues.ts.data[index];
            value = timeFormat('%Y-%m-%d')(new Date(ts));
        } else if (uaDimensionType === UADimensionProperty.STR && axisValues?.str) {
            value = axisValues.str.sortedMapping[axisValues.str.data[index]].label;
        }

        return value;
    }

    uaFormattedVal(uaDimension: any, coords: ChartCoordinates<ChartCoordinatesScatterExtras>, axisName: string, measuresFormatter: any): string {
        let index: number = -1;
        let axisValues: any;

        switch (axisName) {
            case 'x':
            case 'y':
                index = coords[axisName] as number;
                axisValues = this.data[`${axisName}Axis`];
                break;
            case 'color':
                index = coords[axisName] as number;
                axisValues = this.data.values[axisName];
                break;
            case 'shape':
            case 'size':
                index = coords.extras ? coords.extras[axisName] : -1;
                axisValues = this.data.values[axisName];
                break;
        }

        if (index < 0) {
            return 'NA';
        }

        return this.uaFormattedAxisValues(uaDimension, axisValues, index, measuresFormatter);
    }

    hasUAShape(def: Record<string, any>): boolean {
        return def.uaShape.length > 0;
    }

    hasUAColor(def: Record<string, any>): boolean {
        return def.uaColor.length > 0;
    }
}
