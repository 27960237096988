import { Component, ChangeDetectionStrategy, OnChanges, SimpleChanges, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { fairAny } from 'dku-frontend-core';

export type Column = {
  name: string;
  type: string;
  meaning: string | null;
  comment: string | null;
};

@Component({
  selector: 'dss-right-panel-schema',
  templateUrl: './right-panel-schema.component.html',
  styleUrls: [
    './right-panel-schema.component.less'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelSchemaComponent implements OnChanges {
  @Input() wordingOption: "feature" | "column";
  @Input() columns: Column[]; // this type is compatible with the _source.columns info from the internal catalog ES result, but also with serializedDataset.schema.columns
  @Input() columnHighlight?: string[]; // optional (a list of column names with some parts embedded in a <em> tag)
  @Input() selected?: string;

  showSchema: boolean;
  filterName: string;
  highlightedColumns: Column[];

  private allMeanings: Record<string, string>;

  constructor(
    @Inject('$rootScope') public $rootScope: fairAny,
  ) {
    this.allMeanings = { "": "All meanings", ...this.$rootScope.appConfig.meanings.labelsMap };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.columns) {
      this.filterName = "";
      this.showSchema = this.wordingOption === 'feature' || this.columns.length <= 10;
    }
    if(changes.columns || changes.columnHighlight) {
      this.highlightedColumns = this.computeHighlightedColumns();
    }
  }

  hasHighlightedColumns(): boolean {
    return (this.columnHighlight?.length || 0) > 0;
  }

  getFilteredHighlightedColumns() {
    return this.highlightedColumns.filter(column => this.showColumn(column));
  }

  removeHighlighting(name: string) {
    return name.replace(/<\/?em>/g, '');
  }

  private computeHighlightedColumns() {
    const columns: Column[] = [];
    const strippedCols: string[] = this.columnHighlight
      ? this.columnHighlight.map(str => this.removeHighlighting(str))
      : [];
    this.columns.forEach(column => {
      if (this.showColumn(column)) {
        const index = strippedCols.indexOf(column.name);
        const name = index === -1
          ? column.name
          : this.columnHighlight?.[index] || "";

        columns.push({
          name,
          type: column.type,
          meaning: column.meaning,
          comment: column.comment,
        });
      }
    });
    return columns;
  }

  private showColumn(column: Column): boolean {
    return column.name.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase());
  }
}
