import type {AnalyseUtils} from './../../ml/prediction/guess/analyse-utils';
import type {CalibrationParams} from './calibration-params';
import type {CausalPredictionPreprocessingParams} from './../preprocessing/causal-prediction-preprocessing-params';
import type {ClassicalPredictionPreprocessingParams} from './../preprocessing/classical-prediction-preprocessing-params';
import type {DeepHubPreTrainModelingParams} from './deep-hub-pre-train-modeling-params';
import type {MLAssertionsParams} from './assertions/mlassertions-params';
import type {MLOverridesParams} from './overrides/mloverrides-params';
import type {PartitionedModelParams} from './partitioned-model-params';
import type {PredictionGuessPolicy} from './../../ml/prediction/guess/prediction-guess-policy';
import type {PredictionModelingParams} from './prediction-modeling-params';
import type {PredictionPreprocessingParams} from './../preprocessing/prediction-preprocessing-params';
import type {SplitParams} from './../split-params';
import type {TimeOrderingParams} from './time-ordering-params';
import type {TimeseriesForecastingPreprocessingParams} from './../preprocessing/timeseries-forecasting-preprocessing-params';
import type {TimestepParams} from './timestep-params';
import type {WeightParams} from './weight-params';
import type {_MLTask} from './../mltask';

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask
 */
export interface _PredictionMLTask extends _MLTask {
    predictionType: PredictionMLTask.PredictionType;
    splitParams: SplitParams;
    targetVariable: string;
}

export type PredictionMLTask = PredictionMLTask.CausalPredictionMLTask | PredictionMLTask.ClassicalPredictionMLTask | PredictionMLTask.DeepHubPredictionMLTask | PredictionMLTask.TimeseriesForecastingMLTask;

export namespace PredictionMLTask.TimeseriesForecastingMLTask {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask$TimeseriesForecastingMLTask$TimeseriesEvaluationParams
     */
    export interface TimeseriesEvaluationParams {
        gapSize: number;
        testSize: number;
    }
}

export namespace PredictionMLTask {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask$ClassicalPredictionMLTask
     */
    export interface ClassicalPredictionMLTask extends PredictionMLTask._TabularPredictionMLTask {
        assertionsParams: MLAssertionsParams;
        calibration: CalibrationParams;
        overridesParams: MLOverridesParams;
        preprocessing: ClassicalPredictionPreprocessingParams;
        time: TimeOrderingParams;
        weight: WeightParams;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask$CausalPredictionMLTask
     */
    export interface CausalPredictionMLTask extends PredictionMLTask._TabularPredictionMLTask {
        controlValue: string;
        positiveClass: string;
        preprocessing: CausalPredictionPreprocessingParams;
        treatmentEffectSums: {[key: string]: AnalyseUtils.TreatmentEffectSum};
        treatmentVariable: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask$PredictionType
     */
    export enum PredictionType {
        BINARY_CLASSIFICATION = 'BINARY_CLASSIFICATION',
        REGRESSION = 'REGRESSION',
        MULTICLASS = 'MULTICLASS',
        DEEP_HUB_IMAGE_OBJECT_DETECTION = 'DEEP_HUB_IMAGE_OBJECT_DETECTION',
        DEEP_HUB_IMAGE_CLASSIFICATION = 'DEEP_HUB_IMAGE_CLASSIFICATION',
        TIMESERIES_FORECAST = 'TIMESERIES_FORECAST',
        CAUSAL_BINARY_CLASSIFICATION = 'CAUSAL_BINARY_CLASSIFICATION',
        CAUSAL_REGRESSION = 'CAUSAL_REGRESSION'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask$TabularPredictionMLTask
     */
    export interface _TabularPredictionMLTask extends _PredictionMLTask {
        guessPolicy: PredictionGuessPolicy;
        modeling: PredictionModelingParams;
        partitionedModel: PartitionedModelParams;
    }

    export type TabularPredictionMLTask = PredictionMLTask.CausalPredictionMLTask | PredictionMLTask.ClassicalPredictionMLTask | PredictionMLTask.TimeseriesForecastingMLTask;

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask$TimeseriesForecastingMLTask
     */
    export interface TimeseriesForecastingMLTask extends PredictionMLTask._TabularPredictionMLTask {
        evaluationParams: PredictionMLTask.TimeseriesForecastingMLTask.TimeseriesEvaluationParams;
        predictionLength: number;
        preprocessing: TimeseriesForecastingPreprocessingParams;
        quantilesToForecast: number[];
        timeVariable: string;
        timeseriesIdentifiers: string[];
        timestepParams: TimestepParams;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionMLTask$DeepHubPredictionMLTask
     */
    export interface DeepHubPredictionMLTask extends _PredictionMLTask {
        managedFolderSmartId: string;
        modeling: DeepHubPreTrainModelingParams;
        pathColumn: string;
        preprocessing: PredictionPreprocessingParams;
    }
}