import { directive } from '@migration/downgraded-components';
import { service, constant } from '@migration/downgraded-providers';
import { ChartTensorDataWrapper } from './models';
import { EChartsManagerService, EChartsInstanceManagerService, HorizontalBarsEChartDefService, PieEChartDefService, StackedVerticalBarsEChartDefService, TreemapEChartDefService, VerticalBarsEChartDefService, ScatterEChartDefService, SimpleReportEchartComponent } from './modules';
import { ChartDataWrapperFactoryService, ChartDimensionService, ChartFormattingService, ChartStaticDataService, ChartUADimensionService, NumberFormatterService } from './services';

export function downgradeSimpleReport() {
    //  Services
    service('EChartsManager', EChartsManagerService),
    service('EChartsInstanceManager', EChartsInstanceManagerService),
    service('VerticalBarsEChartDef', VerticalBarsEChartDefService),
    service('StackedVerticalBarsEChartDef', StackedVerticalBarsEChartDefService),
    service('HorizontalBarsEChartDef', HorizontalBarsEChartDefService),
    service('ScatterEChartDef', ScatterEChartDefService),
    service('TreemapEChartDef', TreemapEChartDefService),
    service('PieEChartDef', PieEChartDefService),
    service('ChartDimension', ChartDimensionService),
    service('ChartUADimension', ChartUADimensionService),
    service('ChartsStaticData', ChartStaticDataService),
    service('NumberFormatter', NumberFormatterService),
    service('ChartFormatting', ChartFormattingService),
    service('ChartDataWrapperFactory', ChartDataWrapperFactoryService),

    //  Constants
    constant('ChartTensorDataWrapper', ChartTensorDataWrapper),

    //  Components
    directive('ng2SimpleReportEchart', SimpleReportEchartComponent);
}
