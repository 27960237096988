import { LabelingRegion, LabelingTask } from "src/generated-sources";
import { LabelingTaskInfo } from "../services/labeling.service";
import { getAverageRectangleFromAnnotations } from "../utils";
import { UIAnnotation, UIBoundingBox, UIClassificationAnnotation } from "./annotation";
import { ClassificationAnnotationRegion, ObjectDetectionRegion } from "./labeling-region";

export function createFromRegion(r: LabelingRegion, task: LabelingTaskInfo): AnnotationGroup {
    switch(task.type) {
        case LabelingTask.LabelingTaskType.OBJECT_DETECTION:
            return BoundingBoxGroup.createFromRegion(r, task.minNbAnnotatorsPerRecord, task.objectDetectionIOUConflictThreshold);
        case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
            return ClassificationAnnotationGroup.createFromRegion(r, task.minNbAnnotatorsPerRecord);
        default:
            throw('Not supported for ' + task.type);
    }
}

export abstract class AnnotationGroup {
    annotations: UIAnnotation[];
    minNbAnnotators: number;
    modifiedByReviewer: boolean;
    selected: boolean;
    selectable: boolean;

    constructor(minNbAnnotators: number) {
        this.minNbAnnotators = minNbAnnotators;
    }

    hasConflict(): boolean {
        return this.conflictReasons().length !== 0;
    }

    conflictReasons(): string[] {
        const reasons: string[] = [];

        if (this.annotations.length === 0) {
            return reasons;
        }
        if (this.hasConflictingCategory()) {
            reasons.push('No consensus on the category');
        }
        if (this.hasMissingAnnotator()) {
            reasons.push('Some annotators missed this object. Accept an answer to resolve');
        }

        return reasons;
    }

    hasMissingAnnotator(): boolean {
        return !this.modifiedByReviewer && new Set(this.annotations.map(object => object.annotator)).size < this.minNbAnnotators;
    }

    hasConflictingCategory(): boolean {
        return this.annotations.map(object => object.category).some((category, _, categories) => category != categories[0])
    }

    hasMissingCategory(): boolean {
        return this.annotations.map(object => object.category).some(category => category === undefined)
    }

    getConsensusCategory(): string | undefined {
        if (this.annotations.map(object => object.category).every((category, _, categories) => category === categories[0])) {
            if (this.annotations[0]) {
                return this.annotations[0].category!
            }
        }
        return undefined;
    }

    abstract computeAverageObject(): UIAnnotation;
}

export class ClassificationAnnotationGroup extends AnnotationGroup {
    annotations: UIClassificationAnnotation[];

    constructor(annotations: UIClassificationAnnotation[], minNbAnnotators: number, modifiedByReviewer?: boolean) {
        super(minNbAnnotators);
        this.annotations = annotations;
        this.selected = true;
        this.selectable = false;
        this.modifiedByReviewer = modifiedByReviewer || false;
    }

    computeAverageObject(): UIClassificationAnnotation {
        return this.annotations[0];
    }

    static createFromRegion(region: LabelingRegion, minNbAnnotators: number) {
        let annotations = (region as ClassificationAnnotationRegion).elements.map(e => {
            return new UIClassificationAnnotation(e.annotation.category, undefined, e.annotator)
        });
        
        return new ClassificationAnnotationGroup(annotations, minNbAnnotators);
    }
}

export class BoundingBoxGroup extends AnnotationGroup {
    annotations: UIBoundingBox[];
    IOUConflictThreshold: number;

    constructor(annotations: UIBoundingBox[], minNbAnnotators: number, IOUConflictThreshold: number, selected?: boolean, modifiedByReviewer?: boolean) {
        super(minNbAnnotators);
        this.annotations = annotations;
        this.selected = selected || false;
        this.selectable = true;
        this.modifiedByReviewer = modifiedByReviewer || false;
        this.IOUConflictThreshold = IOUConflictThreshold;
    }

    static createFromRegion(region: LabelingRegion, minNbAnnotators: number, IOUConflictThreshold: number) {
        const bboxes = (region as ObjectDetectionRegion).elements.map(e => {
            const boundingBox = e.annotation;

            return new UIBoundingBox({
                width: boundingBox.bbox.width,
                top: boundingBox.bbox.y0,
                left: boundingBox.bbox.x0,
                height: boundingBox.bbox.height,
                category: boundingBox.category,
                annotator: e.annotator,
                pinned: true
            });
        });
        return new BoundingBoxGroup(bboxes, minNbAnnotators, IOUConflictThreshold);
    }

    computeAverageObject(): UIBoundingBox {
        return new UIBoundingBox({
            ...getAverageRectangleFromAnnotations(this.annotations),
            category: this.annotations[0].category!,
            annotator: "",
            pinned: false
        })
    }

    conflictReasons(): string[] {
        const reasons = super.conflictReasons();

        if (this.hasConflictingIoU()) {
            reasons.push('Boxes don\'t overlap enough');
        }

        return reasons;
    }

    hasConflictingIoU(): boolean {
        return this.annotations.some((object, _, annotations) => !object.empty() && object.iou(annotations[0].bbox) < this.IOUConflictThreshold);
    }
}
