import { Type } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { fairAny } from 'dku-frontend-core';
import { ProjectInitialsService } from '@shared/services';
import { MigrationStoreService } from './migration-store.service';
import { DashboardFiltersUrlParamsService } from '@features/dashboards';
import { SlugifyService } from '@shared/services/slugify.service';

// Downgrades Angular service to an angularJS service
export function service(serviceName: string, svc: Type<fairAny>, ajsModule: string = 'dataiku') {
  const angularJS = (window as fairAny).angular;
  angularJS.module(ajsModule).service(serviceName, downgradeInjectable(svc));
}

// Migrate a constant to an angularJS constant
export function constant(serviceName: string, obj: fairAny, ajsModule: string = 'dataiku') {
  const angularJS = (window as fairAny).angular;
  angularJS.module(ajsModule).constant(serviceName, obj);
}

export function downgradeProviders() {
  return [
    service('ProjectInitials', ProjectInitialsService),
    service('MigrationStoreService', MigrationStoreService),
    service('DashboardFiltersUrlParams', DashboardFiltersUrlParamsService),
    service('SlugifyService', SlugifyService)
  ];
}
