import { Aggregation } from "./query-result-models";

export const ALL_FACET_KEYS = [
  "tag.raw",
  "projectKey.raw",
  "user",
  "partitioned",
  "meaning",
  "isQuicklyShareable",
] as const;
export type FacetKey = typeof ALL_FACET_KEYS[number];
export type FacetMap = Partial<Record<FacetKey, string[]>>;
export type AggregationResults = Partial<Record<FacetKey, Aggregation>>;

export interface FacetValue {
  key: string | number;
  value: string;
  count: number;
  selected: boolean;
}

export interface Facet {
  key: FacetKey;
  facetSearch: string;
  values: FacetValue[];
}

export interface TagsFile {
  tags: Record<string, any>;
}

export interface SearchOptions {
  query: string;
  sortBy: string;
  reverse: boolean;
  viewBy: ViewBy;
}

export enum ViewBy {
  FEATURE_GROUP = "FEATURE_GROUP",
  FEATURE_NAME = "FEATURE_NAME",
}

export interface FeatureGroup {
  id: string;
  createdOn: number;
  description: string;
  shortDesc: string;
  lastModifiedOn: number;
  name: string;
  projectKey: string;
  projectName: string;
  tag: string[];
}

export interface Feature {
  createdOn: number;
  dataset: string;
  description: string;
  lastModifiedOn: number;
  meaning: string;
  name: string;
  projectKey: string;
  projectName: string;
  type: string;
}
