import { Inject, Injectable } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';
import { ProjectsService } from '@model-main/server/services/projects-service';
import { ROLE_LIST } from '../constants';
import { DKURootScope, Role, ROLES } from '../models';
import { ITaggingService } from 'src/generated-sources';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceSecurityService {

    constructor(@Inject('$rootScope') private $rootScope: DKURootScope) {}

    canCreateWorkspaces(): boolean {
        return this.$rootScope.appConfig && this.$rootScope.appConfig.loggedIn
            && (this.$rootScope.appConfig.admin || this.$rootScope.appConfig.globalPermissions.mayCreateWorkspaces);
    }

    canShareToWorkspace(workspace: Workspace): boolean {
        return this.$rootScope && this.$rootScope.appConfig && this.$rootScope.appConfig.loggedIn
            && (this.$rootScope.appConfig.admin || this.$rootScope.appConfig.globalPermissions.mayShareToWorkspaces)
            && workspace.currentUserPermissions && workspace.currentUserPermissions.write;
    }

    canEditWorkspace(workspace: Workspace): boolean {
        return this.$rootScope && this.$rootScope.appConfig && this.$rootScope.appConfig.loggedIn
            && (this.$rootScope.appConfig.admin || workspace.currentUserPermissions.admin);
    }

    canShareFromProject(project: ProjectsService.UIProject): boolean {
        return this.$rootScope && this.$rootScope.appConfig && this.$rootScope.appConfig.loggedIn
            && (this.$rootScope.appConfig.admin || this.$rootScope.appConfig.globalPermissions.mayShareToWorkspaces)
            && project.canShareToWorkspaces;
    }

    canGoToSource(object: Workspace.WorkspaceObject, project: ProjectsService.UIProject | null | undefined): boolean {
        if (object.appId) {
            return true;
        }
        switch(object.reference?.type) {
            case ITaggingService.TaggableType.DASHBOARD:
                return !!project?.canWriteDashboards;
            case ITaggingService.TaggableType.DATASET:
            case ITaggingService.TaggableType.ARTICLE:
            case ITaggingService.TaggableType.WEB_APP:
                return !!project?.canWriteProjectContent;
            default:
                return false;
        }
    }

    isObjectSharingRequestEnabled(project: ProjectsService.UIProject): boolean {
        return this.$rootScope && this.$rootScope.appConfig &&
            (this.$rootScope.appConfig.objectSharingRequestsMode === 'ALL_ENABLED' ||
                (this.$rootScope.appConfig.objectSharingRequestsMode !== 'ALL_DISABLED' && project.sharingRequestsEnabled)
            );
    }

    getRole(name: string): Role | undefined {
        return ROLE_LIST.find(item => item.role === name);
    }

    getRoleFromPermission(permission: Workspace.Permissions): Role {
        if (permission.admin) {
            return this.getRole(ROLES.ADMIN) as Role;
        } else if (permission.write) {
            return this.getRole(ROLES.CONTRIBUTOR) as Role;
        }
        return this.getRole(ROLES.MEMBER) as Role;
    }

    getPermissionFromRole(role: string): Workspace.Permissions | null {
        switch (role) {
            case ROLES.ADMIN:
                return { admin: true, write: true, read: true };
            case ROLES.CONTRIBUTOR:
                return { admin: false, write: true, read: true };
            case ROLES.MEMBER:
                return { admin: false, write: false, read: true };
            default:
                return null;
        }
    }

}
