import { Input, Component, ChangeDetectionStrategy, Output, EventEmitter, ElementRef, NgZone, OnInit, OnDestroy } from '@angular/core';
import { EChartsOption } from 'echarts';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'simple-report-echart',
    templateUrl: './simple-report-echart.component.html',
    styleUrls: ['./simple-report-echart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleReportEchartComponent implements OnInit, OnDestroy {
    @Input() options: EChartsOption;
    @Output() chartClick = new EventEmitter();
    @Output() chartInit = new EventEmitter();

    private resizeObserver: ResizeObserver;
    private animationFrameID: number;
    private resize$ = new Subject<void>();
    private echartsInstance: any;
    private canvas: HTMLElement;
    domLoaded$ = new BehaviorSubject<boolean>(false);

    constructor(private ngZone: NgZone, private element: ElementRef) {

    }

    ngOnInit() {
        this.resizeObserver = this.ngZone.runOutsideAngular(() => new window.ResizeObserver(() => {
            this.animationFrameID = window.requestAnimationFrame(() => this.resize$.next());
        }));
    }

    ngOnDestroy() {
        if (this.animationFrameID) {
            window.cancelAnimationFrame(this.animationFrameID);
        }

        if (this.canvas && this.resizeObserver) {
            this.resizeObserver.unobserve(this.canvas);
        }

        if (this.echartsInstance) {
            this.echartsInstance.off('finished');
        }
    }

    /**
     * We assert that that the canvas takes the full width/height
     * of its container in order to avoid flickering issues with ngx-echarts
     * (ngx-echarts defines a default width/height on its canvas before adapting it to its controller)
     * @param canvas
     */
    private checkCanvas(canvas: HTMLElement | null) {
        const parentRect = canvas?.parentElement?.parentElement?.getBoundingClientRect();
        const canvasRect = canvas?.getBoundingClientRect();

        this.domLoaded$.next(
            !!parentRect &&
            !!canvasRect &&
            canvasRect.width > 0 &&
            canvasRect.height > 0 &&
            Math.round(canvasRect.width) === Math.round(parentRect.width) &&
            Math.round(canvasRect.height) === Math.round(parentRect.height)
        );
    }

    onChartInit(echartsInstance: any) {
        this.echartsInstance = echartsInstance;
        this.chartInit.next(echartsInstance);

        echartsInstance.on('finished', () => {
            this.canvas = this.element.nativeElement.querySelector('canvas');
            this.checkCanvas(this.canvas);
            this.resizeObserver.observe(this.canvas);

            this.resize$.subscribe(_ => {
                this.checkCanvas(this.canvas);
            });
        });
    }
}
