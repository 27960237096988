// In this file, we make Angular components available to AngularJS
//
// - ! - WARNING - ! -
//
// - You have to redeclare the selector for directives, with the AngularJS convention:
// ex: for myDirective to match my-directive markup.
// The selector metadata of the downgraded Angular component is ignored.
// For simplicity, make sure what you write corresponds to the component definition,
// In the Component file:
//     selector: 'my-directive',
// here:
//  angular.module(...).directive('myDirective', ...)

import { Type } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { DowngradedEditTypeaheadInputComponent } from '@app/widgets/inputs/edit-typeahead-input/downgraded/downgraded-edit-typeahead-input.component';
import { DowngradedConnectionPropertiesListComponent } from '@app/widgets/lists/connection-properties-list/downgraded/downgraded-connection-properties-list.component';
import { DowngradedCredentialsListComponent } from '@app/widgets/lists/credentials-list/downgraded/downgraded-credentials-list.component';
import { DowngradedKeyValuesListComponent } from '@app/widgets/lists/key-values-list/downgraded/downgraded-key-values-list.component';
import { DowngradedValuesListComponent } from '@app/widgets/lists/values-list/downgraded/downgraded-values-list.component';
import { fairAny, LazyEchartComponent } from 'dku-frontend-core';
import { EdaComponent } from '@features/eda/eda.component';
import { InsightCardComponent } from '@features/eda/worksheet/cards/insight-card/insight-card.component';
import { WorksheetRedirectionPageComponent } from '@features/eda/pages/worksheet-redirection-page/worksheet-redirection-page.component';
import { DeephubDesignDataAugmentationComponent } from '@features/deephub/computer-vision/design/data-augmentation/deephub-design-data-augmentation.component';
import { DeephubImageClassificationDesignImageFeedComponent } from '@features/deephub/image-classification/design/image-feed-provider/deephub-image-classification-design-image-feed.component';
import { DeephubImageClassificationReportComponent } from '@features/deephub/image-classification/report/deephub-image-classification-report.component';
import { DeephubImageClassificationReportInteractiveScoringComponent } from '@features/deephub/image-classification/report/interactive-scoring/deephub-image-classification-report-interactive-scoring.component';
import { DeephubObjectDetectionDesignImageFeedComponent } from '@features/deephub/object-detection/design/image-feed-provider/deephub-object-detection-design-image-feed.component';
import { DeephubObjectDetectionReportComponent } from '@features/deephub/object-detection/report/deephub-object-detection-report.component';
import { DeephubObjectDetectionReportInteractiveScoringComponent } from '@features/deephub/object-detection/report/interactive-scoring/deephub-object-detection-report-interactive-scoring.component';
import { LabelingComponent } from '@features/labeling/labeling.component';
import { LabelingContainerComponent } from '@features/labeling/labeling-container/labeling-container.component';
import { ExperimentTrackingRunsListComponent } from '@features/experiment-tracking/experiment-tracking-runs-list.component';
import { ExperimentTrackingRunComponent } from '@features/experiment-tracking/experiment-tracking-run/experiment-tracking-run.component';
import { ExperimentTrackingComponent } from '@features/experiment-tracking/experiment-tracking/experiment-tracking.component';
import { FeatureStoreComponent } from '@features/feature-store/feature-store.component';
import { AcademyLinkComponent, ChartTypePickerComponent } from '@shared/modules';
import { BasicSelectComponent } from '@shared/components/basic-select/basic-select.component';
import { Ng2LoaderComponent } from '@core/ng2-loader/ng2-loader.component';
import { ObjectDetectionDatasetViewExploreImagesComponent } from '@features/dataset-view/explore-images/image-feed/object-detection/object-detection-dataset-view-explore-images.component';
import { ImageClassificationDatasetViewExploreImagesComponent } from '@features/dataset-view/explore-images/image-feed/image-classification/image-classification-dataset-view-explore-images.component';
import { SimpleDatasetViewExploreImagesComponent } from '@features/dataset-view/explore-images/image-feed/simple/simple-dataset-view-explore-images.component';
import { RightPanelSchemaComponent } from '@shared/components/right-panel-summary/right-panel-schema/right-panel-schema.component';
import { DowngradedAccessibleObjectsSelectorComponent } from '@shared/components/accessible-objects-selector/downgraded/downgraded-accessible-objects-selector.component';
import { DatasetViewImagePreview } from '@features/dataset-view/image-preview/dataset-view-image-preview.component';

export function directive(ajsDirective: string, component: Type<any>, inputs?: string[], outputs?: string[]): Type<any> {
    const angularJS = (window as fairAny).angular;
    angularJS.module('dataiku').directive(ajsDirective, downgradeComponent({ component, inputs, outputs, propagateDigest: false }));
    return component;
}

export function downgradeComponents(): Array<Type<any>> {
    return [
        directive('ng2KeyValuesList', DowngradedKeyValuesListComponent),
        directive('ng2ValuesList', DowngradedValuesListComponent),
        directive('ng2CredentialsList', DowngradedCredentialsListComponent),
        directive('ng2ConnectionPropertiesList', DowngradedConnectionPropertiesListComponent),
        directive('ng2Eda', EdaComponent),
        directive('ng2ExperimentTracking', ExperimentTrackingComponent),
        directive('ng2ExperimentTrackingRunsList', ExperimentTrackingRunsListComponent),
        directive('ng2ExperimentTrackingRun', ExperimentTrackingRunComponent),
        directive('ng2EdaWorksheetRedirectionPage', WorksheetRedirectionPageComponent),
        directive('ng2EdaInsight', InsightCardComponent, ['insight', 'readOnly'], ['updated']),
        directive('ng2LazyEchart', LazyEchartComponent),
        directive('ng2Typeahead', DowngradedEditTypeaheadInputComponent),
        directive('ng2Labeling', LabelingComponent),
        directive('ng2LabelingContainer', LabelingContainerComponent),
        directive("ng2ObjectDetectionDatasetViewExploreImages", ObjectDetectionDatasetViewExploreImagesComponent),
        directive("ng2ImageClassificationDatasetViewExploreImages", ImageClassificationDatasetViewExploreImagesComponent),
        directive("ng2SimpleDatasetViewExploreImages", SimpleDatasetViewExploreImagesComponent),
        directive("ng2DatasetViewImagePreview", DatasetViewImagePreview),
        directive("ng2DeephubImageClassificationDesignImageFeed", DeephubImageClassificationDesignImageFeedComponent),
        directive("ng2DeephubObjectDetectionDesignImageFeed", DeephubObjectDetectionDesignImageFeedComponent),
        directive("ng2DeephubDesignDataAugmentation", DeephubDesignDataAugmentationComponent),
        directive("ng2DeephubObjectDetectionReportInteractiveScoring", DeephubObjectDetectionReportInteractiveScoringComponent),
        directive("ng2DeephubImageClassificationReportInteractiveScoring", DeephubImageClassificationReportInteractiveScoringComponent),
        directive("ng2DeephubObjectDetectionReport", DeephubObjectDetectionReportComponent),
        directive("ng2DeephubImageClassificationReport", DeephubImageClassificationReportComponent),
        directive("ng2FeatureStore", FeatureStoreComponent),
        directive("ng2BasicSelect", BasicSelectComponent),
        directive("ng2AccessibleObjectsSelector", DowngradedAccessibleObjectsSelectorComponent),
        directive("ng2AcademyLink", AcademyLinkComponent),
        directive('ng2Loader', Ng2LoaderComponent),
        directive('ng2RightPanelSchema', RightPanelSchemaComponent),
        directive("ng2ChartTypePicker", ChartTypePickerComponent),
        directive("ng2BasicSelect", BasicSelectComponent),
    ];
}
