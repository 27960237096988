<div class="editable-list" [class.editable-list--full-width]="fullWidthList" [class.h100]="fullHeightList">
    <ng-container *ngIf="disableRawEdit || !inRawEditMode; else rawEditView">
        <!-- Virtual scroll main block -->
        <ng-container *ngIf="useVirtualScroll; else noVirtualScroll">
            <cdk-virtual-scroll-viewport #virtualScrollViewport inViewport forceResize [dynamicViewport]="itemsControls.length" [dynamicViewportMaxHeight]="virtualScrollHeight" [itemSize]="virtualScrollItemSize">
                <ul *ngIf="!sortable" class="editable-list__items">
                    <!-- templateCacheSize 0 because sometimes list gets wonky and adds duplicate items -->
                    <li #itemLi *cdkVirtualFor="let item of itemsControls; let index = index; templateCacheSize: 0" class="common-styles-only editable-list__item" [class.editable-list__item--divider]="hasDivider">
                        <ng-container *ngTemplateOutlet="itemTemplate, context: { item: item, index: index }"></ng-container>
                    </li>
                </ul>

                <!-- Main block when drag and drop is enabled -->
                <!-- virtual scroll + drag/drop technically not supported by angular -->
                <ul *ngIf="sortable" class="editable-list__items" cdkDropList (cdkDropListDropped)="drop($event)">
                    <li #itemLi *cdkVirtualFor="let item of itemsControls; let index = index;  templateCacheSize: 0" class="common-styles-only editable-list__item"  [class.editable-list__item--divider]="hasDivider" cdkDrag>
                        <editable-list-drag cdkDragHandle data-qa-editable-list-drag></editable-list-drag>
                        <ng-container *ngTemplateOutlet="itemTemplate, context: { item: item, index: index }"></ng-container>
                    </li>
                </ul>
            </cdk-virtual-scroll-viewport>
        </ng-container>

        <!-- Main block -->
        <ng-template #noVirtualScroll>
            <ul *ngIf="!sortable" class="editable-list__items">
                <li #itemLi *ngFor="let item of itemsControls; let index = index;" class="common-styles-only editable-list__item" [class.editable-list__item--divider]="hasDivider">
                    <ng-container *ngTemplateOutlet="itemTemplate, context: { item: item, index: index }"></ng-container>
                </li>
            </ul>

            <!-- Main block when drag and drop is enabled -->
            <ul *ngIf="sortable" class="editable-list__items" cdkDropList (cdkDropListDropped)="drop($event)">
                <li #itemLi *ngFor="let item of itemsControls; let index = index;" class="common-styles-only editable-list__item"  [class.editable-list__item--divider]="hasDivider" cdkDrag>
                    <editable-list-drag cdkDragHandle data-qa-editable-list-drag></editable-list-drag>
                    <ng-container *ngTemplateOutlet="itemTemplate, context: { item: item, index: index }"></ng-container>
                </li>
            </ul>
        </ng-template>
    </ng-container>

    <ng-template #rawEditView>
        <textarea [(ngModel)]="rawItems" class="editable-list__raw"></textarea>
    </ng-template>

    <!-- Add button footer -->
    <div class="editable-list__actions">
        <div class="editable-list__add" *ngIf="!disableAdd && !inRawEditMode">
            <button type="button"
                    data-qa-add-button
                    class="btn btn--primary btn--text editable-list__add-label"
                    (click)="add()"
                    (mouseover)="onMouseOverAddButton.emit()"
                    (mouseleave)="onMouseLeaveAddButton.emit()">
                <i class="icon-plus"></i>{{addLabel}}
            </button>
        </div>

        <ng-container *ngIf="!disableRawEdit">
            <div *ngIf="!inRawEditMode">
                <button type="button"class="btn btn--secondary btn--text" (click)="enterRawEditMode()">
                    <i class="icon-edit"></i>Raw Text Edit
                </button>
            </div>

            <ng-container *ngIf="inRawEditMode">
                <button type="button"class="btn btn--primary btn--text" (click)="parseRawData()">
                    Save
                </button>

                <button type="button"class="btn btn--secondary btn--text" (click)="inRawEditMode = false">
                    Cancel
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>

<!-- Row template -->
<ng-template #itemTemplate let-item="item" let-index="index">
    <!-- Default Template -->
    <editable-list-template *ngIf="!template">
        {{item?.get("item").value}} at index {{ index }}
    </editable-list-template>

    <!-- Custom Template -->
    <ng-container *ngIf="template">
        <ng-container *ngTemplateOutlet="template, context: { item: item, index: index, goToNext: enter(index) }"></ng-container>
    </ng-container>

    <editable-list-delete (onDelete)="delete($event, index)"></editable-list-delete>
</ng-template>
