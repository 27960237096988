import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FeatureGroupDetails } from "src/generated-sources";
import { Feature } from "../feature-store-models";
import { FeatureStoreService } from "../feature-store.service";
import { formatDescription, formatItemName, formatProjectName } from "../highlight-utils";
import { Highlight, Hit } from "../query-result-models";

@Component({
  selector: "feature-details",
  templateUrl: "./feature-details.component.html",
  styleUrls: ["./feature-details.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureDetailsComponent implements OnChanges {
  @Input() projectKey?: string;
  @Input() details!: FeatureGroupDetails;
  @Input() highlight!: Highlight;
  @Input() users!: Record<string, string>;
  @Input() datasetsInCurrentProject!: string[];
  @Input() selectedFeature!: Hit<Feature>;
  @Output() useFeatureGroupInProject: EventEmitter<void> = new EventEmitter();

  useInProjectTitle: string = "Use in project";
  navigateTitle: string = "Navigate around";
  viewInProjectTitle: string = "See in flow";

  showFeatureGroupDetails: boolean = false;

  constructor(readonly featureStoreService: FeatureStoreService) {}

  ngOnChanges() {
    if (this.featureGroupUsedInCurrentProject()) {
      this.useInProjectTitle = "This Feature Group is already used in current project.";
    } else {
      const useInProjectLabel = "Use in project" + (this.projectKey ? " " + this.projectKey : "");
      this.useInProjectTitle = this.disableUseInProject()
        ? "This Feature Group is not quickly shareable and access requests are not enabled for the source project"
        : useInProjectLabel;
    }
    this.navigateTitle = this.disableNavigator() ? "You don't have access to the source project" : "Navigate around";
    this.viewInProjectTitle = this.disableViewInProject()
      ? "You don't have access to the source project"
      : "See in flow";
  }

  formatProjectName() {
    return formatProjectName(this.highlight, this.details.projectName || "");
  }

  formatFeatureGroupName() {
    return formatItemName(this.highlight, this.details.datasetFullInfo.dataset.name || "");
  }

  formatDescription() {
    return formatDescription(this.highlight, this.selectedFeature._source.description || "");
  }

  datasetLink() {
    return this.featureStoreService.datasetLink(this.details);
  }

  viewInProject() {
    if (!this.disableViewInProject())
      this.featureStoreService.viewInProject(
        this.details.datasetFullInfo.dataset.projectKey,
        this.details.datasetFullInfo.dataset.name
      );
  }

  disableViewInProject() {
    return this.featureStoreService.disableViewInProject(this.details);
  }

  linkToProject() {
    return this.featureStoreService.linkToProject(this.details.datasetFullInfo.dataset.projectKey);
  }

  showNavigator() {
    if (!this.disableNavigator())
      this.featureStoreService.showNavigator(
        this.details.datasetFullInfo.dataset.projectKey,
        this.details.datasetFullInfo.dataset.name
      );
  }

  disableNavigator() {
    return this.featureStoreService.disableNavigator(this.details);
  }

  lastBuildInfo() {
    return this.featureStoreService.lastBuildInfo(this.details);
  }

  useInProject() {
    if (!this.disableUseInProject()) {
      this.useFeatureGroupInProject.emit();
    }
  }

  featureGroupUsedInCurrentProject() {
    return this.featureStoreService.featureGroupUsedInCurrentProject(
      this.datasetsInCurrentProject,
      this.details,
      this.projectKey
    );
  }

  disableUseInProject() {
    return this.featureStoreService.disableUseInProject(this.datasetsInCurrentProject, this.details, this.projectKey);
  }
}
