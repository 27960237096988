import { ChartVariant } from '@model-main/pivot/frontend/model/chart-variant';
import { EChartsOption, GridComponentOption, TitleComponentOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { cloneDeep, isNumber } from 'lodash';
import { ChartDataWrapper } from '../../../models';
import { ChartAxisTypes, ChartSortTypes } from '../../../enums';
import { ChartBase, ChartCoordinates, FrontendChartDef, FrontendMeasureDef } from '../../../interfaces';
import { EChartsAxesService } from '../echarts-axes.service';
import { EChartAxisTypes, EChartLabelPosition, EChartBarLabelPosition, EChartAxis, EChartNameLocation } from '../enums';
import { EChartMatrixPoint, EChartOptionsContext, EChartSeriesContext, Cartesian2DSeries, EChartDrawContext, FacetsMetadata, MetaEChartDef } from '../interfaces';
import { EChartDef } from './echart-def.model';
import { EChartPrepareDataContext } from './echart-prepare-data-context.model';
import { PrepareData } from './prepare-data.model';
import { ChartFormattingService } from '@features/simple-report/services';

export abstract class Cartesian2DEChartDef extends EChartDef {
    mainAxis: EChartAxis = EChartAxis.X;

    constructor(
        protected stringUtils: any,
        protected chartStoreFactory: any,
        protected chartFormattingOptions: any,
        protected chartLabelsService: any,
        protected chartFormattingService: ChartFormattingService,
        protected prepareDataService: PrepareData,
        protected echartsAxesService: EChartsAxesService
    ) {
        super(stringUtils, chartFormattingOptions, prepareDataService);
    }

    protected getSeriesId(coord: ChartCoordinates): string {
        const frameIndex = coord.animation;
        const facetIndex = coord.facet;
        const colorIndex = coord.color;
        const measureIndex = coord.measure;
        const coordinatesId = `animation${frameIndex}-facet${facetIndex}-color${colorIndex}-measure${measureIndex}`;
        return coordinatesId;
    }

    protected buildOneSeries(
        seriesId: string,
        chartDef: FrontendChartDef,
        measure: FrontendMeasureDef,
        extent: any,
        labelsResolution: any,
        color: any,
        colorLabel: string,
        labelPosition: EChartLabelPosition | EChartBarLabelPosition,
        formatter: (params: any, value: any) => string
    ): Cartesian2DSeries {
        const builtSeries: Cartesian2DSeries = {
            id: seriesId,
            name: colorLabel || this.chartLabelsService.getLongMeasureLabel(measure), //  We use the color label if a color dimension is defined or else we get the measure label
            itemStyle: {
                opacity: chartDef.colorOptions.transparency
            },
            animationDuration: 300,
            data: [],
            color
        };

        if (chartDef.showInChartValues) {
            const isPercent = this.isMeasureComputedInPercentage(measure) || chartDef.variant === ChartVariant.stacked_100;
            const formatValue = this.chartFormattingService.createNumberFormatter(measure, extent, labelsResolution, isPercent);

            builtSeries.label = {
                show: true,
                position: labelPosition,
                fontFamily: 'SourceSansPro',
                color: '#333',
                fontSize: 12,
                formatter: (params: any) => {
                    return formatter(params, formatValue);
                }
            };

            builtSeries.labelLayout = {
                hideOverlap: true
            };
        }

        return builtSeries;
    }

    protected getAxisValue(axisValues: any, axisType: string): string | number {
        let axisValue: string | number = axisValues.label;

        switch (axisType) {
            case EChartAxisTypes.VALUE:
            case EChartAxisTypes.LOG:
                axisValue = axisValues.sortValue;
                break;
            case EChartAxisTypes.TIME:
                axisValue = axisValues.tsValue;
                break;
        }

        return axisValue;
    }

    protected abstract getAxesSpecs(chartDef: FrontendChartDef, matrixPoints: Array<EChartMatrixPoint>, chartData: ChartDataWrapper): any;

    protected abstract getSeries(seriesContext: EChartSeriesContext): Array<Cartesian2DSeries>;

    protected abstract getOptions(optionsContext: EChartOptionsContext): EChartsOption;

    /**
     * Used to retrieve each point value on x or y axis for sorting
     * @param {number} measureIndex
     * @param {echarts series} series
     * @param {boolean} invert default: x, else: y
     */
    private getWeights(measureIndex: number, series: any, invert: any) {
        return series.reduce((acc: any, serie: any) => {
            if (serie.id && serie.id.includes(`m${measureIndex}`)) {
                serie.data.forEach((point: any) => {
                    if (!acc[point[invert ? 1 : 0]]) {
                        acc[point[invert ? 1 : 0]] = point[invert ? 0 : 1];
                    } else {
                        acc[point[invert ? 1 : 0]] += point[invert ? 0 : 1];
                    }
                });
            }

            return acc;
        }, {});
    }

    /**
     * Used to sort axes by natural order (alphanumerical) or by measure value (asc or desc)
     * @param {echarts options} options
     */
    private sort(specs: Array<any>, options: EChartsOption) {
        const updatedOptions = { ...options };
        const dimensionSpec = Object.values(specs).find((spec) => spec.type === ChartAxisTypes.DIMENSION);

        if (!dimensionSpec) {
            return updatedOptions;
        }

        let axes: Array<XAXisComponentOption> | Array<YAXisComponentOption> = options.xAxis as Array<XAXisComponentOption> | undefined || [];

        if (dimensionSpec.name === 'y') {
            axes = options.yAxis as Array<YAXisComponentOption> | undefined || [];
        }

        const sort = dimensionSpec.dimension && dimensionSpec.dimension.sort;
        const weights = this.getWeights(sort.measureIdx, options.series, dimensionSpec.name === 'y');

        axes.forEach((axis) => {
            if (axis.type === EChartAxisTypes.CATEGORY && sort && sort.type && 'data' in axis && axis.data) {
                axis.inverse = dimensionSpec.name === 'y';

                switch (sort.type) {
                    case ChartSortTypes.NATURAL:
                        axis.data = axis.data.sort((a: any, b: any) => a - b);
                        break;
                    case ChartSortTypes.AGGREGATION:
                        axis.data = axis.data.sort((a: any, b: any) => weights[a] - weights[b]);
                        break;
                }
            } else {
                axis.inverse = dimensionSpec.ascendingDown;

                /** When inverted, time y axis is not correctly computed by echarts, so we add a 5% gap at each edge of the chart */
                if (axis.type === EChartAxisTypes.TIME && dimensionSpec.name === 'y' && isNumber(axis.min) && isNumber(axis.max)) {
                    const gap = ((axis.max - axis.min) * 5) / 100;
                    axis.min = axis.min - gap;
                    axis.max = axis.max + gap;
                }
            }
        });

        return updatedOptions;
    }

    //  Sets an additional interval on time axis at the left and right sides to add spacing between y axes and bars
    protected addIntervalToTimeAxes(xAxes: Array<XAXisComponentOption>): Array<XAXisComponentOption> {
        return xAxes.map((xAxis) => {
            if (xAxis.type === 'time' && isNumber(xAxis.min) && isNumber(xAxis.max)) {
                const range = xAxis.max - xAxis.min;
                const gap = (range * 3) / 100;
                xAxis.min = xAxis.min - gap;
                xAxis.max = xAxis.max + gap;
                //  We remove 6% of available axis width, so we need 6% less splits
                (xAxis as any).maxInterval = Math.floor((xAxis as any).maxInterval + ((xAxis as any).maxInterval * 6) / 100);
            }

            return xAxis;
        });
    }

    draw(drawContext: EChartDrawContext<ChartDataWrapper>): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        let gridOptions = drawContext.chartBase.margins;

        const xAxes = [];
        const yAxes = [];

        const prepareDataContext: EChartPrepareDataContext<ChartDataWrapper> = {
            axis: this.mainAxis,
            chartDef: drawContext.chartDef,
            chartData: drawContext.chartData,
            legends: drawContext.legends,
            colorScale: drawContext.chartBase.colorScale,
            mapper: value => this.mapValue(value)
        };

        const chartPoints = this.prepareData(prepareDataContext);

        const specs = this.getAxesSpecs(drawContext.chartDef, chartPoints, drawContext.chartData);

        this.chartStoreFactory.get(drawContext.chartId).setAxisSpecs(
            { xSpec: specs.xSpec, ySpec: specs.ySpec, y2Spec: specs.y2Spec },
            drawContext.frameIndex,
            drawContext.facetIndex
        );

        const axes = this.echartsAxesService.getAxesAndMargins(
            drawContext.chartData,
            drawContext.chartDef,
            drawContext.chartBase.width,
            drawContext.chartBase.height,
            drawContext.chartBase.margins,
            specs,
            drawContext.noXAxis,
            drawContext.noYAxis
        );

        axes.xAxisOptions && xAxes.push(axes.xAxisOptions);
        axes.yAxisOptions && yAxes.push(axes.yAxisOptions);
        axes.y2AxisOptions && yAxes.push(axes.y2AxisOptions);

        // Hide split lines if we already have the ones of the left y axis
        if (yAxes[1]) {
            yAxes[1].splitLine = { show: false };
        }

        gridOptions = axes.gridOptions;

        const chartWidth = drawContext.chartBase.width - (gridOptions.left || 0) - (gridOptions.right || 0);
        const chartHeight = drawContext.chartBase.height - (gridOptions.top || 0) - (gridOptions.bottom || 0);

        const seriesContext: EChartSeriesContext = {
            chartDef: drawContext.chartDef,
            chartData: drawContext.chartData,
            chartWidth,
            chartHeight,
            colorScale: drawContext.chartBase.colorScale,
            chartPoints,
            xAxes,
            yAxes,
            frameIndex: drawContext.frameIndex || 0
        };

        const series = this.getSeries(seriesContext);

        const optionsContext: EChartOptionsContext = {
            xAxes,
            yAxes,
            series,
            gridOptions
        };

        let options = this.getOptions(optionsContext);

        if (specs) {
            options = this.sort(specs, options);
        }

        const allCoords = this.getAllCoords(chartPoints);

        return {
            options,
            allCoords
        };
    }

    private updateManualExtent(chartDef: FrontendChartDef, xAxes: Array<XAXisComponentOption>, yAxes: Array<YAXisComponentOption>): void {
        /**
         * Extents are computed from first xAxis and yAxis
         */
        const xAxisExtent: [number, number] = [isNumber(xAxes[0].min) ? xAxes[0].min : Infinity, isNumber(xAxes[0].max) ? xAxes[0].max : -Infinity];
        const yAxisExtent: [number, number] = [isNumber(yAxes[0].min) ? yAxes[0].min : Infinity, isNumber(yAxes[0].max) ? yAxes[0].max : -Infinity];

        /*
         *  Mandatory for manual range
         *  Used to to prevent the chart def watcher to take the incoming changes into account
         */
        chartDef.$ignoreFields = ['xCustomExtent.manualExtent', 'yCustomExtent.manualExtent'];

        if (xAxisExtent[0] !== Infinity && xAxisExtent[1] !== -Infinity) {
            chartDef.xCustomExtent.manualExtent = xAxisExtent;
        }

        if (yAxisExtent[0] !== Infinity && yAxisExtent[1] !== -Infinity) {
            chartDef.yCustomExtent.manualExtent = yAxisExtent;
        }

        //  Reset it afterwards
        setTimeout(() => {
            chartDef.$ignoreFields = [];
        });
    }

    private getMetaEChartDef(
        chartDef: FrontendChartDef,
        xAxes: Array<XAXisComponentOption>,
        yAxes: Array<YAXisComponentOption>,
        series: Array<Cartesian2DSeries>,
        grid: GridComponentOption,
        maxContainerWidth: number,
        noXAxis?: boolean
    ): MetaEChartDef {
        const left = isNumber(grid.left) ? grid.left : parseInt(grid.left || '0');
        const height = chartDef.displayXAxis && !noXAxis ? grid.bottom : 20;
        const meta: MetaEChartDef = {
            height,
            options: {
                grid: {
                    //  bottom and height are set to cheat echarts to display only xAxis
                    bottom: height,
                    left: maxContainerWidth + left,
                    right: grid.right,
                    height: height
                },
                xAxis: xAxes.map(xAxis => ({ ...xAxis, show: !!(chartDef.displayXAxis && !noXAxis) })),
                yAxis: yAxes.map(yAxis => ({ ...yAxis, show: false })),
                series
            }
        };

        return meta;
    }

    private getFacetGridOption(
        grid: GridComponentOption,
        top: number,
        bottom: number,
        height: number,
        maxContainerWidth: number
    ): GridComponentOption {
        const updatedGrid: GridComponentOption = {
            ...grid,
            top,
            bottom,
            left: maxContainerWidth + (isNumber(grid.left) ? grid.left : parseInt(grid.left || '0')),
            height
        };

        return updatedGrid;
    }

    private getSeriesByFacets(series: Array<Cartesian2DSeries> = []): Array<Cartesian2DSeries> {
        let updatedSeries = cloneDeep(series);

        /**
         * Retrieves number of axes used by series
         * On series with axes, there is at least one axis
         */
        let seriesAxes = { xAxisIndices: { 0: true } as any, yAxisIndices: { 0: true } as any };
        seriesAxes = series
            .reduce((acc, seriesItem) => {
                if (isNumber(seriesItem.xAxisIndex)) {
                    acc.xAxisIndices[seriesItem.xAxisIndex] = true;
                }

                if (isNumber(seriesItem.yAxisIndex)) {
                    acc.yAxisIndices[seriesItem.yAxisIndex] = true;
                }

                return acc;
            }, seriesAxes);

        const xAxisIndices = Object.keys(seriesAxes.xAxisIndices);
        const yAxisIndices = Object.keys(seriesAxes.yAxisIndices);

        updatedSeries = updatedSeries
            .map(seriesItem => {
                const coordIds = (seriesItem.id as string).split('-');
                const facetId = coordIds[1];
                const seriesItemFacetIndex = parseInt(facetId.split('facet')[1]);

                /**
                 * For each facet, we need to duplicate axes
                 * Thus, series should target the axis on which they belong
                 */
                seriesItem.xAxisIndex = (seriesItem.xAxisIndex || 0) + xAxisIndices.length * seriesItemFacetIndex;
                seriesItem.yAxisIndex = (seriesItem.yAxisIndex || 0) + yAxisIndices.length * seriesItemFacetIndex;
                return seriesItem;
            });

        return updatedSeries;
    }

    private getOptionsByFacets(context: {
        options: EChartsOption,
        facetsMetadata: FacetsMetadata,
        chartHeight: number,
        singleXAxis?: boolean,
        displayXAxis?: boolean | null,
        noXAxis?: boolean
    }): EChartsOption {
        const updatedSeries = this.getSeriesByFacets(context.options.series as Array<Cartesian2DSeries>);

        let updatedOptions: EChartsOption = {
            xAxis: [],
            yAxis: [],
            series: updatedSeries,
            grid: [],
            title: []
        };

        const grid = context.options.grid as GridComponentOption;
        const top = isNumber(grid.top) ? grid.top : parseInt(grid.top || '0');
        const bottom = isNumber(grid.bottom) ? grid.bottom : parseInt(grid.bottom || '0');

        updatedOptions = context.facetsMetadata.facets.reduce((acc, facet, facetIndex, facets) => {
            let updatedXAxis: XAXisComponentOption = {
                ...(context.options.xAxis as Array<XAXisComponentOption>)[0],
                gridIndex: facetIndex
            };

            if (context.singleXAxis) {
                updatedXAxis = {
                    ...updatedXAxis,
                    name: undefined,
                    nameTextStyle: undefined,
                    nameGap: 0,
                    nameLocation: EChartNameLocation.END,
                    axisLine: {
                        show: true
                    },
                    splitLine: {
                        show: false
                    },
                    show: true,
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                };
            }

            (acc.xAxis as Array<XAXisComponentOption>).push(updatedXAxis);

            const updatedYAxes: Array<YAXisComponentOption> = (context.options.yAxis as Array<YAXisComponentOption>)
                .map(yAxis => {
                    return {
                        ...yAxis,
                        gridIndex: facetIndex
                    };
                });
            (acc.yAxis as Array<YAXisComponentOption>).push(...updatedYAxes);

            let updatedTop = top;
            let updatedBottom = bottom;
            let height = context.chartHeight - top - bottom;

            if (context.singleXAxis) {
                updatedTop = top + context.chartHeight * facetIndex;
                updatedBottom = context.displayXAxis && !context.noXAxis ? 0 : 1;
                height = context.chartHeight - top - updatedBottom;
            } else {
                if (facetIndex > 0 && acc.grid && 'length' in acc.grid) {
                    updatedTop = (acc.grid[facetIndex - 1].bottom as number) + top + context.chartHeight * facetIndex;
                }

                if (facetIndex < facets.length - 1) {
                    updatedBottom = 0;
                }
            }

            const facetGrid = this.getFacetGridOption(
                grid,
                updatedTop,
                updatedBottom,
                height,
                context.facetsMetadata.maxContainerWidth
            );

            (acc.grid as Array<GridComponentOption>).push(facetGrid);

            const title = context.options.title as TitleComponentOption;
            const facetTitle = this.getFacetTitleOption(
                facet,
                facetIndex,
                top,
                context.chartHeight,
                context.facetsMetadata.maxContainerWidth,
                context.singleXAxis,
                title?.subtext,
                title?.subtextStyle
            );

            (acc.title as Array<TitleComponentOption>).push(facetTitle);

            return acc;
        }, updatedOptions);

        return updatedOptions;
    }

    getThumbnailOptions(options: EChartsOption) {

        const updatedXAxes: any[] = [];
        const updatedYAxes: any[] = [];
        const updatedSeries: Cartesian2DSeries[] = [];

        (options.xAxis as any[]).forEach(axis => {
            updatedXAxes.push({
                show: false,
                scale: axis.scale,
                min: axis.min,
                max: axis.max,
                inverse: axis.inverse,
                interval: axis.interval,
                type: axis.type,
                data: axis.data
            });
        });

        (options.yAxis as any[]).forEach(axis => {
            updatedYAxes.push({
                show: false,
                scale: axis.scale,
                min: axis.min,
                max: axis.max,
                inverse: axis.inverse,
                interval: axis.interval,
                type: axis.type,
                data: axis.data
            });
        });

        (options.series as any[]).forEach(serie => {
            updatedSeries.push({
                type: serie.type,
                itemStyle: serie.itemStyle,
                animation: false,
                data: serie.data,
                color: serie.color,
                emphasis: {
                    disabled: true
                },
                label: {
                    show: false
                }
            });
        });
        
        return {
            xAxis: updatedXAxes,
            yAxis: updatedYAxes,
            series: updatedSeries,
            animation: false,
            tooltip: {
                show: false
            }
        };   
    }

    drawFacets(
        facets: Array<{ label: string }>,
        { chartId, chartDef, chartData, chartBase, legends, frameIndex, noXAxis, noYAxis }: EChartDrawContext<ChartDataWrapper>
    ): { meta?: MetaEChartDef, options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        const facetsMetadata = this.getFacetsMetadata(facets);

        const chartBaseForFacet: ChartBase = {
            ...chartBase,
            height: chartDef.chartHeight,
            width: chartBase.width - facetsMetadata.maxContainerWidth
        };

        const facetsResult = this.draw({
            chartId,
            chartDef,
            chartData,
            chartBase: chartBaseForFacet,
            noXAxis,
            noYAxis,
            legends,
            frameIndex
        });

        const xAxes = facetsResult.options.xAxis as Array<XAXisComponentOption>;
        const yAxes = facetsResult.options.yAxis as Array<YAXisComponentOption>;
        const series = facetsResult.options.series as Array<Cartesian2DSeries>;

        //  Mandatory for manual range
        this.updateManualExtent(chartDef, xAxes, yAxes);

        const meta = this.getMetaEChartDef(
            chartDef,
            xAxes,
            yAxes,
            series,
            facetsResult.options.grid as GridComponentOption,
            facetsMetadata.maxContainerWidth,
            noXAxis
        );

        const updatedOptions = this.getOptionsByFacets({
            options: facetsResult.options,
            facetsMetadata,
            chartHeight: chartDef.chartHeight,
            singleXAxis: chartDef.singleXAxis,
            displayXAxis: chartDef.displayXAxis,
            noXAxis
        });

        return { options: updatedOptions, allCoords: facetsResult.allCoords, meta };
    }
}
