import { BrowserModule } from '@angular/platform-browser';
import { DoBootstrap, NgModule, APP_INITIALIZER } from '@angular/core';
import { upgradedProviders } from '@migration/upgraded-providers';
import { DkuActivatedRouteService } from '@migration/dku-activated-route';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { KeyValuesListModule } from './widgets/lists/key-values-list/key-values-list.module';
import { ValuesListModule } from './widgets/lists/values-list/values-list.module';
import { CredentialsListModule } from './widgets/lists/credentials-list/credentials-list.module';
import { ConnectionPropertiesListModule } from './widgets/lists/connection-properties-list/connection-properties-list.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalsModule } from '@shared/modals/modals.module';
import { CoreModule } from '@core/core.module';
import { EdaModule } from '@features/eda/eda.module';
import { FeatureStoreModule } from '@features/feature-store/feature-store.module';

import { DataikuWT1Module,QaModule, QaModeService, QaSelectorService } from 'dku-frontend-core';
import { DkuHttpService } from 'dku-frontend-core';
import { DkuLegacyHttpService } from '@core/requests/dku-legacy-http.service';
import { EditTypeAheadModule } from './widgets/inputs/edit-typeahead-input/edit-typeahead-input.module';
import { LabelingModule } from '@features/labeling/labeling.module';
import { DeephubModule } from '@features/deephub/deephub.module';
import { WorkspacesModule } from '@features/workspaces/workspaces.module';
import { ExperimentTrackingModule } from '@features/experiment-tracking/experiment-tracking.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DatasetViewModule } from '@features/dataset-view/dataset-view.module';
import { DashboardsModule } from '@features/dashboards';
import { SimpleReportEchartModule } from '@features/simple-report';


@NgModule({
    imports: [
        BrowserModule,
        WidgetsModule,
        BrowserAnimationsModule,
        MatDialogModule,
        // Core module (singleton services)
        CoreModule,
        ModalsModule,
        EdaModule,
        LabelingModule,
        DeephubModule,
        DatasetViewModule,
        WorkspacesModule,
        DashboardsModule,
        KeyValuesListModule,
        ValuesListModule,
        EditTypeAheadModule,
        CredentialsListModule,
        ConnectionPropertiesListModule,
        ExperimentTrackingModule,
        FeatureStoreModule,
        DataikuWT1Module.legacy(),
        QaModule,
        SimpleReportEchartModule,
        NgxEchartsModule.forRoot({
            // Lazy loaded echarts modules
            echarts: async () => (await import('./import-echarts')).echarts
        })
    ],
    providers: [
        ...upgradedProviders(),
        QaModeService,
        QaSelectorService,
        DkuActivatedRouteService, // Helper to prepare the transition to Angular2+ router when the migration is full
        { provide: '$scope', useExisting: '$rootScope' }, // Added to make upgraded angularjs directive work in modals... don't ask.
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [QaModeService, QaSelectorService],
            multi: true
        },
        { provide: DkuHttpService, useClass: DkuLegacyHttpService },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { floatLabel: 'always' }
        }
    ],
    bootstrap: [],
})
export class AppModule implements DoBootstrap {
    ngDoBootstrap() {
    }
}
