import { Injectable } from '@angular/core';
import { utcFormat } from 'd3-time-format';
import { FrontendChartDef } from '../interfaces';
import { ChartDataWrapper, ChartTensorDataWrapper } from '../models';
import { NumberFormatterService } from './number-formatter.service';

@Injectable({
    providedIn: 'root'
})
export class ChartFormattingService {
    constructor(
        private numberFormatterService: NumberFormatterService
    ) {
    }

    /**
     * Returns a number formatter configured with the given parameters.
     *
     * @param                       measureOptions  - options to format measures (can also be numerical UA and dimensions)
     * @param {[number, number]}    extent
     * @param {number}              labelsResolution
     * @param {boolean | undefined} isPercent
     * @returns {(value: number) => string}
     */
    createNumberFormatter(measureOptions: any, extent: [number, number] | [undefined, undefined], labelsResolution: number, isPercent?: boolean): (value: number) => string {
        return this.numberFormatterService.get(extent[0] || 0, extent[1] || 0, labelsResolution, true, true, measureOptions, isPercent);
    }

    createMeasureFormatters(chartDef: FrontendChartDef, chartData: ChartDataWrapper, labelsResolution: number): Array<(value: number) => string> {
        const formatters = [];
        let mIdx = 0;

        /*
         *  Measure formatters are based on aggregated data
         *  @TODO : handle unaggregated data like scatter?
         */
        if (chartData instanceof ChartTensorDataWrapper) {
            chartDef.genericMeasures.forEach(measure => {
                formatters.push(this.createNumberFormatter(measure, chartData.getAggrExtent(mIdx++), labelsResolution));
            });

            if (chartDef.xMeasure?.length) {
                formatters.push(this.createNumberFormatter(chartDef.xMeasure[0], chartData.getAggrExtent(mIdx++), labelsResolution));
            }
            if (chartDef.yMeasure?.length) {
                formatters.push(this.createNumberFormatter(chartDef.yMeasure[0], chartData.getAggrExtent(mIdx++), labelsResolution));
            }
            if (chartDef.sizeMeasure?.length) {
                formatters.push(this.createNumberFormatter(chartDef.sizeMeasure[0], chartData.getAggrExtent(mIdx++), labelsResolution));
            }
            if (chartDef.colorMeasure?.length) {
                formatters.push(this.createNumberFormatter(chartDef.colorMeasure[0], chartData.getAggrExtent(mIdx++), labelsResolution));
            }

            chartDef.tooltipMeasures.forEach((measure) => {
                formatters.push(this.createNumberFormatter(measure, chartData.getAggrExtent(mIdx++), labelsResolution));
            });
        }

        return formatters;
    }

    /** Gets a tick/ values formatter for measure values */
    getMeasuresFormatter(chartSpec: any, longOk: boolean) {
        if (chartSpec.stdAggregatedMeasureScale == 'AVG_RATIO'
                    || chartSpec.stdAggregatedMeasureScale == 'PERCENTAGE_SCALE'
                    || chartSpec.variant == 'stacked_100'
                    || (chartSpec.genericMeasures && chartSpec.genericMeasures[0] && chartSpec.genericMeasures[0].computeMode == 'PERCENTAGE')
        ) {
            return this.numberFormatterService.percentageNumberFilter();
        } else {
            if (longOk) {
                return this.numberFormatterService.longSmartNumberFilter();
            } else {
                return this.numberFormatterService.smartNumberFilter();
            }
        }
    }

    /**
     * Converts an epoch timestamp to date in universal time
     * @param {number} msEpoch - epoch timestamp
     * @returns {string} date as YYYY-MM-DD
     */
    getDateFormatter() {
        return (msEpoch: number) => (utcFormat('%Y-%m-%d')(new Date(msEpoch)));
    }
}
