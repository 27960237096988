<modal-header [title]="title" [hasBorder]="false"></modal-header>
<api-error-alert
    [apiError]="error?.error$ | async"
    (resetError)="error?.resetError()"
    [closable]="true"
></api-error-alert>
<form class="dkuform-modal-horizontal">
    <mat-tab-group
        class="edit-workspace-modal__tabs"
        [class.edit-workspace-modal__tabs--hidden]="!isEdition || tabs.length === 1"
        [selectedIndex]="currentTabIndex"
        (selectedIndexChange)="onTabChange($event)"
    >
        <mat-tab *ngFor="let tab of tabs" [label]="tab">
            <workspace-general-edition
                *ngIf="tab === EditTabs.GENERAL && opened"
                class="modal-body"
                [workspace]="editedWorkspace"
                [editionMode]="isEdition"
                (workspaceChange)="onWorkspaceChange($event)"
            ></workspace-general-edition>
            <ng-container *ngIf="tab === EditTabs.PERMISSIONS">
                <div *ngIf="!isEdition" class="modal__step-header mbot0 mtop8">
                    <a (click)="previousTab()" class="dku-back-link"><i class="icon-arrow-left"></i></a>
                    <span class="modal__step-header-title">Add users to your workspace</span>
                </div>
                <workspace-permissions-edition
                    class="modal-body"
                    [workspace]="editedWorkspace"
                    [users]="users$ | async"
                    [groups]="groups$ | async"
                    [editionMode]="isEdition"
                    (permissionsChange)="onWorkspaceChange({ permissions: $event })"
                ></workspace-permissions-edition>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</form>
<div class="modal-footer modal-footer-std-buttons">
    <div class="pull-right">
        <button type="button" class="btn btn-default" (click)="cancel()">{{ isEdition && !canEdit ? 'Close' : 'Cancel' }}</button>
        <ng-container *ngIf="!isEdition && currentTabIndex < (tabs.length - 1); then nextBtn; else confirmBtn"></ng-container>
        <ng-template #nextBtn>
            <button type="button" class="btn btn-primary" (click)="nextTab()" [disabled]="!canNext">Next</button>
        </ng-template>
        <ng-template #confirmBtn>
            <button [hidden]="isEdition && !canEdit" type="submit" class="btn btn-primary modal-btn--confirm" (click)="confirm()" [disabled]="!canSubmit" autofocus>
                {{ isEdition ? 'Update workspace' : 'Create workspace' }}
            </button>
        </ng-template>
    </div>
</div>
