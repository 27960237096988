<modal-header title="Add content to workspace" icon="icon-plus"></modal-header>
<div class="modal-body">
    <api-error-alert
        [apiError]="error?.error$ | async"
        (resetError)="error?.resetError()"
        [closable]="true">
    </api-error-alert>
    <workspace-add-objects
        [workspace]="workspace"
        (onChange)="onObjectsChanged($event)">
    </workspace-add-objects>
    <div class="modal-footer modal-footer-std-buttons">
        <div class="pull-right">
            <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="confirm()" [disabled]="!objects.length" autofocus>
                Add content
            </button>
        </div>
    </div>
</div>
