import { Injectable } from '@angular/core';
import { format, formatLocale, formatDefaultLocale, FormatLocaleDefinition } from 'd3-format';
import { max, min } from 'd3-array';
import { cloneDeep, isInteger } from 'lodash';
import { ChartAxisTypes } from '../enums';
import { MultiplierInfo } from '../interfaces';
import { ChartStaticDataService } from './chart-static-data.service';
import { MathUtilsService } from './math-utils.service';
import { UnitSymbol } from '../enums/unit-symbol.enum';

@Injectable({
    providedIn: 'root'
})
export class NumberFormatterService {
    private formatPrefixes = ['y', 'z', 'a', 'f', 'p', 'n', 'µ', 'm', '', 
        UnitSymbol.K, UnitSymbol.M, UnitSymbol.G, UnitSymbol.T, UnitSymbol.P, UnitSymbol.E, UnitSymbol.Z, UnitSymbol.Y]
        .map((prefix, index) => this.formatPrefix(prefix, index));

    constructor(
        private chartStaticDataService: ChartStaticDataService,
        private mathUtilsService: MathUtilsService
    ) {
        //  This is mandatory as d3-format uses a different minus character: "−" (U+2212) instead of "-"
        formatDefaultLocale({
            minus: '-',
            thousands: ',',
            grouping: [3],
            currency: ['$', ''],
            decimal: '.'
        });
    }

    private formatPrecision(x: number, p: number) {
        return p - (x ? Math.ceil(Math.log(x) / Math.LN10) : 1);
    }

    private formatPrefix(d: string, i: number) {
        const k = Math.pow(10, Math.abs(8 - i) * 3);
        return {
            scale: i > 8 ? function(d: number) {
                return d / k;
            } : function(d: number) {
                return d * k;
            },
            symbol: d
        };
    }

    private round(x: number, n: number) {
        return n ? Math.round(x * (n = Math.pow(10, n))) / n : Math.round(x);
    }

    /*
     * This method and its dependencies are parts of the code retrieved in previous versions of d3
     * It is useful to get the precision and the symbol and precision of a given value
     */
    getPrefix(value: number, precision?: number) {
        let i = 0;
        if (value) {
            if (value < 0) {
                value *= -1;
            }
            if (precision) {
                value = this.round(value, this.formatPrecision(value, precision));
            }
            i = 1 + Math.floor(1e-12 + Math.log(value) / Math.LN10);
            i = Math.max(-24, Math.min(24, Math.floor((i - 1) / 3) * 3));
        }
        return this.formatPrefixes[8 + i / 3];
    }

    isPercentScale(measures: Array<any>): boolean {
        return measures.every((measure) => this.chartStaticDataService.MEASURES_PERCENT_MODES.includes(measure.computeMode));
    }

    shouldFormatInPercentage(measureOptions: any): boolean {
        return measureOptions && this.isPercentScale([measureOptions]);
    }

    appendPrefixAndSuffix(value: string, prefix = '', suffix = '', isPercentage = false): string {
        return prefix + value + (isPercentage ? '%' : '') + suffix;
    }

    getMinDecimals(minValue: number, maxValue: number, numValues: number) {
        // Suppose the values are evenly spaced, the minimum display precision we need is:
        const minPrecision = (maxValue - minValue) / numValues;

        // That means we need to have that many decimals: (can be negative: -1 means we don't even need the units number, -2 the hundreds, etc)
        return minPrecision > 0 ? Math.ceil(-this.mathUtilsService.log10(minPrecision)) : null;
    }

    getMultiplierFromLabel(multiplierLabel: any): MultiplierInfo {
        const multiplier = Object.values(this.chartStaticDataService.availableMultipliers).find(multiplier => multiplier.label === multiplierLabel);
        return multiplier ? multiplier : this.chartStaticDataService.highestMultiplier;
    }

    /**
     * Manually applies the asked multiplier and decimal places to the given value.
     *
     * @param   {number}  value                                         - Number to format.
     * @param   {object}  formattingOptions                             - Object containing user preferences for formatting.
     * @param   {number}  formattingOptions.minDecimals                 - Default number of decimals to keep. (can be negative: -1 means we don't even need the units number, -2 the hundreds, etc)
     * @param   {number}  [formattingOptions.customDecimalPlaces]       - Number of digits to keep after the decimal point.
     * @param   {object}  formattingOptions.multiplier                  - A multiplier from ChartsStaticData.allMultipliers or ChartsStaticData.Multipliers
     * @param   {boolean} [formattingOptions.shouldFormatInPercentage]  - True to append a %.
     * @returns {string} value, formatted as per the provided options.
     */
    applyMultiplierAndDecimalPlaces(value: any, formattingOptions: any): string {
        let unitPrefixSymbol = '';
        let powerOfTen = 0;
        let minDecimals = formattingOptions.minDecimals;

        if (value !== 0) {
            // A number displayed in percentages cannot have a multiplier.
            if (!formattingOptions.shouldFormatInPercentage && formattingOptions.multiplier) {
                unitPrefixSymbol = formattingOptions.multiplier.symbol || '';
                powerOfTen = formattingOptions.multiplier.powerOfTen;
            }

            if (powerOfTen > 0) {
                value = value / Math.pow(10, powerOfTen);
                minDecimals = formattingOptions.minDecimals + powerOfTen;
            }
        }

        if (!isNaN(formattingOptions.customDecimalPlaces)) {
            value = value.toFixed(formattingOptions.customDecimalPlaces);
        } else if (!isNaN(minDecimals) && minDecimals !== null) {
            value = value.toFixed(Math.max(0, minDecimals));
        }

        return `${value}${unitPrefixSymbol}`;
    }

    /**
     * Manually formats the given value using the given formatting options.
     *
     * To use when a multiplier has been selected (ie is not Auto), because we cannot force a specific unit prefix while formatting with D3 formatting helpers.
     *
     * @param   {number}  value                                         - Number to format.
     * @param   {object}  formattingOptions                             - Object containing user preferences for formatting.
     * @param   {number}  formattingOptions.minDecimals                 - Default number of decimals to keep. (can be negative: -1 means we don't even need the units number, -2 the hundreds, etc)
     * @param   {number}  [formattingOptions.customDecimalPlaces]       - Number of digits to keep after the decimal point.
     * @param   {object}  formattingOptions.multiplier                  - A multiplier from ChartsStaticData.allMultipliers or ChartsStaticData.Multipliers
     * @param   {boolean} [formattingOptions.shouldFormatInPercentage]  - True to append a %.
     * @returns {string} value, formatted as per the provided options.
     */
    formatValueManually(value: any, formattingOptions: any): string {
        return this.appendPrefixAndSuffix(
            this.applyMultiplierAndDecimalPlaces(value, formattingOptions),
            formattingOptions.prefix,
            formattingOptions.suffix,
            formattingOptions.shouldFormatInPercentage
        );
    }

    /**
     * Get the label matching the given prefix symbol according to the supported multipliers list.
     * @param   {string}    unitPrefixSymbol            - A symbol of a SI-prefix (k, M, G...).
     * @returns A multiplier object from ChartsStaticData.allMultipliers
     */
    getMultiplierFromUnitPrefixSymbol(unitPrefixSymbol: UnitSymbol): MultiplierInfo {
        // In DSS we chose to display billions as 'B' and not 'G' like in d3.
        unitPrefixSymbol = unitPrefixSymbol === UnitSymbol.G ? UnitSymbol.B : unitPrefixSymbol;
        const multiplier = Object.values(this.chartStaticDataService.allMultipliers).find(multiplier => multiplier.symbol === unitPrefixSymbol);
        return multiplier ? multiplier : this.chartStaticDataService.highestMultiplier;
    }

    /**
     * Format the given value knowing the unit prefix symbol to use.
     *
     * @param   {number}    value                                           - Number to format.
     * @param   {object}    formattingOptions                               - Object containing user preferences for formatting.
     * @param   {string}    formattingOptions.unitPrefixSymbol              - A symbol of a SI-prefix (k, M, G...).
     * @param   {number}    formattingOptions.minDecimals                   - Default number of decimals to keep. (can be negative: -1 means we don't even need the units number, -2 the hundreds, etc)
     * @param   {number}    [formattingOptions.customDecimalPlaces]         - Number of digits to keep after the decimal point.
     * @param   {string}    [formattingOptions.prefix]                      - Optional string to add before the formatted number
     * @param   {string}    [formattingOptions.suffix]                      - Optional string to add after the formatted number
     * @param   {boolean}   [formattingOptions.shouldFormatInPercentage]    - True to append a %.
     * @returns {string} value, formatted as per the provided options.
     */
    formatFromPrefix(value: any, formattingOptions: any): string {
        const formattingFromPrefixOptions = cloneDeep(formattingOptions);

        // A number displayed in percentages cannot have a multiplier.
        if (!formattingFromPrefixOptions.shouldFormatInPercentage) {
            formattingFromPrefixOptions.multiplier = this.getMultiplierFromUnitPrefixSymbol(formattingFromPrefixOptions.unitPrefixSymbol);
        }

        return this.formatValueManually(value, formattingFromPrefixOptions);
    }

    formatWithD3(value: any, specifier: any, prefix: string, suffix: string, isPercentage: boolean): string {
        // Default d3 suffix 'G' for billions must be replaced by 'B'
        return this.appendPrefixAndSuffix(format(specifier)(value).replace(/G/, 'B'), prefix, suffix, isPercentage);
    }

    /**
     * Format the given value in the more human-readable manner.
     *
     * @param   {number}    value                                         - Number to format.
     * @param   {object}    formattingOptions                             - Object containing user preferences for formatting.
     * @param   {number}    formattingOptions.minDecimals                 - Number of decimals to keep. (can be negative: -1 means we don't even need the unit prefix, -2 the hundreds, etc)
     * @param   {string}    [formattingOptions.coma]                      - Coma separator character.
     * @param   {boolean}   [formattingOptions.stripZeros]                - True to remove trailing zeros after the decimal point.
     * @param   {object}    [formattingOptions.measureOptions]            - Measure object containing user preferences for formatting.
     * @param   {boolean}   [formattingOptions.shouldFormatInPercentage]  - True to append a %.
     * @param   {number}    [formattingOptions.customDecimalPlaces]       - Number of digits to keep after the decimal point.
     * @param   {string}    [formattingOptions.customPrecision]           - Part of a specifier dedicated to precision to be used with d3.format().
     * @returns {string} value, formatted as per the provided options.
     */
    formatValueAutomatically(value: any, formattingOptions: any) {
        const abs = Math.abs(value);

        // If the number is too low, we don't apply any unit prefix and convert it in scientific notation unless if the user has specified a custom precision.
        if (abs < 0.00001 && !formattingOptions.customPrecision) {
            const scientificNotation = value.toExponential(Math.max(0, Math.max(formattingOptions.minDecimals, 0) - Math.floor(-this.mathUtilsService.log10(value)) - 1));
            return this.appendPrefixAndSuffix(scientificNotation, formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);
            // Else if we need decimals (and user hasn't specified a custom precision) we format with the required decimals and strip zeros if asked.
        } else if (formattingOptions.minDecimals > 0 && !formattingOptions.customPrecision) {
            const valueWithPrecision = format((formattingOptions.coma || '') + '.' + formattingOptions.minDecimals + 'f')(value);

            if (formattingOptions.stripZeros) {
                const strippedValue = valueWithPrecision.replace(/\.?0+$/, '');
                if (parseFloat(strippedValue) == value) {
                    return this.appendPrefixAndSuffix(strippedValue, formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);
                }
            }

            return this.appendPrefixAndSuffix(valueWithPrecision, formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);

            // Else if we don't need decimals but we still need to display a unit prefix (at least '10k')
        } else if (abs >= 10000) {
            if (formattingOptions.customPrecision) {
                /*
                 * d3.format can append the unit suffix thanks to "s" specifier but when doing so, it also rounds the value.
                 * In order to automatically get the unit prefix + apply custom places, we must go manual.
                 */
                const d3UnitPrefix = this.getPrefix(value);
                formattingOptions.unitPrefixSymbol = d3UnitPrefix.symbol;
                return this.formatFromPrefix(value, formattingOptions);
            } else {
                /*
                 * We trim the number based on minDecimals (<0): this will round and replace the last digits by zero
                 * (e.g. minDecimals = -4, x = 123456, => trimmedX = 120000)
                 */
                const trimmedValue = Math.round(value * Math.pow(10, formattingOptions.minDecimals)) * Math.pow(10, -formattingOptions.minDecimals);
                // Then we ask d3 to write the trimmed number with a unit prefix
                const d3UnitPrefix = this.getPrefix(trimmedValue);
                const prefixed = d3UnitPrefix.scale(trimmedValue) + d3UnitPrefix.symbol;

                /*
                 * Because it's been trimmed, prefixed can be 120k if the value was 123456
                 * In this case, we want to return 123k (as concise + more precise),
                 * so we just use the length of prefixed as reference and let d3 do the rest
                 */
                return this.formatWithD3(value, '.' + (prefixed.replace(/\D/g, '').length) + 's', formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);
            }
        }

        const precision = formattingOptions.customPrecision || (formattingOptions.coma + '.' + Math.max(0, formattingOptions.minDecimals) + 'f');

        return this.formatWithD3(value, precision, formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);
    }

    get(
        minValue: number,
        maxValue: number,
        numValues: number,
        comaSeparator?: boolean,
        stripZeros?: boolean,
        measureOptions?: any,
        shouldFormatInPercentage?: boolean
    ): (value: any) => string {
        shouldFormatInPercentage = shouldFormatInPercentage != undefined ? shouldFormatInPercentage : this.shouldFormatInPercentage(measureOptions);

        if (shouldFormatInPercentage) {
            minValue = minValue * 100;
            maxValue = maxValue * 100;
        }
        const minDecimals = this.getMinDecimals(minValue, maxValue, numValues);

        const coma = comaSeparator ? ',' : '';

        return (value: any) => {
            if (value === '___dku_no_value___') {
                return 'No value';
            }

            if (typeof value !== 'number') {
                return 'NA';
            }

            const formattingOptions: any = {
                multiplier: measureOptions && measureOptions.multiplier,
                prefix: measureOptions && measureOptions.prefix,
                suffix: measureOptions && measureOptions.suffix,
                shouldFormatInPercentage,
                minDecimals
            };

            if (formattingOptions.shouldFormatInPercentage) {
                value = value * 100;
            }

            if (measureOptions && measureOptions.decimalPlaces !== null && !isNaN(measureOptions.decimalPlaces)) {
                formattingOptions.customDecimalPlaces = measureOptions.decimalPlaces;
                formattingOptions.customPrecision = '.' + formattingOptions.customDecimalPlaces + 'f';
            }

            const arbitraryPrecisionZero = value > -1e-8 && value < 1e-8;

            if (value === 0 || arbitraryPrecisionZero) {
                return this.appendPrefixAndSuffix('0', formattingOptions.prefix, formattingOptions.suffix, formattingOptions.shouldFormatInPercentage);
            } else if (!formattingOptions.shouldFormatInPercentage && (measureOptions && measureOptions.multiplier
                && measureOptions.multiplier !== this.chartStaticDataService.autoMultiplier.label)) {
                formattingOptions.multiplier = this.getMultiplierFromLabel(measureOptions.multiplier);
                return this.formatValueManually(value, formattingOptions);
            } else {
                formattingOptions.coma = coma;
                formattingOptions.stripZeros = stripZeros;
                return this.formatValueAutomatically(value, formattingOptions);
            }
        };
    }

    /*
     * Allow to format manually or apply decimal places if these options are provided,
     * else fallback on the given formatter.
     */
    getForGivenFormatterInAutoMode(measureOptions: any, formatterInAutoMode: (value: any, options: any) => any) {
        return (value: any) => {
            const formattingOptions: any = {
                multiplier: measureOptions.multiplier,
                prefix: measureOptions.prefix,
                suffix: measureOptions.suffix
            };

            if (measureOptions && measureOptions.decimalPlaces !== null && !isNaN(measureOptions.decimalPlaces)) {
                formattingOptions.customDecimalPlaces = measureOptions.decimalPlaces;
            }

            if (value === 0) {
                return this.appendPrefixAndSuffix('0', measureOptions.prefix, measureOptions.suffix, false);
            } else if (!this.shouldFormatInPercentage(measureOptions) && (measureOptions && measureOptions.multiplier
                && measureOptions.multiplier !== this.chartStaticDataService.autoMultiplier.label)) {
                formattingOptions.minDecimals = null;
                formattingOptions.shouldFormatInPercentage = false;
                formattingOptions.multiplier = this.getMultiplierFromLabel(measureOptions.multiplier);
                return this.formatValueManually(value, formattingOptions);
            } else {
                return this.appendPrefixAndSuffix(
                    formatterInAutoMode(value, { customDecimalPlaces: formattingOptions.customDecimalPlaces }),
                    measureOptions.prefix, measureOptions.suffix, false
                );
            }
        };
    }

    getForOrdinalAxis(value: any): any {
        if (value === '___dku_no_value___') {
            return 'No value';
        }
        return value;
    }

    /**
     * getForAxis is used to get correct format for a given axis (it is used by d3 and echarts, so it should stay agnostic)
     * @param { number }            minValue
     * @param { number }            maxValue
     * @param { number }            numValues
     * @param { CHART_AXIS_TYPES }  axisType
     * @param { FormattingOptions } [formattingOptions = {}]   The number formatting options. Can be omitted to use the default formatting.
     * @param { boolean }           shouldFormatInPercentage
     */
    getForAxis(
        minValue: number,
        maxValue: number,
        numValues: number,
        axisType: ChartAxisTypes,
        formattingOptions: any = {},
        shouldFormatInPercentage = false
    ) {
        if (formattingOptions.multiplier === this.chartStaticDataService.autoMultiplier.label && (axisType === ChartAxisTypes.DIMENSION || axisType === ChartAxisTypes.UNAGGREGATED)) {
            return this.getForGivenFormatterInAutoMode(formattingOptions, (value: any, { customDecimalPlaces }: any) => customDecimalPlaces != null ? value.toFixed(customDecimalPlaces) : value);
        }
        return this.get(minValue, maxValue, numValues, false, true, formattingOptions, shouldFormatInPercentage);
    }

    /**
     * @param   {number}  minValue
     * @param   {number}  maxValue
     * @param   {number}  numValues
     * @param   {object}  measureOptions                            - Object containing user preferences for formatting.
     * @param   {number}  measureOptions.minDecimals                - Default number of decimals to keep. (can be negative: -1 means we don't even need the units number, -2 the hundreds, etc)
     * @param   {number}  [measureOptions.customDecimalPlaces]      - Number of digits to keep after the decimal point.
     * @param   {object}  measureOptions.multiplier                 - A multiplier from ChartsStaticData.allMultipliers or ChartsStaticData.Multipliers
     * @param   {boolean} [measureOptions.shouldFormatInPercentage] - True to append a %.
     * @param   {boolean} [stripZeros]
     * @returns {(extent: [number, number]) => string} a function that returns the extent, formatted as per the provided options.
     */
    getForExtent(minValue: number, maxValue: number, numValues: number, measureOptions: any, stripZeros?: boolean): (extent: [number, number]) => string {
        return (extent: [number, number]) => extent
            .map(value => this.get(minValue, maxValue, numValues, false, stripZeros, measureOptions)(value))
            .join('-');
    }

    getForBinnedAxis(tickExtents: any, formattingOptions = {}) {
        const measureOptions = {
            ...formattingOptions,
            shouldFormatInPercentage: false,
            stripZeros: true
        };

        const minValue = tickExtents[0][0];
        const maxValue = tickExtents[tickExtents.length - 1][1];
        const numValues = tickExtents.length * 2;

        return (_: any, index: number) => this.getForExtent(minValue, maxValue, numValues, measureOptions, true)(tickExtents[index]);
    }

    /**
     *
     * @param { d3.axis }                   axis
     * @param { ChartAxisTypes }   axisType
     * @param { FormattingOptions }         [formattingOptions = {}]     The number formatting options. Can be omitted to use the default formatting.
     */
    addToAxis(axis: any, axisType: ChartAxisTypes, formattingOptions = {}) {
        const scale = (axis.scale() instanceof Function) ? axis.scale() : axis.scale;
        const minValue = min<number>(scale.domain());
        const maxValue = max<number>(scale.domain());
        const numValues = axis.tickValues() ? axis.tickValues().length : axis.ticks()[0];
        axis.tickFormat(this.getForAxis(minValue!, maxValue!, numValues, axisType, formattingOptions));
    }

    addToBinnedAxis(axis: any, tickExtents: any, formattingOptions = {}) {
        axis.tickFormat(this.getForBinnedAxis(tickExtents, formattingOptions));
    }

    addToOrdinalAxis(axis: any, tickExtents: any) {
        axis.tickFormat((_: any, i: number) => {
            const value = tickExtents[i];
            return this.getForOrdinalAxis(value);
        });
    }

    addToPercentageAxis(axis: any, formattingOptions = {}) {
        const scale = (axis.scale() instanceof Function) ? axis.scale() : axis.scale;
        const minValue = (min<number>(scale.domain()) || 0) * 100;
        const maxValue = (max<number>(scale.domain()) || 0) * 100;
        const numValues = axis.tickValues() ? axis.tickValues().length : axis.ticks()[0];
        axis.tickFormat(this.get(minValue, maxValue, numValues, undefined, true, formattingOptions, true));
    }

    /**
     * This method belongs originally to number-formatting.filters.js and has been retrieved here
     * because we can't upgrade filters in Angular, so it is defined here and then downgraded to be used
     * as a filter in number-formatting.filters.js (longReadableNumber).
     */
    longReadableNumberFilter() {
        const getNumberOfDigitsBeforeDecimalPoint = (x: number) => {
            const absX = Math.abs(x);
            const logX = this.mathUtilsService.log10(absX);

            if (logX < 0) {
                return 0;
            } else {
                return 1 + (logX | 0); // | 0 is quick way to floor
            }
        }

        const computeNbDecimals = (x: number) => {

            const nbDigitsBeforeDecimalPoint = getNumberOfDigitsBeforeDecimalPoint(x);
            let nbDecimals = 9 - nbDigitsBeforeDecimalPoint; //Ideally we do not want numbers that exceed 9 digits in total

            nbDecimals = Math.max(2, nbDecimals); // Yet we want a minimum accuracy of 2 decimals (meaning that in some cases, we can go up to 11 digits)

            // Avoid getting remaining trailing zeros after rounding
            const roundedX = Math.round(x * Math.pow(10, nbDecimals)) / Math.pow(10, nbDecimals);

            /*
             * To find the last significant number in x, multiply x by decreasing powers of 10 and check if it's still an integer
             * The number of loops is minimised by starting the search with the max number of decimals that can be displayed
             */
            let i;

            for (i = nbDecimals - 1 ; i > 0 ; i--) {
                if (!isInteger(roundedX * Math.pow(10, i))) {
                    break;
                }
            }
            return i+1;
        }

        const digitFormatters: any[] = [];

        // All these keys of the locale need to be defined to avoid formatLocale to crash
        const modifiedLocale: FormatLocaleDefinition = {
            decimal: '.',
            thousands: '\xa0',
            grouping: [3],
            currency: ['', '']
        };

        for (let i = 0; i <= 9; i++) {
            digitFormatters.push(formatLocale(modifiedLocale).format(',.' + i + 'f')); //,.Xf uses a comma for a thousands separator and will keep X decimals
        }

        // We're dealing with a number here, but the method should be able to work with other things than a number.
        return (x: any) => {

            if (isNaN(x as number)) {
                if (typeof x === 'string') {
                    return x;
                } else if (typeof x.toString === 'function') {
                    return x.toString();
                } else {
                    return x;
                }
            }

            const abs_x = Math.abs(x as number);

            if (isInteger(abs_x)) {
                return formatLocale(modifiedLocale).format(',')(x as number);
            }

            if (x === 0) {
                return '0';
            }

            const nbDecimals = computeNbDecimals(x as number);

            return digitFormatters[nbDecimals](x);
        }
    }

    /**
     * This method belongs originally to number-formatting.filters.js and has been retrieved here
     * because we can't upgrade filters in Angular, so it is defined here and then downgraded to be used
     * as a filter in number-formatting.filters.js (longSmartNumber).
     */
    longSmartNumberFilter() {
        /*
         * Good looking numbers.
         * Contains thousands separator.
         */
        const digitFormatters: any[] = [];

        for (let i = 0; i < 6; i++) {
            digitFormatters.push(format(',.' + i + 'f'));
        }

        return (x: any, formattingOptions: any) => {
            if (typeof x != 'number') {
                return 'NA';
            }

            if (typeof formattingOptions === 'object' && typeof formattingOptions.customDecimalPlaces === 'number') {
                return format('.' + formattingOptions.customDecimalPlaces + 'f')(x);
            }

            const abs_x = Math.abs(x);

            if (this.mathUtilsService.isInteger(abs_x)) {
                return format(',')(x);
            }
            if (this.mathUtilsService.isInteger(abs_x * 100)) {
                return format(',.2f')(x);
            }
            if (x == 0) {
                return '0';
            }

            const heavyWeight = 1 - (this.mathUtilsService.log10(abs_x) | 0);

            const nbDecimals = Math.max(2, -heavyWeight + 2);

            if (nbDecimals < 6) {
                return digitFormatters[nbDecimals](x);
            } else {
                return x.toPrecision(4);
            }
        };
    }

    /**
     * This method belongs originally to number-formatting.filters.js and has been retrieved here
     * because we can't upgrade filters in Angular, so it is defined here and then downgraded to be used
     * as a filter in number-formatting.filters.js (smartNumber).
     */
    smartNumberFilter() {
        // short representation of number.
        const expFormatter = format('.2e');
        const siFormatter = (value: any, customDecimalPlaces: any) => {
            if (!isNaN(customDecimalPlaces)) {
            /*
             * d3.format() can append the unit prefix thanks to "s" specifier but when doing so, it also rounds the value.
             * In order to automatically get the unit prefix + apply custom places, we must go manual.
             */
                const d3UnitPrefix = this.getPrefix(value);
                switch (d3UnitPrefix.symbol) {
                    case UnitSymbol.K: value = value / 1000; break;
                    case UnitSymbol.M: value = value / 1000000; break;
                    case UnitSymbol.G: value = value / 1000000000; break;
                }
                value = value.toFixed(customDecimalPlaces);
                return value + d3UnitPrefix.symbol;
            } else {
                return format('.2s')(value);
            }
        };

        const digitFormatters: any[] = [];

        for (let i = 0; i < 6; i++) {
            digitFormatters.push(format('.' + i + 'f'));
        }

        const formatWithDecimalPlaces = (value: any, decimalPlaces: any, customDecimalPlaces: any) => {
            return typeof customDecimalPlaces === 'number'
                ? format('.' + customDecimalPlaces + 'f')(value)
                : digitFormatters[decimalPlaces](value);
        };

        return (d: any, formattingOptions: any) => {
            if (typeof d != 'number') {
                return 'NA';
            }
            const abs = Math.abs(d);
            let customDecimalPlaces;

            if (typeof formattingOptions === 'object' && typeof formattingOptions.customDecimalPlaces === 'number') {
                customDecimalPlaces = formattingOptions.customDecimalPlaces;
            }

            if (abs >= 1e12) {
                return expFormatter(d);
            } else if (abs >= 100000) {
                return siFormatter(d, customDecimalPlaces);
            } else if (abs >= 100) {
                return formatWithDecimalPlaces(d, 0, customDecimalPlaces);
            } else if (abs >= 1) {
                if (abs % 1 === 0) {
                    return formatWithDecimalPlaces(d, 0, customDecimalPlaces);
                }
                return formatWithDecimalPlaces(d, 2, customDecimalPlaces);
            } else if (abs === 0) {
                return formatWithDecimalPlaces(d, 0, customDecimalPlaces);
            } else if (abs < 0.00001) {
                return d.toPrecision(3);
            } else {
                if (!isNaN(customDecimalPlaces)) {
                    return format('.' + customDecimalPlaces + 'f')(d);
                } else {
                    const x = Math.min(5, 2 - (this.mathUtilsService.log10(abs) | 0));
                    return digitFormatters[x](d);
                }
            }
        };
    }

    /**
     * This method belongs originally to number-formatting.filters.js and has been retrieved here
     * because we can't upgrade filters in Angular, so it is defined here and then downgraded to be used
     * as a filter in number-formatting.filters.js (percentageNumber).
     */
    percentageNumberFilter() {
        return (value: number, formattingOptions: any) => {
            if (typeof formattingOptions === 'object' && typeof formattingOptions.customDecimalPlaces === 'number') {
                return format('.' + formattingOptions.customDecimalPlaces + '%')(value);
            } else {
                return format('.0%')(value);
            }
        };
    }
}
