import type {BinaryClassificationModelPerf} from './binary-classification-model-perf';
import type {MulticlassModelPerf} from './multiclass-model-perf';

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf
 */
export interface _DeepHubPredictionModelPerf {}

export type DeepHubPredictionModelPerf = DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf | DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf;

export namespace DeepHubPredictionModelPerf {
    export const type = ['DEEP_HUB_IMAGE_CLASSIFICATION', 'DEEP_HUB_IMAGE_OBJECT_DETECTION'] as const;
    export function isDeepHubImageClassificationPredictionModelPerf(toTest: DeepHubPredictionModelPerf): toTest is DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf { return toTest['type'] === 'DEEP_HUB_IMAGE_CLASSIFICATION'; }
    export function isDeepHubObjectDetectionPredictionModelPerf(toTest: DeepHubPredictionModelPerf): toTest is DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf { return toTest['type'] === 'DEEP_HUB_IMAGE_OBJECT_DETECTION'; }
}

export namespace DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PerIOUMetrics
     */
    export interface PerIOUMetrics {
        averagePrecision: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve
     */
    export interface PrecisionRecallCurve {
        confidenceOfBest: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PrecisionRecallCurve.BestConfidence;
        curve: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PrecisionRecallCurve.Curve;
    }
}

export namespace DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PrecisionRecallCurve {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve$Curve
     */
    export interface Curve {
        confidence: number[];
        f1: number[];
        precision: number[];
        recall: number[];
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve$BestConfidence
     */
    export interface BestConfidence {
        f1: number;
        precision: number;
        recall: number;
    }
}

export namespace DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf
     */
    export interface PerIOUPerf {
        global: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PerIOUMetrics;
        iou: number;
        optimalConfidenceScoreThreshold: number;
        perCategory: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PerIOUMetrics[];
        precisionRecallCurve: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PrecisionRecallCurve;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$ObjectDetectionMetrics
     */
    export interface ObjectDetectionMetrics {
        averagePrecisionAllIOU: number;
        averagePrecisionIOU50: number;
        averagePrecisionIOU75: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$ConfusionMatrix
     */
    export interface ConfusionMatrix {
        confusionMatrices: number[][][][];
        groundTruthsCounts: number[];
        perConfidenceScoreDetectionsCount: number[][];
    }
}

export namespace DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubImageClassificationPredictionModelPerf$ImageClassificationMetrics
     */
    export interface ImageClassificationMetrics {
        accuracy: number;
        f1: number;
        hammingLoss: number;
        logLoss: number;
        mcalibrationLoss: number;
        mrocAUC: number;
        precision: number;
        recall: number;
    }
}

export namespace DeepHubPredictionModelPerf {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubImageClassificationPredictionModelPerf
     */
    export interface DeepHubImageClassificationPredictionModelPerf extends _DeepHubPredictionModelPerf {
        confusion_matrix: MulticlassModelPerf.MulticlassConfusion;
        densityData: {[key: string]: BinaryClassificationModelPerf.PerClassPredictedProbabilityDensityData};
        metrics: DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf.ImageClassificationMetrics;
        oneVsAllCalibrationCurves: {[key: string]: BinaryClassificationModelPerf.CalibrationBin[]};
        oneVsAllCalibrationLoss: {[key: string]: number};
        oneVsAllRocAUC: {[key: string]: number};
        oneVsAllRocCurves: {[key: string]: BinaryClassificationModelPerf.RocVizBin[]};

        // PolyJson type
        type: 'DEEP_HUB_IMAGE_CLASSIFICATION';
    }

    export namespace DeepHubImageClassificationPredictionModelPerf {
        export const type = 'DEEP_HUB_IMAGE_CLASSIFICATION';
    }



    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf
     */
    export interface DeepHubObjectDetectionPredictionModelPerf extends _DeepHubPredictionModelPerf {
        IOUThreshold: number[];
        confidenceScoreThresholds: number[];
        confusion_matrix: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.ConfusionMatrix;
        metrics: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.ObjectDetectionMetrics;
        optimalConfidenceScoreThreshold: number;
        perCategoryMetrics: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.ObjectDetectionMetrics[];
        perIOU: DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf[];

        // PolyJson type
        type: 'DEEP_HUB_IMAGE_OBJECT_DETECTION';
    }

    export namespace DeepHubObjectDetectionPredictionModelPerf {
        export const type = 'DEEP_HUB_IMAGE_OBJECT_DETECTION';
    }


}