import { Inject, Injectable, NgZone } from "@angular/core";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { WaitingService } from "@core/overlays/waiting.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { FriendlyTimeDeltaShortPipe } from "@shared/pipes/date-pipes/friendly-time-delta-short.pipe";
import { fairAny, InfoMessage } from "dku-frontend-core";
import { BehaviorSubject, catchError, from, map, of, switchMap } from "rxjs";
import { ExposedObjectsService, FeatureGroupDetails } from "src/generated-sources";
import { FacetMap, TagsFile } from "./feature-store-models";

@UntilDestroy()
@Injectable()
export class FeatureStoreService implements ErrorContext {
  public tagsMap$: BehaviorSubject<Record<string, TagsFile>>;
  public error$: BehaviorSubject<APIError | undefined>;

  friendlyTimeDeltaShort: FriendlyTimeDeltaShortPipe;

  constructor(
    @Inject("$scope") private $scope: fairAny,
    @Inject("$rootScope") private $rootScope: fairAny,
    @Inject("$state") private $state: fairAny,
    @Inject("StateUtils") private stateUtils: fairAny,
    @Inject("ActivityIndicator") private activityIndicator: fairAny,
    @Inject("Navigator") private navigator: fairAny,
    @Inject("ExposedObjectsService") private exposedObjectsService: fairAny,
    @Inject("TaggingService") private taggingService: fairAny,
    private DataikuAPI: DataikuAPIService,
    private waitingService: WaitingService,
    private ngZoneService: NgZone
  ) {
    this.tagsMap$ = new BehaviorSubject<Record<string, TagsFile>>({});
    this.error$ = new BehaviorSubject<APIError | undefined>(undefined);
    this.friendlyTimeDeltaShort = new FriendlyTimeDeltaShortPipe();
  }

  useInCurrentProject(details: FeatureGroupDetails, projectKey: string) {
    return this.exposeToProject(
      details.datasetFullInfo.dataset.projectKey,
      "DATASET",
      details.datasetFullInfo.dataset.name,
      projectKey
    ).pipe(
      catchError(() => of()), // silently ignoring the error raised when we cancel the removal
      map((result: InfoMessage) => {
        if (result.severity === InfoMessage.Severity.ERROR) {
          this.ngZoneService.runOutsideAngular(() => {
            this.activityIndicator.info(result.title, 5000);
            this.$scope.$apply();
          });
        } else {
          this.stateUtils.go.flowLink(
            {
              nodeType: "DATASET",
              projectKey: details.datasetFullInfo.dataset.projectKey,
              name: details.datasetFullInfo.dataset.name,
            },
            projectKey
          );
        }
      })
    );
  }

  useInProject(details: FeatureGroupDetails) {
    return from(
      this.exposedObjectsService.exposeSingleObject(
        "DATASET",
        details.datasetFullInfo.dataset.name,
        details.datasetFullInfo.dataset.name,
        details.datasetFullInfo.dataset.projectKey
      )
    ).pipe(
      catchError(() => of()), // silently ignoring the error raised when we cancel the removal
      switchMap(() => this.getDetails(details.datasetFullInfo.dataset.projectKey, details.datasetFullInfo.dataset.name))
    );
  }

  requestSharing(details: FeatureGroupDetails, projectKey?: string) {
    return from(
      this.exposedObjectsService.requestSharing(
        "DATASET",
        details.datasetFullInfo.dataset.name,
        details.datasetFullInfo.dataset.name,
        details.datasetFullInfo.dataset.projectKey,
        projectKey
      )
    ).pipe(
      catchError(() => of()), // silently ignoring the error raised when we cancel the removal
      switchMap(() => this.getDetails(details.datasetFullInfo.dataset.projectKey, details.datasetFullInfo.dataset.name))
    )
  }

  disableUseInProject(datasetsInCurrentProject: string[], details: FeatureGroupDetails, projectKey?: string) {
    return (
      (!details.datasetFullInfo.objectAuthorizations.isQuicklyShareable &&
        !details.datasetFullInfo.objectAuthorizations.canManageExposedElements &&
        !details.datasetFullInfo.objectAuthorizations.isObjectSharingRequestEnabled) ||
      this.featureGroupUsedInCurrentProject(datasetsInCurrentProject, details, projectKey)
    );
  }

  featureGroupUsedInCurrentProject(
    datasetsInCurrentProject: string[],
    details: FeatureGroupDetails,
    projectKey?: string
  ) {
    return (
      !!projectKey &&
      datasetsInCurrentProject.includes(
        details.datasetFullInfo.dataset.projectKey + "." + details.datasetFullInfo.dataset.name
      )
    );
  }

  removeFromFeatureStoreLabel() {
    return `Remove from Feature Store${
      !this.mayManageFeatureStore() ? " (you do not have the permission to perform this action)" : ""
    }`;
  }

  mayManageFeatureStore() {
    return this.$rootScope.appConfig.globalPermissions.mayManageFeatureStore;
  }

  showNavigator(projectKey: string, id: string) {
    this.navigator.show(projectKey, "DATASET", id);
  }

  disableNavigator(details: FeatureGroupDetails) {
    return !this.hasReadAccessOnTargetProject(details.datasetFullInfo.objectAuthorizations);
  }

  hasReadAccessOnTargetProject(objectAuthorizations: ExposedObjectsService.ObjectAuthorizations) {
    return objectAuthorizations.directAccessOnOriginal;
  }

  flowLink(projectKey: string, name: string) {
    return this.stateUtils.href.flowLink({
      nodeType: "DATASET",
      projectKey: projectKey,
      name: name,
    });
  }

  datasetLink(details: FeatureGroupDetails) {
    if(this.hasReadAccessOnTargetProject(details.datasetFullInfo.objectAuthorizations))
      return this.stateUtils.href.dssObject(
        "DATASET",
        details.datasetFullInfo.dataset.name,
        details.datasetFullInfo.dataset.projectKey
      );
    else if(details.usages.projectsWithAccess.length != 0) {
      const seeInProject = details.usages.projectsWithAccess[0].projectKey;
      return this.stateUtils.href.dssObject(
        "DATASET",
        details.datasetFullInfo.dataset.projectKey + "." + details.datasetFullInfo.dataset.name,
        seeInProject
      );
    }
  }

  linkToProject(projectKey: string) {
    return this.stateUtils.href.project(projectKey);
  }

  viewInProject(projectKey: string, datasetName: string) {
    this.$state.go("projects.project.flow", { projectKey: projectKey, id: datasetName });
  }

  disableViewInProject(details: FeatureGroupDetails) {
    return (
      !this.hasReadAccessOnTargetProject(details.datasetFullInfo.objectAuthorizations) &&
      !details.projectAccessRequestEnabled
    );
  }

  lastBuildInfo(details: FeatureGroupDetails) {
    if (details.datasetFullInfo.dataset.type === "UploadedFiles") {
      return "Uploaded Dataset";
    }
    // details.datasetFullInfo.lastBuild can be undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (details.datasetFullInfo.lastBuild && !details.datasetFullInfo.currentBuildState) {
      const buildTime = this.friendlyTimeDeltaShort.transform(details.datasetFullInfo.lastBuild.buildEndTime);
      const buildStatus = details.datasetFullInfo.lastBuild.buildSuccess
        ? '<span class="text-success">Success</span>'
        : '<span class="text-error">Failed</span>';
      return `${buildTime} (${buildStatus})`;
    }
    return "N/A";
  }

  pushTagsMap(tagsMap: Record<string, TagsFile>) {
    this.tagsMap$.next(tagsMap);
  }

  tagBackgroundColor(projectKey: string, tag: string) {
    // this.tagsMap$.getValue()[projectKey] can be undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return this.tagsMap$.getValue()[projectKey]?.tags[tag]?.color || this.taggingService.getTagColor(tag);
  }

  // Error management

  pushError(error?: APIError) {
    this.error$.next(error);
  }

  resetError() {
    this.pushError();
  }

  // API calls

  searchFeatureGroups(query: string, facets: FacetMap) {
    return this.DataikuAPI.featureStore.searchFeatureGroups(query, facets).pipe(catchAPIError(this));
  }

  searchFeatures(query: string, facets: FacetMap) {
    return this.DataikuAPI.featureStore.searchFeatures(query, facets).pipe(catchAPIError(this));
  }

  listUsers() {
    return this.DataikuAPI.security.listUsers().pipe(catchAPIError(this));
  }

  listProjects() {
    return this.DataikuAPI.projects.list().pipe(catchAPIError(this));
  }

  listTags() {
    return this.DataikuAPI.taggableObjects.listAllTags(true).pipe(catchAPIError(this));
  }

  getDetails(projectKey: string, datasetName: string) {
    return this.DataikuAPI.featureStore.getDetails(projectKey, datasetName).pipe(catchAPIError(this));
  }

  removeFromFeatureStore(featureGroup: FeatureGroupDetails) {
    return this.DataikuAPI.featureStore
      .setFeatureGroup(
        featureGroup.datasetFullInfo.dataset.projectKey,
        featureGroup.datasetFullInfo.dataset.name,
        false
      )
      .pipe(catchAPIError(this));
  }

  flush() {
    if(this.$rootScope.appConfig.disableImmediateFlush) {
      this.activityIndicator.warning("Your removal request is being processed. It may take a few seconds to be reflected here.", 10000);
      return of();
    }
    else return this.DataikuAPI.catalog.flush().pipe(
      this.waitingService.bindSpinner(),
      catchAPIError(this)
    );
  }

  exposeToProject(sourceProjectKey: string, type: string, objectId: string, targetProjectKey: string) {
    return this.DataikuAPI.projects
      .addExposedObject(sourceProjectKey, type, objectId, targetProjectKey)
      .pipe(catchAPIError(this));
  }

  listAccessibleDatasetsInProject(projectKey: string) {
    return this.DataikuAPI.taggableObjects
      .listAccessibleObjects(projectKey, "DATASET", "READ")
      .pipe(catchAPIError(this));
  }
}
