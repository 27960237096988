import { Aggregation } from '@model-main/pivot/backend/model/aggregation';
import { Injectable } from '@angular/core';
import { ChartTensorDataWrapper } from '../../models';
import { FrontendMeasureDef, ChartCoordinates } from '../../interfaces';
import { EChartMatrixPoint } from './interfaces';
import { EChartPrepareDataContext, PrepareData } from './models';

@Injectable({
    providedIn: 'root'
})
export class PrepareDataService extends PrepareData {
    protected isMeasureComputedInPercentage(measure: FrontendMeasureDef): boolean {
        return measure.computeMode === Aggregation.ComputeMode.PERCENTAGE || measure.computeMode === Aggregation.ComputeMode.CUMULATIVE_PERCENTAGE;
    }

    prepareData(context: EChartPrepareDataContext<ChartTensorDataWrapper>): Array<EChartMatrixPoint> {
        const frames = (context.chartData.getAxisLabels('animation') || [null]).map((_: any, index: number) => index);
        const facets = (context.chartData.getAxisLabels('facet') || [null]).map((_: any, index: number) => index);
        const axisLabels = context.axis ? (context.chartData.getAxisLabels(context.axis) || [null]) : [null];
        const colorLabels = context.chartData.getAxisLabels('color') || [null];

        const getPrepareDataReducer = (frameIndex: number, facetIndex: number, colorIndex: number, axisIndex: number, color: any, axisValues: Array<any>) => {
            return (measureAcc: any, measure:FrontendMeasureDef, measureIndex: number) => {
                const coord: ChartCoordinates = {
                    animation: frameIndex,
                    facet: facetIndex,
                    color: colorIndex,
                    measure: measureIndex
                };

                if (context.axis) {
                    coord[context.axis] = axisIndex;
                }

                let value = context.chartData.aggr(measureIndex).get(coord);

                if (context.chartDef.axis1LogScale) {
                    //  If value is null in log scale, we ignore it (echarts bug: https://github.com/apache/echarts/issues/9801)
                    if (value === null) {
                        return measureAcc;
                    }

                    //  If log scale, we need to transform 0 values to 1
                    if (value === 0) {
                        value = 1;
                    }

                    //  Percentage are number between 0 and 1, but a log scale starts at 1
                    if (this.isMeasureComputedInPercentage(measure)) {
                        value = value + 1;
                    }
                }

                const point = {
                    colorLabel: color && color.label,
                    value: context.mapper ? context.mapper(value) : value,
                    axisValues,
                    coord
                };

                measureAcc.push(point);

                return measureAcc;
            };
        };

        const data = frames.reduce((frameAcc: any, frameIndex: number) => {
            return facets.reduce((facetAcc: any, facetIndex: number) => {
                return axisLabels.reduce((axisAcc: any, axisValues: any, axisIndex: number) => {
                    return colorLabels.reduce((colorAcc: any, color: any, colorIndex: number) => {
                        return context.chartDef.genericMeasures.reduce(
                            getPrepareDataReducer(frameIndex, facetIndex, colorIndex, axisIndex, color, axisValues),
                            colorAcc
                        );
                    }, axisAcc);
                }, facetAcc);
            }, frameAcc);
        }, []);

        return data;
    }
}
