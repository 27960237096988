import { Inject, Injectable } from '@angular/core';
import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { EChartsOption, TitleComponentOption, PieSeriesOption } from 'echarts';
import { isNumber } from 'lodash';
import { ChartAxisTypes, ChartLabels, ChartSortTypes } from '../../../enums';
import { ChartCoordinates, FrontendChartDef } from '../../../interfaces';
import { ChartIAE, ChartTensorDataWrapper } from '../../../models';
import { ChartDimensionService, ChartFormattingService } from '../../../services';
import { EChartSeriesContext, EChartOptionsContext, EChartMatrixPoint, EChartPrepareDataContext, NonCartesian2DEChartDef, EChartLegendContext, EChartDrawContext, PrepareDataService } from '../../echarts';

@Injectable({
    providedIn: 'root'
})
export class PieEChartDefService extends NonCartesian2DEChartDef {
    onLegendHover = (legendContext: EChartLegendContext): void => {
        const actionType = legendContext.mouseEnter ? 'highlight' : 'downplay';
        legendContext.echartInstance.dispatchAction({
            type: actionType,
            name: legendContext.item.label
        });
    };

    constructor(
        @Inject('StringUtils') protected stringUtils: any,
        @Inject('ChartStoreFactory') chartStoreFactory: any,
        @Inject('CHART_FORMATTING_OPTIONS') chartFormattingOptions: any,
        @Inject('ChartLabels') private chartLabelsService: any,
        private chartDimensionService: ChartDimensionService,
        chartFormattingService: ChartFormattingService,
        prepareDataService: PrepareDataService
    ) {
        super(stringUtils, chartStoreFactory, chartFormattingOptions, chartFormattingService, prepareDataService);
    }

    public getColorSpec(chartDef: FrontendChartDef) {
        return { type: ChartAxisTypes.DIMENSION, name: 'color', dimension: chartDef.genericDimension0[0] };
    }

    protected wrapData(data: PivotTableTensorResponse, axesDef?: Record<string, number>): ChartTensorDataWrapper {
        return new ChartTensorDataWrapper(data, axesDef);
    }

    protected mapValue(value: number): number | null {
        if (value < 0) {
            throw new ChartIAE('Cannot represent negative values on a pie chart. Please use another chart type.');
        }

        return value > 0 ? value : null;
    }

    protected getSeriesId(coord: ChartCoordinates): string {
        const frameIndex = coord.animation;
        const facetIndex = coord.facet;
        const measureIndex = coord.measure;
        const seriesId = `animation${frameIndex}-facet${facetIndex}-measure${measureIndex}`;
        return seriesId;
    }

    protected getSeries({
        chartDef,
        chartData,
        chartWidth,
        chartHeight,
        colorScale,
        chartPoints,
        frameIndex
    }: EChartSeriesContext): Array<PieSeriesOption> {
        if (chartDef && chartData && chartWidth && chartHeight && colorScale) {
            return this.buildSeries(
                chartDef,
                chartData,
                colorScale,
                chartPoints as Array<EChartMatrixPoint>,
                frameIndex
            );
        } else {
            throw new Error('Missing properties to build series for pie echart');
        }
    }

    private buildOneSeries(
        seriesId: string,
        chartDef: FrontendChartDef
    ) {
        const seriesItem: PieSeriesOption = {
            id: seriesId,
            type: 'pie',
            emphasis: {
                labelLine: {
                    show: chartDef.showInChartValues || chartDef.showInChartLabels
                },
                itemStyle: {
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            },
            animationDuration: 300,
            labelLine: {
                show: chartDef.showInChartValues || chartDef.showInChartLabels,
                lineStyle: {
                    opacity: chartDef.colorOptions.transparency
                }
            },
            data: []
        };

        if (chartDef.variant === 'donut') {
            /*
             * When putting 100% as outer radius, the labels do not have room to be displayed thus are overlapping with the donut.
             * So instead we go up to 80 but no more so that the labels can be displayed. Hole size should be adapted consequently.
             */
            seriesItem.radius = [chartDef.pieOptions.donutHoleSize * 0.8 + '%', '80%'];
        }

        return seriesItem;
    }

    protected buildSeries(
        chartDef: FrontendChartDef,
        chartData: ChartTensorDataWrapper,
        colorScale: ((index: number) => string) | undefined,
        chartPoints: Array<EChartMatrixPoint>,
        frameIndex: number
    ): Array<PieSeriesOption> {
        let seriesMap: Record<string, PieSeriesOption> = {};

        const numberFormattingOptions = this.chartDimensionService.getNumberFormattingOptions(chartDef.genericDimension0[0]);

        let colorLabelsMap: Record<string, any> = {};
        colorLabelsMap = (chartData.getAxisLabels('color') || []).reduce((acc, axisLabel) => {
            acc[axisLabel.label] = axisLabel;
            return acc;
        }, colorLabelsMap);

        //  Retrieves min/max/numValues for color dimension to fit current implementation in tooltips
        const colorDimensionMin = chartData.getMinValue('color');
        const colorDimensionMax = chartData.getMaxValue('color');
        const colorDimensionNumValues = chartData.getNumValues('color');

        // chartDef attributes can be undefined, resulting in passing undefined to ECharts's 'show' option and displaying the labels by mistake.
        // So we need to ensure that the 'show' option is a boolean:
        const shouldDisplayLabel = !!(chartDef.showInChartLabels || chartDef.showInChartValues);

        seriesMap = chartPoints.reduce((acc, point) => {
            if (frameIndex === point.coord.animation) {
                const measureIndex = point.coord.measure;
                const colorIndex = isNumber(point.coord.color) ? point.coord.color : 0;

                const measure = chartDef.genericMeasures[measureIndex];
                const color = colorScale && colorScale(colorIndex + measureIndex);
                const formatValue = this.chartFormattingService.createNumberFormatter(measure, chartData.getAggrExtent(measureIndex), 1000);

                const seriesId = this.getSeriesId(point.coord);

                if (!acc[seriesId]) {
                    acc[seriesId] = this.buildOneSeries(
                        seriesId,
                        chartDef
                    ) ;
                }

                const dataItem = {
                    value: point.value,
                    name: point.colorLabel,
                    itemStyle: {
                        color: color,
                        opacity: chartDef.colorOptions.transparency
                    },
                    label: {
                        fontFamily: this.chartFormattingOptions.FONT_FAMILY,
                        fontSize: this.chartFormattingOptions.LABELS_FONT_SIZE,
                        color: this.chartFormattingOptions.COLOR,
                        show: shouldDisplayLabel,
                        opacity: 1
                    },
                    labelLine: {
                        show: shouldDisplayLabel
                    },
                    emphasis: {
                        label: {
                            show: shouldDisplayLabel
                        },
                        labelLine: {
                            show: shouldDisplayLabel
                        }
                    }
                };

                if (shouldDisplayLabel) {
                    (dataItem.label as any).formatter = (params: any) => {
                        let displayedText = '';
                        if (chartDef.showInChartLabels) {
                            const axisLabel = colorLabelsMap[params.name];
                            /*
                             *  This formatting is set to be the same used for tooltips but is not relevant
                             *  @TODO : https://app.shortcut.com/dataiku/story/110162/enhance-formatting-for-dimensions-and-measures-in-auto-mode
                             */
                            displayedText = this.chartLabelsService.getFormattedLabel(axisLabel, numberFormattingOptions, colorDimensionMin, colorDimensionMax, colorDimensionNumValues);

                            if (chartDef.showInChartValues) {
                                displayedText += ' - ' + formatValue(params.value);
                            }
                        } else {
                            if (chartDef.showInChartValues) {
                                displayedText = formatValue(params.value);
                            }
                        }
                        return displayedText.replace(new RegExp(`\\b${ChartLabels.NO_VALUE}\\b`, 'gi'), 'No value');
                    };
                }

                if (Array.isArray(acc[seriesId].data)) {
                    acc[seriesId].data?.push(dataItem);
                }
            }

            return acc;
        }, seriesMap);

        return Object.values(seriesMap);
    }

    protected getTitle(chartPoints: Array<EChartMatrixPoint>): TitleComponentOption | undefined {
        if (chartPoints?.length === 0) {
            return {
                subtextStyle: {
                    fontSize: this.chartFormattingOptions.LABELS_FONT_SIZE,
                    fontFamily: this.chartFormattingOptions.FONT_FAMILY,
                    color: this.chartFormattingOptions.COLOR
                },
                subtext: '(No data)'
            };
        }

        return;
    }

    protected getOptions({ series, gridOptions, title }: EChartOptionsContext): EChartsOption {
        const options: EChartsOption = {
            grid: gridOptions,
            series
        };

        if (title) {
            options.title = title;
        }

        return options;
    }


    /**
     * Sort facets data with the following priority:
     * animation / facet / measureId / value (according to the desired sorting)
     * @returns data array with the desired sorting
     */
    private sortFacetsBySortingDimension(data: Array<EChartMatrixPoint>, chartDef: FrontendChartDef) {
        const sortingDimension = chartDef.genericDimension0[0];
        if (!sortingDimension) {
            return data;
        }

        // get dimension sorting if the option is enabled and ordering is not natural (default DESC)
        const sort = !this.chartDimensionService.isTrueNumerical(sortingDimension) && sortingDimension.sort.type !== ChartSortTypes.NATURAL && sortingDimension.sort.sortAscending ? -1 : 1;
        const measureIdx = sortingDimension.sort.measureIdx;

        return data.sort((a: any, b: any) => {
            const facet = a.coord.facet - b.coord.facet;
            const animation = a.coord.animation - b.coord.animation;
            const value = (a.value > b.value) ? -sort : sort;
            let measure = 0;
            if (a.coord.measure !== measureIdx && b.coord.measure === measureIdx) {
                measure = 1;
            } else if (a.coord.measure === measureIdx && b.coord.measure !== measureIdx) {
                measure = -1;
            }

            return animation || facet || measure || value;
        });
    }

    draw(drawContext: EChartDrawContext<ChartTensorDataWrapper>): { options: EChartsOption, allCoords: Array<Array<ChartCoordinates>> } {
        const gridOptions = drawContext.chartBase.margins;

        const prepareDataContext: EChartPrepareDataContext<ChartTensorDataWrapper> = {
            chartDef: drawContext.chartDef,
            chartData: drawContext.chartData,
            legends: drawContext.legends,
            colorScale: drawContext.chartBase.colorScale,
            mapper: value => this.mapValue(value)
        };

        let chartPoints = this.prepareData(prepareDataContext);
        // pie override the sort to sort by facets
        chartPoints = this.sortFacetsBySortingDimension(chartPoints, drawContext.chartDef);

        const chartWidth = drawContext.chartBase.width - (gridOptions.left || 0) - (gridOptions.right || 0);
        const chartHeight = drawContext.chartBase.height - (gridOptions.top || 0) - (gridOptions.bottom || 0);

        const seriesContext: EChartSeriesContext = {
            chartDef: drawContext.chartDef,
            chartData: drawContext.chartData,
            chartWidth,
            chartHeight,
            colorScale: drawContext.chartBase.colorScale,
            chartPoints,
            frameIndex: drawContext.frameIndex || 0
        };

        const series = this.getSeries(seriesContext);

        const optionsContext: EChartOptionsContext = {
            series,
            gridOptions
        };

        const options = this.getOptions(optionsContext);

        const allCoords = this.getAllCoords(chartPoints);

        return {
            options,
            allCoords
        };
    }
}
