import { Pipe, PipeTransform } from "@angular/core";
import { ManagedFolder } from "@shared/utils/managed-folder";
import { AnyLoc } from "src/generated-sources";
import { UIBoundingBox } from "./models/annotation";
import { LabelingTaskInfo } from "./services/labeling.service";

export function isTaskSetProperly(task: LabelingTaskInfo): boolean {
    return task.idColumn !== undefined;
}

export function getImageURL(contextProjectKey: string, labelingTaskId: string, managedFolderLoc: AnyLoc, imagePath: string): string{
    return `/dip/api/labelingtasks/preview-image?labelingTaskId=${labelingTaskId}&contextProjectKey=${encodeURIComponent(contextProjectKey)}&projectKey=${encodeURIComponent(managedFolderLoc.projectKey)}&odbId=${encodeURIComponent(managedFolderLoc.id)}&itemPath=${encodeURIComponent(imagePath)}&contentType=${encodeURIComponent(ManagedFolder.getSupportedContentType(imagePath))}`;
}

export function getAverageRectangleFromAnnotations(annotations: UIBoundingBox[]): { width: number, height: number, top: number, left: number } {
    const total = annotations.length;

    return {
        width: computeRoundedAverage(annotations.map(bbox => bbox.width)),
        height: computeRoundedAverage(annotations.map(bbox => bbox.height)),
        top: computeRoundedAverage(annotations.map(bbox => bbox.top)),
        left: computeRoundedAverage(annotations.map(bbox => bbox.left))
    };

    // round values to facilitate conversion between canvas and bbox
    function computeRoundedAverage(items: number[]) {
        return Math.round(items.reduce((a, b) => a + b, 0) / total);
    }
}

@Pipe({ name: 'formattedAnnotatorName'})
export class FormattedAnnotatorNamePipe implements PipeTransform  {
    transform(annotatorId: string, annotators: {[login: string]: any}) {
        if (annotators[annotatorId]) {
            return annotators[annotatorId].displayName;
        } else {
            return annotatorId + " (Unknown)";
        }
    }
}
