import { Inject, Injectable } from '@angular/core';
import { ChartVariant } from '@model-main/pivot/frontend/model/chart-variant';
import { BarSeriesOption, EChartsOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { cloneDeep } from 'lodash';
import { ChartAxisTypes, ChartModes } from '../../../../enums';
import { FrontendChartDef } from '../../../../interfaces';
import { ChartIAE } from '../../../../models';
import { ChartDimensionService, ChartFormattingService } from '../../../../services';
import { EChartsAxesService, EChartAxisTypes, EChartMatrixPoint, EChartSeriesContext, EChartLabelPosition, EChartOptionsContext, EChartAxis, PrepareDataService } from '../../../echarts';
import { BarsEChartDef } from '../bars-echart-def.model';

@Injectable({
    providedIn: 'root'
})
export class HorizontalBarsEChartDefService extends BarsEChartDef {
    mainAxis = EChartAxis.Y;

    constructor(
        @Inject('StringUtils') stringUtils: any,
        @Inject('ChartStoreFactory') chartStoreFactory: any,
        @Inject('CHART_FORMATTING_OPTIONS') chartFormattingOptions: any,
        @Inject('ChartLabels') chartLabelsService: any,
        private chartDimensionService: ChartDimensionService,
        chartFormattingService: ChartFormattingService,
        prepareDataService: PrepareDataService,
        echartsAxesService: EChartsAxesService
    ) {
        super(stringUtils, chartStoreFactory, chartFormattingOptions, chartLabelsService, chartFormattingService, prepareDataService, echartsAxesService);
    }

    /*
     *  Force category on yAxis when two values are set to fix an issue with echarts (it switches to vertical bar chart when both axes are numerical)
     *  https://app.shortcut.com/dataiku/story/81991/when-using-two-numeric-values-for-xaxis-and-yaxis-the-stacked-bar-chart-displays-bars-vertically-instead-of-displaying-them
     */
    private forceCategoryAxis(xAxes: Array<XAXisComponentOption>, yAxes: Array<YAXisComponentOption>, series: Array<BarSeriesOption>): { yAxes: Array<YAXisComponentOption>, series: Array<BarSeriesOption> } {
        if ((xAxes[0].type === EChartAxisTypes.VALUE || xAxes[0].type === EChartAxisTypes.LOG) && (yAxes[0].type === EChartAxisTypes.VALUE || yAxes[0].type === EChartAxisTypes.LOG)) {
            let serieChanges: { axisValues: Array<any>, updatedSeries: Array<BarSeriesOption> } = {
                axisValues: [],
                updatedSeries: []
            };

            serieChanges = series.reduce((serieChanges, serie) => {
                let dataChanges: { axisValues: Array<any>, data: Array<any> } = {
                    axisValues: [],
                    data: []
                };

                dataChanges = (serie.data || []).reduce((dataChanges, item) => {
                    const itemYValue = (item as Array<string>)[1];

                    if (itemYValue >= (yAxes[0].min || -Infinity) && itemYValue <= (yAxes[0].max || Infinity)) { // Avoid adding data out of the required range
                        const newValue = `${(item as Array<string>)[1]}`;
                        dataChanges.axisValues.push(newValue);

                        (item as Array<string>)[1] = newValue;
                    } else {
                        (item as Array<string | null>)[1] = null; // Keep item as null to preserve correct indexes and ensure correct tooltips
                    }
                    dataChanges.data.push(item);

                    return dataChanges;
                }, dataChanges);

                //  Always getting the highest number of ticks (should not change though)
                if (serieChanges.axisValues.length < dataChanges.axisValues.length) {
                    serieChanges.axisValues = dataChanges.axisValues;
                }

                serie.data = dataChanges.data;
                serieChanges.updatedSeries.push(serie);

                return serieChanges;
            }, serieChanges);

            series = serieChanges.updatedSeries;

            //  Used to display a linear axis even though the data is adapted for a discrete scale (category axis)
            const displayedYAxes = yAxes.map(yAxis => {
                const updatedYAxis: YAXisComponentOption = cloneDeep(yAxis);
                updatedYAxis.position = updatedYAxis.position || 'left';
                return updatedYAxis;
            });

            const hiddenYAxes = yAxes.map(yAxis => {
                const updatedYAxis: YAXisComponentOption = cloneDeep(yAxis);
                updatedYAxis.type = 'category';
                updatedYAxis.show = false;
                delete updatedYAxis.min;
                delete updatedYAxis.max;
                if ((updatedYAxis as any).interval) {
                    delete (updatedYAxis as any).interval;
                }
                (updatedYAxis as any).data = serieChanges.axisValues;
                return updatedYAxis;
            });

            yAxes = [...hiddenYAxes, ...displayedYAxes];
        }

        return { yAxes, series };
    }

    protected mapValue(value: number): number | null {
        if (value < 0) {
            throw new ChartIAE('Cannot represent negative values on a Stacked chart. Please use another chart.');
        }

        return value > 0 ? value : null;
    }

    protected getAxesSpecs(chartDef: FrontendChartDef, matrixPoints: Array<EChartMatrixPoint>) {
        const isPercentScale = this.chartDimensionService.isPercentScale(chartDef.genericMeasures) || chartDef.variant === ChartVariant.stacked_100;
        const stacks: Record<string, Record<number, number>> = this.stackAxisValues(EChartAxis.Y, matrixPoints);
        const defaultXMax = Object.values(stacks).reduce((acc, stack) => {
            return Math.max(acc, Math.max(...Object.values(stack)));
        }, -Infinity);
        // Hack: by design bar charts start at 0, but if log scale is checked start at 1 (to make it possible)
        let xDomain = [chartDef.axis1LogScale ? 1 : 0, defaultXMax];

        if (chartDef.variant === ChartVariant.stacked_100) {
            xDomain = [0, 1];
        }

        return {
            xSpec: {
                type: ChartAxisTypes.MEASURE,
                domain: xDomain,
                isPercentScale: isPercentScale,
                measure: chartDef.genericMeasures,
                customExtent: chartDef.xCustomExtent
            },
            ySpec: {
                type: ChartAxisTypes.DIMENSION,
                name: 'y',
                mode: ChartModes.COLUMNS,
                dimension: chartDef.genericDimension0[0],
                ascendingDown: true,
                customExtent: chartDef.yCustomExtent
            },
            y2Spec: null
        };
    }

    protected getSeries({
        chartDef,
        chartData,
        chartWidth,
        chartHeight,
        colorScale,
        chartPoints,
        yAxes,
        frameIndex
    }: EChartSeriesContext): Array<BarSeriesOption> {
        if (chartDef && chartData && chartWidth && chartHeight && colorScale && yAxes) {
            return this.buildSeries({
                chartDef,
                chartData,
                chartWidth,
                chartHeight,
                colorScale,
                matrixPoints: chartPoints as Array<EChartMatrixPoint>,
                mainAxis: EChartAxis.Y,
                mainAxisType: yAxes[0].type,
                stacked: true,
                labelPosition: EChartLabelPosition.INSIDE,
                frameIndex
            });
        } else {
            throw new Error('Missing properties to build series for horizontal bars echart');
        }
    }

    protected getOptions({ xAxes, yAxes, series, gridOptions }: EChartOptionsContext): EChartsOption {
        if (xAxes && yAxes) {
            const changes = this.forceCategoryAxis(xAxes, yAxes, series);

            const options: EChartsOption = {
                grid: gridOptions,
                xAxis: xAxes,
                yAxis: changes.yAxes,
                series: changes.series
            };

            return options;
        } else {
            throw new Error('Missing properties to build options for horizontal bars echart');
        }
    }
}
