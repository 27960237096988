import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Feature } from "../feature-store-models";
import { formatItemName } from "../highlight-utils";
import { Highlight, Hit } from "../query-result-models";

@Component({
  selector: "feature-header",
  templateUrl: "./feature-header.component.html",
  styleUrls: ["./feature-header.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureHeaderComponent {
  @Input() selectedFeature!: Hit<Feature>;
  @Input() featureHighlight!: Highlight;

  constructor() {}

  formatFeatureName() {
    return formatItemName(this.featureHighlight, this.selectedFeature._source.name || "");
  }
}
