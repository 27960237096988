import { directive } from '@migration/downgraded-components';
import { service, constant } from '@migration/downgraded-providers';
import { WorkspaceHomeComponent, WorkspaceObjectPageComponent, WorkspacePageComponent } from './features';
import {
    WorkspaceDisplayService,
    WorkspaceIconComponent,
    WorkspaceModalService,
    WorkspaceRightPanelComponent,
    WorkspaceSecurityService
} from './shared';
import { routes } from './workspaces-routes.constant';
import { WorkspacesComponent } from './workspaces.component';

export function downgradeWorkspaces() {
    //  Constants
    constant('WorkspacesRoutes', routes);

    //  Services
    service('WorkspaceDisplayService', WorkspaceDisplayService);
    service('WorkspaceModalService', WorkspaceModalService);
    service('WorkspaceSecurityService', WorkspaceSecurityService);

    //  Directives
    directive('ng2WorkspaceIcon', WorkspaceIconComponent);
    directive('ng2WorkspacePage', WorkspacePageComponent);
    directive('ng2WorkspacesIndexPage', WorkspacesComponent);
    directive('ng2WorkspacesHomePage', WorkspaceHomeComponent);
    directive('ng2WorkspaceRightPanel', WorkspaceRightPanelComponent);
    directive('ng2WorkspaceObjectPage', WorkspaceObjectPageComponent);
}
