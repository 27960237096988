<ng-container *ngIf="!!object">
    <div [ngSwitch]="view"
         class="workspace-object-thumbnail">

        <img *ngSwitchCase="View.Image"
             class="workspace-object-thumbnail__image"
             [src]="safeImage" />

        <div *ngSwitchCase="View.ImageDescription" class="workspace-object-thumbnail__container">
            <img class="workspace-object-thumbnail__image" [src]="safeImage" />
            <markdown 
                class="workspace-object-thumbnail__description markdown-in-tile"
                [from]="object.shortDesc || object.description">
            </markdown>
        </div>

        <div *ngSwitchCase="View.IconDescription" class="workspace-object-thumbnail__container">
            <i
                class="workspace-object-thumbnail__icon"
                [ngClass]="[type | typeToIcon, type | typeToColor]">
            </i>
            <markdown 
                class="workspace-object-thumbnail__description markdown-in-tile"
                [from]="object.shortDesc || object.description">
            </markdown>
        </div>

        <div *ngSwitchCase="View.FaviconDescription" class="workspace-object-thumbnail__container">
            <workspace-object-link-favicon 
                [object]="object">
            </workspace-object-link-favicon>
            <markdown 
                class="workspace-object-thumbnail__description markdown-in-tile"
                [from]="object.shortDesc">
            </markdown>
        </div>

        <workspace-object-link-favicon *ngSwitchCase="View.Favicon"
                                       [object]="object">
        </workspace-object-link-favicon>

        <i *ngSwitchCase="View.DatasetIcon"
           class="workspace-object-thumbnail__icon universe-color dataset"
           [ngClass]="(object.subType || type) | datasetTypeToIcon">
        </i>

        <i *ngSwitchDefault
           class="workspace-object-thumbnail__icon"
           [ngClass]="[type | typeToIcon, type | typeToColor]">
        </i>
    </div>
</ng-container>