import { PivotTableTensorResponse } from '@model-main/pivot/backend/model/pivot-table-tensor-response';
import { ChartVariant } from '@model-main/pivot/frontend/model/chart-variant';
import { DisplayAxis } from '@model-main/pivot/frontend/model/display-axis';
import { BarSeriesOption } from 'echarts';
import { isNumber } from 'lodash';
import { FrontendChartDef } from '../../../interfaces';
import { ChartTensorDataWrapper } from '../../../models';
import { ChartFormattingService } from '../../../services';
import { EChartsAxesService, EChartMatrixPoint, Cartesian2DEChartDef, EChartAxis, EChartLabelPosition, PrepareDataService } from '../../echarts';
import { BarsSeriesContext } from './bars-series-context.model';

export abstract class BarsEChartDef extends Cartesian2DEChartDef {
    constructor(
        protected stringUtils: any,
        protected chartStoreFactory: any,
        protected chartFormattingOptions: any,
        protected chartLabelsService: any,
        protected chartFormattingService: ChartFormattingService,
        protected prepareDataService: PrepareDataService,
        protected echartsAxesService: EChartsAxesService
    ) {
        super(stringUtils, chartStoreFactory, chartFormattingOptions, chartLabelsService, chartFormattingService, prepareDataService, echartsAxesService);
    }

    public getColorSpec(chartDef: FrontendChartDef) {
        return {
            type: 'DIMENSION',
            name: 'color',
            dimension: chartDef.genericDimension1[0]
        };
    }

    private getStackId(frameIndex: number, facetIndex: number): string {
        return `animation${frameIndex}-facet${facetIndex}-total`;
    }

    protected wrapData(data: PivotTableTensorResponse, axesDef?: Record<string, number>): ChartTensorDataWrapper {
        return new ChartTensorDataWrapper(data, axesDef);
    }

    protected stackAxisValues(axis: EChartAxis, matrixPoints: Array<EChartMatrixPoint>): Record<string, Record<number, number>> {
        let stacks: Record<string, Record<number, number>> = {};

        stacks = matrixPoints.reduce((acc, point) => {
            const stackId = this.getStackId(point.coord.animation, point.coord.facet);

            if (!acc[stackId]) {
                acc[stackId] = {};
            }

            const axisIndex = point.coord[axis];

            if (isNumber(axisIndex)) {
                if (!acc[stackId][axisIndex]) {
                    acc[stackId][axisIndex] = 0;
                }

                acc[stackId][axisIndex] = acc[stackId][axisIndex] + point.value;
            }

            return acc;
        }, stacks);

        return stacks;
    }

    protected buildSeries(barsSeriesContext: BarsSeriesContext): Array<BarSeriesOption> {
        let stacks: Record<string, Record<number, number>>;

        const valuePosition = barsSeriesContext.mainAxis === EChartAxis.X ? 1 : 0;
        const labelsResolution = Math.max(barsSeriesContext.chartWidth, barsSeriesContext.chartHeight);
        const hasBothAxes = barsSeriesContext.chartDef.genericMeasures.some(measure => measure.displayAxis === DisplayAxis.axis1) && barsSeriesContext.chartDef.genericMeasures.some(measure => measure.displayAxis === DisplayAxis.axis2);

        if (barsSeriesContext.stacked) {
            stacks = this.stackAxisValues(barsSeriesContext.mainAxis, barsSeriesContext.matrixPoints);
        }

        let seriesMap: Record<string, BarSeriesOption> = {};

        seriesMap = barsSeriesContext.matrixPoints.reduce((acc, point) => {
            if (barsSeriesContext.frameIndex === point.coord.animation) {
                const facetIndex = point.coord.facet;
                const colorIndex = isNumber(point.coord.color) ? point.coord.color : 0;
                const measureIndex = point.coord.measure;

                const measure = barsSeriesContext.chartDef.genericMeasures[measureIndex];
                const serieId = this.getSeriesId(point.coord);
                const stackId = this.getStackId(barsSeriesContext.frameIndex, facetIndex);

                if (!acc[serieId]) {
                    const color = barsSeriesContext.colorScale(colorIndex + measureIndex);

                    acc[serieId] = this.buildOneSeries(
                        serieId,
                        barsSeriesContext.chartDef,
                        measure,
                        barsSeriesContext.chartData.getAggrExtent(measureIndex),
                        labelsResolution,
                        color,
                        point.colorLabel,
                        barsSeriesContext.labelPosition || EChartLabelPosition.TOP,
                        (params, defaultFormatter) => {
                            /*
                             * Echarts accepts either a simple value or an array of two values (x and y) as an item of its dataset
                             * If it is an array, we get either the first or the second value depending on the current main axis
                             */
                            let value = Array.isArray(params.value) ? params.value[valuePosition] : params.value;

                            if (barsSeriesContext.chartDef.axis1LogScale && this.isMeasureComputedInPercentage(measure)) {
                                //  While in log scale, percent values starts from 1 instead of 0, we need to subtract 1 for formatted value
                                value = value - 1;
                            }

                            if (value === 0) {
                                return '';
                            }

                            return defaultFormatter(value);
                        }
                    ) as BarSeriesOption;

                    acc[serieId] = {
                        ...acc[serieId],
                        type: 'bar',
                        barGap: 0,
                        barCategoryGap: '40%', // When adding a color, we set a gap to distinguish bars between each color
                        emphasis: {
                            focus: 'series'
                        }
                    };

                    if (barsSeriesContext.stacked) {
                        acc[serieId].stack = stackId;
                    }

                    if (barsSeriesContext.mainAxis === EChartAxis.X) {
                        /**
                         *  If measure is displayed on right axis (axis2),
                         *  we should check if both axes are displayed to set echart's yAxisIndex
                         *  0 if it is the only axis displayed
                         *  1 if the left axis already exists
                         */
                        if (measure.displayAxis === DisplayAxis.axis2) {
                            acc[serieId].yAxisIndex = hasBothAxes ? 1 : 0;
                        } else {
                            acc[serieId].yAxisIndex = 0;
                        }
                    }
                }

                let value = point.value;

                const mainAxisIndex = point.coord[barsSeriesContext.mainAxis];

                if (isNumber(mainAxisIndex) && stacks && barsSeriesContext.chartDef.variant === ChartVariant.stacked_100) {
                    value = point.value / stacks[stackId][mainAxisIndex];
                }

                const axisValue = this.getAxisValue(point.axisValues, barsSeriesContext.mainAxisType);
                const valuePair = valuePosition ? [axisValue, value] : [value, axisValue];

                if (Array.isArray(acc[serieId].data)) {
                    acc[serieId].data?.push(valuePair);
                }
            }

            return acc;
        }, seriesMap);

        return Object.values(seriesMap);
    }
}
