<div>
    <experiment-tracking-header>
        {{ rightHeader }}
    </experiment-tracking-header>
    <div class="dss-page">
        <api-error-alert *ngIf="error" [apiError]="error" [closable]="false"></api-error-alert>
        <ng-container *ngIf="experiments$ | async as experiments">
            <div class="h100 vertical-flex experiments-table oys">
                <form class="experiments-table-actions"
                      [formGroup]="form">
                    <!-- refresh -->
                    <button *ngIf="experiments?.length"
                            class="refresh-button link-std mleft8 btn btn--text btn--secondary"
                            (click)="refresh()"
                            [qa]="'experiments-refresh-button'"
                            [wt1Click]="'experiment-tracking-refresh-experiments'">
                        <i class="icon-refresh"></i>
                    </button>
                    <div *ngIf="experiments?.length"
                         class="noflex dropdown mass-action-btn mleft8">
                        <input type="checkbox"
                               (change)="masterToggle(experiments)"
                               [checked]="hasSelectedExperiments() && isAllSelected(experiments)"
                               [indeterminate]="hasSelectedExperiments() && !isAllSelected(experiments)"
                               [qa]="'experiments-mass-actions-check-all'"
                               [wt1Click]="'experiment-tracking-toggle-all-experiments'">
                        <a data-toggle="dropdown"
                           class="dropdown-toggle unselectable"
                           [qa]="'experiments-mass-actions-menu'"
                           [ngClass]="{disabled: !hasSelectedExperiments(), collapsed: !experiments.length}">
                            <span>ACTIONS</span>
                            <b class="caret"></b>
                        </a>
                        <ul class="dropdown-menu">
                            <li [ngClass]="{disabled: !hasSelectedExperiments()}">
                                <a (click)="createExperimentsDataset()"
                                   [qa]="{'experiments-mass-actions': {selection: 'create-dataset'}}"
                                   [wt1Click]="'experiment-tracking-create-dataset'">
                                    <i class="icon-dataset"></i>
                                    Create dataset
                                </a>
                            </li>
                            <li [ngClass]="{disabled: !hasSelectedExperiments()}">
                                <a (click)="viewSelectionRuns()"
                                   [qa]="{'experiments-mass-actions': {selection: 'compare'}}"
                                   [wt1Click]="'experiment-tracking-view-selected-experiments-runs'">
                                    <i class="icon-list"></i>
                                    Compare
                                </a>
                            </li>
                            <li [ngClass]="{disabled: !hasSelectedDeletedExperiments(experiments)}">
                                <a (click)="restoreSelected(experiments)"
                                   [qa]="{'experiments-mass-actions': {selection: 'restore'}}"><i class="icon-undo"></i>Restore</a>
                            </li>
                            <li [ngClass]="{disabled: !hasSelectedActiveExperiments(experiments)}">
                                <a (click)="deleteSelected(experiments)"
                                   [qa]="{'experiments-mass-actions': {selection: 'delete'}}"><i class="icon-archive"></i>Delete</a>
                            </li>
                        </ul>
                    </div>
                    <!-- search -->
                    <div *ngIf="experiments?.length"
                         class="std-list-search-box list-control-widget mleft16">
                        <span class="add-on">
                            <i class="icon-dku-search"></i>
                        </span>
                        <input type="search" placeholder="Search..." style="width: 200px" formControlName="querySearch" [qa]="'experiments-search-input'">
                    </div>
                    <div class="dib view-type-group">
                        <input id="viewAllExperiments" type="checkbox" formControlName="viewAll" [qa]="'experiments-view-all-checkbox'">
                        <label for="viewAllExperiments">Show deleted</label>
                    </div>
                    <div *ngIf="experiments?.length"
                         class="dib btn-group pull-right">
                        <button class="btn btn--secondary mright8 btn-group__experiments-dataset"
                                (click)="createExperimentsDataset()"
                                [qa]="'experiments-create-dataset-button'"
                                [wt1Click]="'experiment-tracking-create-dataset'">
                            <i class="icon-dataset"></i> Create dataset
                            <ng-container *ngIf="this.selectedExperimentIds.size == 0"> (All experiments)</ng-container>
                            <ng-container *ngIf="this.selectedExperimentIds.size > 0">
                                ({{this.selectedExperimentIds.size}} {{" experiment" | plurify: this.selectedExperimentIds.size}})
                            </ng-container>
                        </button>
                        <button class="btn btn--primary"
                                (click)="viewSelectionRuns()"
                                [disabled]="!hasSelectedExperiments()"
                                [qa]="'experiments-compare-button'"
                                [wt1Click]="'experiment-tracking-view-selected-experiments-runs'">
                            <i class="icon-list"></i> Compare
                        </button>
                    </div>
                </form>

                <div *ngIf="experiments?.length"
                     class="experiments-table-wrapper">
                    <table *ngIf="querySort$ | async as querySort"
                           mat-table class="dku-mat-table table table-striped table-hover"
                           [dataSource]="dataSource"
                           (matSortChange)="onSortChange($event)"
                           [matSortActive]="querySort?.active ?? ''"
                           [matSortDirection]="querySort?.direction ?? ''"
                           [trackBy]="trackExperimentBy"
                           matSort>
                        <!-- Selection -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef
                                class="select-cell"
                                [qa]="{'experiments-table-header': {columnName: 'select'}}"
                                [attr.rowspan]="tagsHeaders.length ? 2 : 1">
                            </th>
                            <td mat-cell *matCellDef="let experiment"
                                class="select-cell"
                                [qa]="{'experiments-table-cell': {columnName: 'select', experimentId: experiment.id}}">
                                <input type="checkbox"
                                       (click)="$event.stopPropagation()"
                                       (change)="toggleExperimentSelection(experiment)"
                                       [checked]="isExperimentSelected(experiment)"
                                       [wt1Click]="'experiment-tracking-toggle-experiment'">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="deleted">
                            <th mat-header-cell *matHeaderCellDef
                                class="deleted-cell"
                                [qa]="{'experiments-table-header': {columnName: 'deleted'}}"
                                [attr.rowspan]="tagsHeaders.length ? 2 : 1"></th>
                            <td mat-cell *matCellDef="let experiment"
                                class="deleted-cell"
                                [qa]="{'experiments-table-cell': {columnName: 'deleted', experimentId: experiment.id}}"
                                matTooltip="Deleted experiments can be permanently removed with a Project macro">
                                <i *ngIf="experiment.deletedTooltip" class="icon icon-archive"></i>
                            </td>
                        </ng-container>
                        <!-- Experiment Name -->
                        <ng-container matColumnDef="xp-name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="mx-textellipsis"
                                [qa]="{'experiments-table-header': {columnName: 'xp-name'}}"
                                [attr.rowspan]="tagsHeaders.length ? 2 : 1">
                                Experiment
                            </th>
                            <td mat-cell *matCellDef="let experiment"
                                class="mx-textellipsis"
                                [matTooltip]="experiment.experimentName"
                                [qa]="{'experiments-table-cell': {columnName: 'xp-name', experimentId: experiment.id}}"
                                isEllipsisActive>
                                <a [href]="experiment.href" [wt1Click]="'experiment-tracking-view-one-experiment-runs'">
                                    {{ experiment.experimentName }}
                                </a>
                            </td>
                        </ng-container>
                        <!-- Creation -->
                        <ng-container matColumnDef="xp-creationTime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="mx-textellipsis"
                                [qa]="{'experiments-table-header': {columnName: 'xp-creationTime'}}"
                                [attr.rowspan]="tagsHeaders.length ? 2 : 1">
                                Creation</th>
                            <td mat-cell *matCellDef="let experiment"
                                class="mx-textellipsis"
                                [matTooltip]="experiment.creationTime | YYYYMMDDHHmmssDateTime"
                                [qa]="{'experiments-table-cell': {columnName: 'xp-creationTime', experimentId: experiment.id}}"
                                isEllipsisActive>
                                <span>{{ experiment.creationTime|YYYYMMDDHHmmssDateTime }}</span>
                            </td>
                        </ng-container>
                        <!-- Run count -->
                        <ng-container matColumnDef="xp-runCount">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="mx-textellipsis"
                                [qa]="{'experiments-table-header': {columnName: 'xp-runCount'}}"
                                [attr.rowspan]="tagsHeaders.length ? 2 : 1">
                                Runs</th>
                            <td mat-cell *matCellDef="let experiment"
                                class="mx-textellipsis"
                                [matTooltip]="experiment.runCount"
                                [qa]="{'experiments-table-cell': {columnName: 'xp-runCount', experimentId: experiment.id}}"
                                isEllipsisActive>
                                <span>{{ experiment.runCount }}</span>
                            </td>
                        </ng-container>
                        <!-- Last run start -->
                        <ng-container matColumnDef="xp-lastRunStart">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="mx-textellipsis"
                                [qa]="{'experiments-table-header': {columnName: 'xp-lastRunStart'}}"
                                [attr.rowspan]="tagsHeaders.length ? 2 : 1">
                                Last run start
                            </th>
                            <td mat-cell *matCellDef="let experiment"
                                class="mx-textellipsis"
                                [matTooltip]="experiment.lastRunStart ? (experiment.lastRunStart|YYYYMMDDHHmmssDateTime) : '-'"
                                [qa]="{'experiments-table-cell': {columnName: 'xp-lastRunStart', experimentId: experiment.id}}"
                                isEllipsisActive>
                                <span>{{ experiment.lastRunStart ? (experiment.lastRunStart | YYYYMMDDHHmmssDateTime) : '-' }}</span>
                            </td>
                        </ng-container>
                        <!-- Header group tags -->
                        <ng-container matColumnDef="group-tags">
                            <th mat-header-cell *matHeaderCellDef
                                class="first-group-cell mx-textellipsis"
                                [qa]="{'experiments-table-header-group': {groupColumnName: 'tags'}}"
                                [attr.colspan]="tagsHeaders.length">
                                Tags
                            </th>
                        </ng-container>
                        <!-- Tags Columns -->
                        <ng-container *ngFor="let column of tagsColumns"
                                      [matColumnDef]="column.columnDef">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                class="mx-textellipsis"
                                [qa]="{'experiments-table-header': {columnName: column.columnDef}}"
                                [ngClass]="{'first-group-cell': column.isFirstGroupCell}">
                                {{ column.header }}
                            </th>
                            <td mat-cell *matCellDef="let experiment"
                                class="mx-textellipsis"
                                [ngClass]="{'first-group-cell': column.isFirstGroupCell}"
                                [matTooltip]="experiment.tagsMap.get(column.header) || '-'"
                                [qa]="{'experiments-table-cell': {columnName: column.columnDef, experimentId: experiment.id}}"
                                isEllipsisActive>
                                <span>
                                    {{ experiment.tagsMap.get(column.valueKey) || "-" }}
                                </span>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedHeaders; sticky: true">
                        </tr>
                        <ng-container *ngIf="tagsHeaders.length">
                            <tr mat-header-row *matHeaderRowDef="tagsHeaders; sticky: true">
                            </tr>
                        </ng-container>
                        <tr mat-row *matRowDef="let experiment; columns: displayedColumns"
                            [ngClass]="{'greyed-row': experiment.deletedTooltip}">
                        </tr>
                    </table>
                </div>

                <div *ngIf="!experiments?.length"
                     class="flex empty-list-cta section">
                    <h1 [qa]="'experiments-empty-text'">
                        No Experiment in this project
                    </h1>
                    <p [qa]="'experiments-empty-description-text'">
                        Experiments allow you to store and compare code-based
                        machine learning models. They are created in notebooks
                        or in an external IDE using the MLflow Python client.
                    </p>
                    <p>
                        <a target="_blank" href="https://doc.dataiku.com/dss/latest/mlops/experiment-tracking/index.html"
                           [qa]="'experiments-empty-read-documentation-link'">Read
                            the documentation</a> to learn more.
                    </p>
                    <div>
                        <button class="btn btn--secondary btn--large"
                                (click)="refresh()"
                                [qa]="'experiments-empty-refresh-button'"
                                [wt1Click]="'experiment-tracking-refresh-experiments'">
                            <i class="icon-refresh"></i>&nbsp;Refresh
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
