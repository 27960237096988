import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { FacetMap, Feature, FeatureGroup } from "./feature-store-models";
import { formatItemName, formatProjectNameOrKey } from "./highlight-utils";
import { Hit } from "./query-result-models";

@Directive()
export abstract class SearchResultsComponent {
  @Input() items?: Hit<FeatureGroup | Feature>[];
  @Input() selectedItem?: Hit<FeatureGroup | Feature>;
  @Input() searchQuery!: string;
  @Input() facetMap!: FacetMap;
  @Input() projectKey?: string;
  @Input() datasetsInCurrentProject!: string[];
  @Output() resetFiltersEvent: EventEmitter<void> = new EventEmitter();

  constructor() {}

  noResults() {
    return this.items && this.items.length == 0;
  }

  noFiltersInSearch() {
    return !this.searchQuery && !this.hasFacets();
  }

  hasFacets() {
    return Object.keys(this.facetMap).length > 0;
  }

  formatItemProjectName(feature: Hit<FeatureGroup | Feature>) {
    return formatProjectNameOrKey(feature.highlight || {}, feature._source.projectName || "");
  }

  formatItemName(featureGroup: Hit<FeatureGroup | Feature>) {
    return formatItemName(featureGroup.highlight || {}, featureGroup._source.name);
  }

  resetSearch() {
    this.resetFiltersEvent.emit();
  }
}
