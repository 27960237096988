import { Inject, Injectable } from '@angular/core';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { FrontendChartDef } from '../../../../interfaces';
import { ChartDimensionService, ChartFormattingService } from '../../../../services';
import { EChartsAxesService, EChartLabelPosition, EChartMatrixPoint, EChartOptionsContext, EChartSeriesContext, EChartAxis, PrepareDataService } from '../../../echarts';
import { VerticalBarsEChartDef } from './vertical-bars-echart-def.model';

@Injectable({
    providedIn: 'root'
})
export class StackedVerticalBarsEChartDefService extends VerticalBarsEChartDef {
    constructor(
        @Inject('StringUtils') protected stringUtils: any,
        @Inject('ChartStoreFactory') chartStoreFactory: any,
        @Inject('CHART_FORMATTING_OPTIONS') chartFormattingOptions: any,
        @Inject('ChartLabels') chartLabelsService: any,
        chartDimensionService: ChartDimensionService,
        chartFormattingService: ChartFormattingService,
        prepareDataService: PrepareDataService,
        echartsAxesService: EChartsAxesService
    ) {
        super(stringUtils, chartStoreFactory, chartFormattingOptions, chartLabelsService, chartDimensionService, chartFormattingService, prepareDataService, echartsAxesService);
    }

    protected getAxesSpecs(chartDef: FrontendChartDef, matrixPoints: Array<EChartMatrixPoint>): any {
        return this.buildAxesSpecs(
            chartDef.genericMeasures,
            chartDef.variant,
            chartDef.xCustomExtent,
            chartDef.yCustomExtent,
            chartDef.genericDimension0[0],
            chartDef.genericDimension1[0],
            chartDef.axis2LogScale,
            matrixPoints,
            true
        );
    }

    protected getSeries({
        chartDef,
        chartData,
        chartWidth,
        chartHeight,
        colorScale,
        chartPoints,
        xAxes,
        frameIndex
    }: EChartSeriesContext): Array<BarSeriesOption> {
        if (chartDef && chartData && chartWidth && chartHeight && colorScale && xAxes) {
            let series = this.buildSeries({
                chartDef,
                chartData,
                chartWidth,
                chartHeight,
                colorScale,
                matrixPoints: chartPoints as Array<EChartMatrixPoint>,
                mainAxis: EChartAxis.X,
                mainAxisType: xAxes[0].type,
                stacked: true,
                labelPosition: EChartLabelPosition.INSIDE,
                frameIndex
            });
            series = this.addSafeguards(series, xAxes);
            return series;
        } else {
            throw new Error('Missing properties to build series for stacked vertical bars echart');
        }
    }

    protected getOptions({ xAxes, yAxes, series, gridOptions }: EChartOptionsContext): EChartsOption {
        if (xAxes && yAxes) {
            return this.buildOptions(xAxes, yAxes, series, gridOptions);
        } else {
            throw new Error('Missing properties to build options for stacked vertical bars echart');
        }
    }
}
