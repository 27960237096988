import { Component, HostBinding, Input } from '@angular/core';
import { animate, style, transition, trigger, state } from '@angular/animations';

@Component({
    selector: 'workspace-icon',
    template: `<span class="workspace-icon__initials" [style.color]="color" [textContent]="name | initials"></span>`,
    styleUrls: ['./workspace-icon.component.less']
})
export class WorkspaceIconComponent {
    @Input() name: string;
    @Input() color: string;
    @Input() size = 32;

    @HostBinding('style.backgroundColor') get backgroundColor() { return this.color; }
    @HostBinding('style.--icon-size') get iconSize() { return `${this.size}px`; }
    @HostBinding('style.--font-size') get fontSize() { return `${Math.ceil(this.size * 13 / 32)}px`; }

}
