import { Inject, Injectable } from '@angular/core';
import { PTScatterResponse } from '@model-main/pivot/backend/model/scatter/ptscatter-response';
import { ChartDef } from '@model-main/pivot/frontend/model/chart-def';
import { EChartsOption, GridComponentOption, LineSeriesOption, ScatterSeriesOption, XAXisComponentOption, YAXisComponentOption } from 'echarts';
import { ChartAxisTypes, ChartModes } from '../../../enums';
import { ChartCoordinates, ChartPoint, FrontendChartDef } from '../../../interfaces';
import { ChartFormattingService, NumberFormatterService } from '../../../services';
import { EChartsAxesService, EChartMatrixPoint, EChartSeriesContext, EChartOptionsContext, Cartesian2DEChartDef, EChartLegendContext } from '../../echarts';
import { ChartScatterDataWrapper } from './chart-scatter-data-wrapper.model';
import { EChartBubblePoint } from './echart-bubble-point.interface';
import { ScatterAxesDef } from './scatter-axes-def.interface';
import { ScatterPrepareDataService } from './scatter-prepare-data.service';

@Injectable({
    providedIn: 'root'
})
export class ScatterEChartDefService extends Cartesian2DEChartDef {
    onLegendHover = (legendContext: EChartLegendContext): void => {
        if (legendContext.mouseEnter) {
            let dataIndices;
            if (legendContext.item.shape && legendContext.item.color === 'grey') {
                dataIndices = this.prepareDataService.symbolLegendMapping.get(legendContext.item.shape.unicode)?.filter(m => m.label === legendContext.item.label).map(m => m.index);
            } else if (legendContext.item.color) {
                dataIndices = this.prepareDataService.colorsLegendMapping.get(legendContext.item.color)?.filter(m => m.label === legendContext.item.label).map(m => m.index);
            }
            legendContext.echartInstance.dispatchAction({
                type: 'highlight',
                dataIndex: dataIndices
            });
        } else {
            legendContext.echartInstance.dispatchAction({ type: 'downplay' });
        }
    };

    constructor(
        public prepareDataService: ScatterPrepareDataService,
        public numberFormatterService: NumberFormatterService,
        chartFormattingService: ChartFormattingService,
        echartsAxesService: EChartsAxesService,
        @Inject('StringUtils') stringUtils: any,
        @Inject('ChartStoreFactory') chartStoreFactory: any,
        @Inject('ChartLabels') chartLabelsService: any,
        @Inject('CHART_FORMATTING_OPTIONS') chartFormattingOptions: any,
        @Inject('ChartColorUtils') public chartColorUtilsService: any,
        @Inject('ChartDataUtils') public chartDataUtilsService: any,
        @Inject('ChartFeatures') public chartFeaturesService: any
    ) {
        super(
            stringUtils,
            chartStoreFactory,
            chartFormattingOptions,
            chartLabelsService,
            chartFormattingService,
            prepareDataService,
            echartsAxesService
        );
    }

    protected wrapData(data: PTScatterResponse, axesDef: ScatterAxesDef): ChartScatterDataWrapper {
        return new ChartScatterDataWrapper(data, axesDef, this.chartColorUtilsService, this.numberFormatterService, this.chartDataUtilsService);
    }

    protected buildSeries(
        chartDef: FrontendChartDef,
        chartData: ChartScatterDataWrapper,
        matrixPoints: Array<EChartBubblePoint>,
        xAxis: XAXisComponentOption,
        yAxis: YAXisComponentOption
    ): Array<ScatterSeriesOption> {

        const series = new Array<ScatterSeriesOption>();
        const data = this.computeSeriesData(matrixPoints);
        const shapeScale = chartData.makeShapeScale();
        const sizeScale = chartData.makeSizeScale(1.5);

        let serie: ScatterSeriesOption = {
            type: 'scatter',
            itemStyle: {
                opacity: chartDef.colorOptions.transparency
            },
            animation: false,
            data,
            emphasis: {
                focus: 'self'
            },
            symbolSize: ((_: any, p: any) => Math.round((chartData as any).getSymbolSize(p.dataIndex, sizeScale))) as any
        };

        if (shapeScale) {
            const shapes = (chartData.data as any).values.shape.str.data;
            serie = {
                ...serie,
                symbol: ((_: any, p: any) => shapeScale((shapes[p.dataIndex])).echartPath) as any
            };
        }

        series.push(serie);

        if (this.chartFeaturesService.hasCompatibleAxis(chartDef) && chartDef.scatterOptions && chartDef.scatterOptions.identityLine) {
            // axis min & max might be undefined if axis only has one value
            const min = Math.min(xAxis.min || data[0].value[0], yAxis.min || data[0].value[1]);
            const max = Math.max(xAxis.max || data[0].value[0], yAxis.max || data[0].value[1]);
            const identityLine: LineSeriesOption = {
                type: 'line',
                data: [ [min, min], [max, max] ],
                animation: false,
                silent: true,
                symbolSize: 0,
                itemStyle: {
                    color: this.chartFormattingOptions.SUB_COLOR
                },
                lineStyle: {
                    width: 1
                }
            };
            series.push(identityLine as any);
        }

        return series;
    }

    /**
     * @Override
     * getAllCoords returns chart coordinates which allow to associate a point to a tooltip
     * @param chartPoints
     * @returns chartCoordinates a matrix of chart coordinates used by tooltips
     */
    protected getAllCoords(chartPoints: Array<ChartPoint>): Array<Array<ChartCoordinates>> {
        return [chartPoints.map(point => point.coord)];
    }

    protected buildAxesSpecs(
        uaXDimension: any,
        uaYDimension: any,
        xCustomExtent: any,
        yCustomExtent: any,
        colorDimension: any,
        isLogScale: boolean,
        chartData: ChartScatterDataWrapper
    ): any {
        return {
            xSpec: {
                type: ChartAxisTypes.UNAGGREGATED,
                mode: ChartModes.POINTS,
                dimension: uaXDimension,
                name: 'x',
                customExtent: xCustomExtent,
                data: chartData.data.xAxis
            },
            ySpec: {
                type: ChartAxisTypes.UNAGGREGATED,
                mode: ChartModes.POINTS,
                dimension: uaYDimension,
                name: 'y',
                customExtent: yCustomExtent,
                data: chartData.data.yAxis
            },
            colorSpec: {
                type: ChartAxisTypes.UNAGGREGATED,
                name: 'color',
                dimension: colorDimension
            }
        };
    }

    protected buildOptions(xAxes: Array<XAXisComponentOption>, yAxes: Array<YAXisComponentOption>, series: Array<ScatterSeriesOption>, grid: GridComponentOption): EChartsOption {
        xAxes = this.addIntervalToTimeAxes(xAxes);

        const options: EChartsOption = {
            grid,
            xAxis: xAxes,
            yAxis: yAxes,
            series,
            progressive: 0,
            animation: false
        };

        return options;
    }

    getColorSpec(chartDef: FrontendChartDef, dataWrapper: ChartScatterDataWrapper) {
        return {
            type: ChartAxisTypes.UNAGGREGATED,
            name: 'color',
            dimension: chartDef.uaColor[0] as any,
            data: dataWrapper.data.values.color,
            withRgba: true
        };
    }

    private computeSeriesData(points: Array<EChartBubblePoint>) {
        return points.map(point => {
            const color = point.colorLabel;

            let style: any = { itemStyle: { color } };
            if (point.bubblesOptions?.singleShape === ChartDef.BubbleShape.EMPTY_CIRCLE) {
                style = {
                    itemStyle: {
                        ...style.itemStyle,
                        color: 'none',
                        borderColor: color,
                        borderWidth: 2
                    }
                };
            }
            return {
                value: [point.value, point.axisValues],
                ...style
            };
        });
    }

    protected getAxesSpecs(chartDef: FrontendChartDef, matrixPoints: Array<EChartMatrixPoint>, chartData: any): any {
        return this.buildAxesSpecs(
            chartDef.uaXDimension[0],
            chartDef.uaYDimension[0],
            chartDef.xCustomExtent,
            chartDef.yCustomExtent,
            chartDef.uaColor[0],
            false,
            chartData
        );
    }

    protected getSeries({
        chartDef,
        chartData,
        chartWidth,
        chartHeight,
        chartPoints,
        xAxes,
        yAxes
    }: EChartSeriesContext): Array<ScatterSeriesOption> {
        if (chartDef && chartData && chartWidth && chartHeight && xAxes && yAxes) {
            return this.buildSeries(
                chartDef,
                chartData,
                chartPoints as Array<EChartBubblePoint>,
                xAxes[0],
                yAxes[0]
            );
        } else {
            throw new Error('Missing properties to build series for scatter plot echart');
        }
    }

    protected getOptions({ xAxes, yAxes, series, gridOptions }: EChartOptionsContext): EChartsOption {
        if (xAxes && yAxes) {
            return this.buildOptions(xAxes, yAxes, series, gridOptions);
        } else {
            throw new Error('Missing properties to build options for scatter plot echart');
        }
    }
}
