import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceDisplayService } from '../../../../shared';
import { ObjectFilters, ObjectSort, ObjectSortProperties } from '../../models';

const EMPTY_FILTERS: ObjectFilters = {
    search: '',
    types: [],
    starred: false
};

@Component({
    selector: 'workspace-object-filters',
    templateUrl: './workspace-object-filters.component.html',
    styleUrls: ['./workspace-object-filters.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceObjectFiltersComponent implements OnChanges {

    readonly sortProperties = Object.values(ObjectSortProperties);

    @Input() objects: Workspace.WorkspaceObject[];
    @Input() filters: ObjectFilters;
    @Input() sort: ObjectSort;
    @Output() filtersChange = new EventEmitter<ObjectFilters>();
    @Output() sortChange = new EventEmitter<ObjectSort>();

    hasFilters = false;
    types: Array<string> = [];

    constructor(private workspaceDisplayService: WorkspaceDisplayService) {
        this.clearFilters();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.objects) {
            this.types = [...new Set(this.objects.map(object => this.workspaceDisplayService.getObjectType(object)))];
        }

        if (changes.filters) {
            this.hasFilters = !isEqual(this.filters, EMPTY_FILTERS);
        }
    }

    clearFilters(): void {
        this.filters = { ...EMPTY_FILTERS };
        this.filterChanged();
    }

    filterChanged(): void {
        this.hasFilters = !isEqual(this.filters, EMPTY_FILTERS);
        this.filtersChange.emit(this.filters);
    }

    sortChanged(): void {
        this.sortChange.emit(this.sort);
    }

    toggleFavorites(filters: ObjectFilters) {
        filters.starred = !filters.starred;
        this.filterChanged();
    }

    searchFilterChanged(searchTerm: string) {
        this.filters.search = searchTerm;
        this.filterChanged();
    }
}
