<form class="workspace-add-object"
      [formGroup]="addObjectsForm">

    <fieldset class="workspace-add-object__types">
        <span class="workspace-add-object__type selectable"
              [class.selected]="addObjectsForm.type.value === type"
              *ngFor="let type of TYPES">
            <input type="radio"
                   class="workspace-add-object__type-checkbox"
                   [id]="'workspace-add-' + type"
                   [value]="type"
                   name="type"
                   formControlName="type">
            <label [for]="'workspace-add-' + type">
                <span class="workspace-add-object__icon">
                    <i class="icon {{ type | typeToIcon }} {{ type | typeToColor  }}"></i>
                </span>
                {{type | niceConst}}
            </label>
        </span>
    </fieldset>

    <fieldset class="workspace-add-object__control"
              *ngIf="addObjectsForm.projectKey.enabled">
        <label for="projectKeyInput">Project</label>

        <dss-project-selector formControlName="projectKey"
                              [projects]="sharableProjects$ | async"
                              id="projectKeyInput">
        </dss-project-selector>
    </fieldset>

    <fieldset class="workspace-add-object__control"
              *ngIf="addObjectsForm.objects.enabled">
        <label for="objectPicker">{{ addObjectsForm.type.value | niceConst }}</label>

        <dss-accessible-objects-selector [objects]="objects$ | async"
                                         [type]="addObjectsForm.type.value"
                                         [multi]="true"
                                         formControlName="objects"></dss-accessible-objects-selector>

    </fieldset>

    <div class="alert alert-info"
         *ngIf="numObjectsToShare$ | async as numObjectsToShare">
        <ng-container [ngPlural]="numObjectsToShare">
            <ng-template ngPluralCase="=1">This</ng-template>
            <ng-template ngPluralCase="other">{{ numObjectsToShare }}</ng-template>
        </ng-container>
        {{ addObjectsForm.type.value| niceConst | lowercase | plurify: numObjectsToShare}}
        will be shared with workspaces and dashboard-only users.
    </div>

    <div *ngIf="addObjectsForm.url.enabled">
        <fieldset class="workspace-add-object__control">
            <label for="objectUrl">URL</label>
            <input id="objectUrl"
                   type="url"
                   formControlName="url"
                   placeholder="https://example.com"
                   (blur)="sanitizeURL($event)">
        </fieldset>

        <fieldset class="workspace-add-object__control">
            <label for="objectTitle">Title</label>
            <input id="objectTitle"
                   type="text"
                   formControlName="name"
                   placeholder="Title">
        </fieldset>

        <fieldset class="workspace-add-object__control">
            <label for="objectDescription">Description</label>
            <autocompletable-textarea
                id="objectDescription"
                [(model)]="urlDescription"
                (modelChange)="updateDescription()"
                [textarea]="{ name: 'description', placeholder: 'Description (optional)' }"
                options="{ autofocus: false }">
            </autocompletable-textarea>
        </fieldset>
    </div>

</form>