import type {AxisDef} from './../../backend/model/axis-def';

/**
 * Generated from com.dataiku.dip.pivot.frontend.model.ChartFilter
 */
export interface ChartFilter {
    active: boolean;
    allValuesInSample: boolean;
    column: string;
    columnType: AxisDef.Type;
    dateFilterOption?: ChartFilter.DateRelativeOption | null;
    dateFilterPart?: ChartFilter.DateFilterPart | null;
    dateFilterType?: ChartFilter.DateFilterType | null;
    excludeOtherValues: boolean;
    excludedValues?: {[key: string]: boolean} | null;
    explicitConditions?: ChartFilter.ExplicitCondition[] | null;
    explicitExclude?: boolean | null;
    filterType: ChartFilter.FilterType;
    isA: string;
    isAGlobalFilter: boolean;
    maxValue?: number | null;
    minValue?: number | null;
    selectedValues?: {[key: string]: boolean} | null;
}

export namespace ChartFilter {
    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartFilter$ExplicitCondition
     */
    export interface ExplicitCondition {
        column: string;
        columnType: AxisDef.Type;
        dateFilterType: ChartFilter.DateFilterType;
        maxValue: number;
        minValue: number;
        singleValue: string;
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartFilter$DateFilterPart
     */
    export enum DateFilterPart {
        HOUR_OF_DAY = 'HOUR_OF_DAY',
        DAY_OF_WEEK = 'DAY_OF_WEEK',
        DAY_OF_MONTH = 'DAY_OF_MONTH',
        MONTH_OF_YEAR = 'MONTH_OF_YEAR',
        WEEK_OF_YEAR = 'WEEK_OF_YEAR',
        QUARTER_OF_YEAR = 'QUARTER_OF_YEAR',
        YEAR = 'YEAR',
        INDIVIDUAL = 'INDIVIDUAL'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartFilter$FilterType
     */
    export enum FilterType {
        NUMERICAL_FACET = 'NUMERICAL_FACET',
        DATE_FACET = 'DATE_FACET',
        INTERACTIVE_DATE_FACET = 'INTERACTIVE_DATE_FACET',
        ALPHANUM_FACET = 'ALPHANUM_FACET',
        EXPLICIT = 'EXPLICIT'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartFilter$DateRelativeOption
     */
    export enum DateRelativeOption {
        THIS = 'THIS',
        LAST = 'LAST',
        NEXT = 'NEXT',
        TO = 'TO'
    }

    /**
     * Generated from com.dataiku.dip.pivot.frontend.model.ChartFilter$DateFilterType
     */
    export enum DateFilterType {
        RANGE = 'RANGE',
        RELATIVE = 'RELATIVE',
        PART = 'PART',
        HOUR_OF_DAY = 'HOUR_OF_DAY',
        DAY_OF_WEEK = 'DAY_OF_WEEK',
        DAY_OF_MONTH = 'DAY_OF_MONTH',
        MONTH_OF_YEAR = 'MONTH_OF_YEAR',
        WEEK_OF_YEAR = 'WEEK_OF_YEAR',
        QUARTER_OF_YEAR = 'QUARTER_OF_YEAR',
        YEAR = 'YEAR'
    }
}