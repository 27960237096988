
<clearable-searchbar
    class="workspace-object-filters__search"
    (searchTermChange)="searchFilterChanged($event)"
    [hasPrefixIcon]="true"
    [isClearable]="true">
</clearable-searchbar>

<mat-form-field>
    <mat-select [(value)]="filters.types" (valueChange)="filterChanged()" multiple placeholder="Type" disableOptionCentering class="btn--secondary btn--text">
        <mat-select-trigger>
            <b *ngIf="filters.types.length">{{ filters.types.length }}</b>
            {{ 'Type' | plurify: (filters.types.length || 0) }}
        </mat-select-trigger>
        <mat-option *ngFor="let type of types" [value]="type" class="workspace-object-filters__select-option">
            <i [ngClass]="[type | typeToIcon, type | typeToColor]" class="mright8"></i>
            <span>{{ type | niceConst }}</span>
        </mat-option>
    </mat-select>
</mat-form-field>

<button class="workspace-object-filters__favorite btn btn--text btn--secondary btn--select" (click)="toggleFavorites(filters)">
    <i class="icon-star" [class.active]="filters.starred"></i>
    Favorites
</button>

<button *ngIf="hasFilters"
    type="button" 
    class="btn btn--text btn--primary mright8" 
    (click)="clearFilters()">
    Clear filters
</button>

<sort-order-button class="workspace-object-filters__sort-order" [(value)]="sort.reverse" (valueChange)="sortChanged()">
</sort-order-button>

<mat-form-field>
    <mat-select [(value)]="sort.by" (valueChange)="sortChanged()" disableOptionCentering class="btn--secondary btn--text">
        <mat-option *ngFor="let sortType of sortProperties" [value]="sortType" class="workspace-object-filters__select-option">
            {{ sortType }}
        </mat-option>
    </mat-select>
</mat-form-field>
