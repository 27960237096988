import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { NavItems } from "@core/nav/nav-items.enum";
import { NavTabs } from "@core/nav/nav-tabs.enum";
import { NavUniverses } from "@core/nav/nav-universes.enum";
import { TopNavService } from "@core/nav/top-nav.service";
import { PromptService } from "@shared/modals/prompt.service";
import { assertNever, fairAny } from "dku-frontend-core";
import _, { pick } from "lodash";
import { catchError, forkJoin, from, of, switchMap, mergeMap } from "rxjs";
import { FeatureGroupDetails } from "src/generated-sources";
import { FacetListComponent } from "./facet-list/facet-list.component";
import { AggregationResults, FacetMap, Feature, FeatureGroup, SearchOptions, ViewBy } from "./feature-store-models";
import { FeatureStoreService } from "./feature-store.service";
import { Highlight, Hit } from "./query-result-models";

@Component({
  selector: "feature-store",
  templateUrl: "./feature-store.component.html",
  styleUrls: ["./feature-store.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureStoreComponent implements OnInit {
  @Input() projectKey?: string;

  users: Record<string, string> = {};
  projectNames: Record<string, string> = {};
  searchQuery: string = "";
  sortedBy: string = "score";
  reverseOrder: boolean = true;
  viewBy: ViewBy = ViewBy.FEATURE_GROUP;
  activeFacets: FacetMap = {};
  totalItems: number = 0;
  featureGroups?: Hit<FeatureGroup>[];
  features?: Hit<Feature>[];
  aggregationResults: AggregationResults = {};
  selectedFeatureGroup?: Hit<FeatureGroup>;
  selectedFeature?: Hit<Feature>;
  details?: FeatureGroupDetails;
  featureGroupHighlight: Highlight = {};
  featureHighlight: Highlight = {};
  datasetsInCurrentProject: string[] = [];

  @ViewChild(FacetListComponent)
  private facetList!: FacetListComponent;

  constructor(
    @Inject("TaggingService") private taggingService: fairAny,
    @Inject("StateUtils") private stateUtils: fairAny,
    public featureStoreService: FeatureStoreService,
    private promptService: PromptService,
    private changeDetectorRef: ChangeDetectorRef,
    private topNav: TopNavService
  ) {}

  ngOnInit() {
    if (this.projectKey) {
      this.topNav.setLocation(NavUniverses.FLOW, NavItems.DATASET, NavTabs.NONE);
    } else {
      this.topNav.setLocation(NavUniverses.DSS_HOME, NavItems.FEATURE_STORE);
    }
    this.topNav.setNoItem();
    this.taggingService.fetchGlobalTags();

    forkJoin([
      this.featureStoreService.listUsers(),
      this.featureStoreService.listProjects(),
      this.featureStoreService.listTags(),
      this.projectKey ? this.featureStoreService.listAccessibleDatasetsInProject(this.projectKey) : of([]),
    ]).subscribe(([usersResponse, projectsResponse, tagsResponse, accessiblesResponse]) => {
      this.datasetsInCurrentProject = accessiblesResponse.map((d) => d.projectKey + "." + d.id);
      usersResponse.forEach((user: any) => {
        this.users[user.login] = user.displayName;
      });
      projectsResponse.forEach((project) => {
        this.projectNames[project.projectKey] = project.name;
      });
      this.featureStoreService.pushTagsMap(tagsResponse);
      this.search();
    });
  }

  search() {
    switch (this.viewBy) {
      case ViewBy.FEATURE_GROUP:
        this.featureStoreService.searchFeatureGroups(this.searchQuery, this.activeFacets).subscribe((results) => {
          this.totalItems = results.hits.total;
          this.selectedFeatureGroup = results.hits.hits.find((h) => h._id === this.selectedFeatureGroup?._id);
          if (!this.selectedFeatureGroup) {
            this.details = undefined;
          } else {
            this.featureGroupHighlight = this.selectedFeatureGroup.highlight || {};
          }
          this.aggregationResults = results.aggregations;
          this.featureGroups = this.sortItems(results.hits.hits);
          this.changeDetectorRef.markForCheck();
        });
        break;
      case ViewBy.FEATURE_NAME:
        this.featureStoreService.searchFeatures(this.searchQuery, this.activeFacets).subscribe((results) => {
          this.totalItems = results.hits.total;
          this.selectedFeature = results.hits.hits.find((h) => h._id === this.selectedFeature?._id);
          if (!this.selectedFeature) {
            this.details = undefined;
          } else {
            this.featureGroupHighlight = this.selectedFeature.highlight || {};
          }
          this.features = this.sortItems(results.hits.hits);
          this.aggregationResults = results.aggregations;
          this.changeDetectorRef.markForCheck();
        });
        break;
      default:
        assertNever(this.viewBy);
    }
  }

  facetsForFeatureGroups() {
    return pick(this.activeFacets, "tag.raw", "projectKey.raw", "user", "partitioned", "isQuicklyShareable");
  }

  facetsForFeatures() {
    return pick(this.activeFacets, "tag.raw", "projectKey.raw", "user", "meaning", "isQuicklyShareable");
  }

  viewByFeatureGroup() {
    return this.viewBy === ViewBy.FEATURE_GROUP;
  }

  viewByFeatureName() {
    return this.viewBy === ViewBy.FEATURE_NAME;
  }

  getSortAttribute(h: Hit<FeatureGroup | Feature>) {
    if (this.sortedBy === "createdOn") return h._source.createdOn;
    else if (this.sortedBy === "lastModifiedOn") return h._source.lastModifiedOn;
    else return h._score;
  }

  sortItems<T extends FeatureGroup | Feature>(items?: Hit<T>[]) {
    if (!items) {
      return items;
    } else {
      // using slice() to change the array reference
      const sorted = items.slice().sort((a, b) => this.getSortAttribute(b) - this.getSortAttribute(a));
      return this.reverseOrder || this.sortedBy === "score" ? sorted : sorted.reverse();
    }
  }

  newQuery(searchOptions: SearchOptions) {
    const currentSearchOptions = this.currentSearchOptions();
    this.searchQuery = searchOptions.query;
    this.sortedBy = searchOptions.sortBy;
    this.reverseOrder = searchOptions.reverse;
    this.viewBy = searchOptions.viewBy;
    if (currentSearchOptions.viewBy !== searchOptions.viewBy) {
      this.selectedFeatureGroup = undefined;
      this.selectedFeature = undefined;
      this.details = undefined;
      this.search();
    } else if (currentSearchOptions.query !== searchOptions.query) {
      this.search();
    } else {
      if (searchOptions.viewBy === ViewBy.FEATURE_GROUP) this.featureGroups = this.sortItems(this.featureGroups);
      else this.features = this.sortItems(this.features);
      this.changeDetectorRef.markForCheck();
    }
  }

  currentSearchOptions() {
    return {
      query: this.searchQuery,
      sortBy: this.sortedBy,
      reverse: this.reverseOrder,
      viewBy: this.viewBy,
    };
  }

  newFacetSelection(facetMap: FacetMap) {
    if (!_.isEqual(facetMap, this.activeFacets)) {
      this.activeFacets = facetMap;
      this.search();
    }
  }

  newFeatureGroupSelected(featureGroup: Hit<FeatureGroup>) {
    this.featureStoreService
      .getDetails(featureGroup._source.projectKey, featureGroup._source.id)
      .subscribe((details) => {
        this.details = details;
        this.selectedFeatureGroup = featureGroup;
        this.featureGroupHighlight = featureGroup.highlight || {};
        this.featureHighlight = {};
        this.changeDetectorRef.markForCheck();
      });
  }

  useInProject() {
    if(this.details) {
      if (
        this.details.datasetFullInfo.objectAuthorizations.canManageExposedElements ||
        this.details.datasetFullInfo.objectAuthorizations.isQuicklyShareable
      ) {
        if (this.projectKey) this.featureStoreService
          .useInCurrentProject(this.details, this.projectKey)
          .subscribe();
        else this.featureStoreService
          .useInProject(this.details)
          .subscribe((details) => this.refreshFeatureGroupDetails(details));
      }
      else if (this.details.datasetFullInfo.objectAuthorizations.isObjectSharingRequestEnabled) {
        this.featureStoreService
          .requestSharing(this.details, this.projectKey)
          .subscribe((details) => this.refreshFeatureGroupDetails(details));
      }
    }
  }

  refreshFeatureGroupDetails(details: FeatureGroupDetails) {
    this.details = details;
    this.changeDetectorRef.markForCheck();
  }

  newFeatureSelected(feature: Hit<Feature>) {
    this.featureStoreService.getDetails(feature._source.projectKey, feature._source.dataset).subscribe((details) => {
      this.details = details;
      this.featureGroupHighlight = {};
      this.featureHighlight = feature.highlight || {};
      this.selectedFeature = feature;
      this.changeDetectorRef.markForCheck();
    });
  }

  resetFiltersRequest() {
    this.searchQuery = "";
    this.facetList.resetForm();
  }

  removeFromFeatureStore() {
    if (this.details && this.featureStoreService.mayManageFeatureStore()) {
      const details = this.details;
      from(this.promptService.confirm("Remove from Feature Store", "Remove this Feature Group from the Feature Store?"))
        .pipe(
          catchError(() => of()), // silently ignoring the error raised when we cancel the removal
          mergeMap(() => this.featureStoreService.removeFromFeatureStore(details)),
          switchMap(() => this.featureStoreService.flush())
        )
        .subscribe(() => this.search());
    }
  }

  currentProjectLink() {
    return this.stateUtils.href.project(this.projectKey);
  }
}
