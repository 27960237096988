import { AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Workspace } from '@model-main/workspaces/workspace';
import { fairAny } from 'dku-frontend-core';
import { BehaviorSubject } from 'rxjs';
import { EditTabs, WorkspaceModalService, WorkspaceSecurityService } from '../../../../shared';

const DESCRIPTION_HEIGHT_THRESHOLD = 32;

@Component({
    selector: 'workspace-header',
    templateUrl: './workspace-header.component.html',
    styleUrls: ['./workspace-header.component.less']
})
export class WorkspaceHeaderComponent implements OnChanges, AfterViewInit, OnDestroy {
    readonly isExpanded$ = new BehaviorSubject<boolean>(false);
    readonly canExpand$ = new BehaviorSubject<boolean>(false);
    readonly isWordBreak$ = new BehaviorSubject<boolean>(false);
    hasGroups = false;
    avatars: string[];
    resizeObserver?: ResizeObserver;
    canShareToWorkspace = false;
    containerHeight: number;
    containerWidth: number;


    @Input() workspace: Workspace;
    @ViewChild('description') descriptionEl: ElementRef<HTMLElement>;

    constructor(
        private workspaceModalService: WorkspaceModalService,
        private workspaceSecurityService: WorkspaceSecurityService,
        @Inject('StringUtils') private stringUtils: fairAny,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { workspace } = changes;
        if (workspace) {
            // Update avatars
            this.avatars = this.workspace.permissions
                .filter(permission => permission.user)
                .slice(0, 3)
                .map(permission => permission.user as string);

            this.hasGroups = this.workspace.permissions.some(permission => permission.group);
            this.canShareToWorkspace = this.workspaceSecurityService.canShareToWorkspace(workspace.currentValue);
            this.setWordBreak();
            this.setCanExpand();
        }
    }

    ngAfterViewInit(): void {
        const content = this.descriptionEl.nativeElement.firstElementChild;
        if (content) {
            this.resizeObserver = new ResizeObserver(entries => {
                if (entries.length > 0) {
                    this.containerHeight = entries[0].contentRect.height;
                    this.containerWidth = entries[0].contentRect.width;
                    this.setWordBreak();
                    this.setCanExpand();
                }
            });
            this.resizeObserver.observe(content);
        }
    }

    ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    openDeleteWorkspaceModal(): void {
        this.workspaceModalService.deleteWorkspace(this.workspace);
    }

    openEditWorkspaceModal(): void {
        this.workspaceModalService.editWorkspace(this.workspace, 'header');
    }

    openMembersModal(): void {
        this.workspaceModalService.editWorkspace(this.workspace, 'header', [EditTabs.PERMISSIONS]);
    }

    setExpanded(expanded: boolean): void {
        if (expanded !== this.isExpanded$.value) {
            this.isExpanded$.next(expanded);
            this.descriptionEl.nativeElement.scrollTop = 0;
        }
    }

    openAddObjectModal(): void {
        this.workspaceModalService.addWorkspaceObject(this.workspace);
    }

    setWordBreak() {
        if (this.workspace.description) {
            const wordLength = this.stringUtils.getTextWidth(this.workspace.description, '14px SourceSansPro');
            if (wordLength > this.containerWidth && !this.workspace.description.includes(' ')) {
                this.isWordBreak$.next(true);
            } else {
                this.isWordBreak$.next(false);
            }
        }
    }

    setCanExpand(): void {
        if (this.containerHeight <= DESCRIPTION_HEIGHT_THRESHOLD) {
            this.canExpand$.next(false);
            this.setExpanded(false);
        } else {
            this.canExpand$.next(true);
        }
    }

}
