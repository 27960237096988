import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { AutocompletableTextareaModule, UIRouterModule } from '@migration/modules';
import { MigrationModule } from '@migration/migration.module';
import { ComponentsModule } from '@shared/components/components.module';
import { ModalsModule } from '@shared/modals/modals.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DkuFrontendCoreModule } from 'dku-frontend-core';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import {
    SelectButtonComponent,
    WorkspacesLeftMenuComponent,
    WorkspacePermissionsEditionComponent,
    EditWorkspaceModalComponent,
    WorkspaceAddObjectsComponent,
    AddObjectsModalComponent,
    WorkspaceIconModule,
    WorkspaceGeneralEditionModule
} from './shared';
import { WorkspacesComponent } from './workspaces.component';
import { TextOverflowTooltipModule } from '@migration/modules';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        UIRouterModule,
        MigrationModule,
        WidgetsModule,
        MatMenuModule,
        MatDialogModule,
        MatSelectModule,
        MatTabsModule,
        MatTooltipModule,
        ComponentsModule,
        NgxMatSelectSearchModule,
        PipesModule,
        ModalsModule,
        DataikuAPIModule,
        DkuFrontendCoreModule,
        WorkspaceIconModule,
        WorkspaceGeneralEditionModule,
        TextOverflowTooltipModule,
        AutocompletableTextareaModule
    ],
    declarations: [
        WorkspacesComponent,
        SelectButtonComponent,
        WorkspacesLeftMenuComponent,
        WorkspacePermissionsEditionComponent,
        EditWorkspaceModalComponent,
        AddObjectsModalComponent,
        WorkspaceAddObjectsComponent
    ]
})
export class WorkspacesModule {}
