import { Inject, Injectable } from '@angular/core';
import { NavItems } from '@core/nav/nav-items.enum';
import { NavUniverses } from '@core/nav/nav-universes.enum';
import { TopNavService } from '@core/nav/top-nav.service';
import { DkuActivatedRouteService } from '@migration/dku-activated-route';
import { $stateType } from '@migration/upgraded-providers';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceObjectAppIdKey, WorkspaceObjectKey, WorkspaceObjectReferenceKey } from '@shared/models';
import { Observable } from 'rxjs';
import { DKURootScope, WorkspaceStates } from '../models';

@Injectable({
    providedIn: 'root'
})
export class WorkspacesNavigationService {

    readonly currentState$: Observable<string>;
    readonly params$ = this.activatedRoute.paramMap;

    constructor(
        private activatedRoute: DkuActivatedRouteService,
        private topNav: TopNavService,
        @Inject('$rootScope') private $rootScope: DKURootScope,
        @Inject('$state') private $state: $stateType
    ) {
        this.currentState$ = new Observable(observer => {
            if (this.$state.current.name.startsWith('workspaces.')) {
                observer.next(this.$state.current.name);
            }

            this.$rootScope.$on('$stateChangeSuccess', () => {
                if (this.$state.current.name.startsWith('workspaces.')) {
                    observer.next(this.$state.current.name);
                    this.topNav.setLocation(NavUniverses.DSS_HOME, NavItems.WORKSPACES);
                }
            });
        });
    }

    getParam(key: string): string | undefined {
        return this.$state.params[key];
    }

    getParams(keys: string[]): Array<string | undefined> {
        return keys.map(key => this.$state.params[key]);
    }

    updateTopNavTitle(workspace?: Workspace, title?: string): void {
        /**
         * Mandatory to update workspace title in main.html
         * Consider a service instead once main.html is migrated
         */
        if (!this.$rootScope.workspace) {
            this.$rootScope.workspace = {};
        }
        const workspaceTitle = workspace?.displayName || 'Dataiku Workspaces';
        this.$rootScope.workspace.workspaceKey = workspace?.workspaceKey;
        this.$rootScope.workspace.title = workspaceTitle;

        this.topNav.setPageTitle(title || workspaceTitle);
    }

    goToHome(): void {
        this.$state.go(WorkspaceStates.HOME);
    }

    goToWorkspace(workspaceKey: string, replace?: boolean): void {
        this.$state.go(WorkspaceStates.WORKSPACE, { workspaceKey }, replace ? { location: 'replace' } : undefined);
    }

    goToWorkspaceObject(workspaceKey: string, objectKey: WorkspaceObjectKey): void {
        const appId = (objectKey as WorkspaceObjectAppIdKey).appId;
        const reference = (objectKey as WorkspaceObjectReferenceKey).reference;

        if (appId) {
            this.$state.go(WorkspaceStates.OBJECT, {
                appId
            });
        } else if (reference) {
            this.$state.go(WorkspaceStates.OBJECT, {
                workspaceKey,
                projectKey: reference.projectKey,
                objectType: reference.type,
                objectId: reference.id
            });
        }
    }

}
