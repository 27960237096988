import { DkuHttpService, FutureWatcherService, TypedFutureResponse } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FilterRequest, NavigatorService, SerializedDataset, SerializedMemTableV2, SerializedShakerScript, SerializedTableChunk } from 'src/generated-sources';

@Injectable({providedIn: 'root'})
export class PrivateDatasetsAPI {
    constructor(
        private futureWatcher: FutureWatcherService,
        private dkuHttp: DkuHttpService) {
    }

    get(projectKey: string, name: string, contextProjectKey?: string): Observable<SerializedDataset> {
        return this.dkuHttp.request('GET', '/datasets/get', { projectKey, name, contextProjectKey });
    }

    listPartitionsWithName(projectKey: string, datasetName: string): Observable<String[]> {
        return this.dkuHttp.request('GET', '/datasets/list-partitions-with-name', { projectKey, datasetName });
    }

    getFullInfo(contextProjectKey: string, projectKey: string, name: string): Observable<NavigatorService.DatasetFullInfo> {
        return this.dkuHttp.request('GET', '/datasets/get-full-info', { projectKey, name, contextProjectKey });
    }

    refreshTable(contextProjectKey: string, projectKey:string, datasetName:string, data: SerializedShakerScript, 
                 allowCache: boolean, filters: FilterRequest, nbCols: number, requestedSampleId: string|null): Observable<TypedFutureResponse<SerializedMemTableV2>> {
        // explicitely passing requestSampleId as undefined if null, otherwise, it is sent as "null"
        return this.futureWatcher.requestFuture("POST", "/shaker/refresh-table", 
                                    {contextProjectKey, projectKey, datasetName, data: JSON.stringify(data), allowCache, filters: JSON.stringify(filters), requestedSampleId: requestedSampleId ? requestedSampleId : undefined, nbCols});
    }

    getTableChunk(contextProjectKey:string, projectKey: string, datasetName: string,
                  data: SerializedShakerScript, firstRow: number, nbRows: number,
                  firstCol: number, nbCols: number, filters: FilterRequest, requestedSampleId: string|null): Observable<SerializedTableChunk>{
        // explicitely passing requestSampleId as undefined if null, otherwise, it is sent as "null"
        return this.dkuHttp.request("POST", "/shaker/get-table-chunk", 
                                    {contextProjectKey, projectKey, datasetName, data: JSON.stringify(data), 
                                     requestedSampleId: requestedSampleId ? requestedSampleId : undefined, firstRow, nbRows, firstCol, nbCols, filters: JSON.stringify(filters)});
    }

    getForExport(projectKey: string, name:string, contextProjectKey:string = projectKey): Observable<SerializedDataset> {
        return this.dkuHttp.request("GET", "/datasets/get-for-export", {projectKey, name, contextProjectKey});
    }

    exportDS(contextProjectKey: string, projectKey: string, name: string, exportParams: any): Observable<any> {
        return this.dkuHttp.request("POST", "/datasets/export", {contextProjectKey, projectKey, name, params: JSON.stringify(exportParams)});
    }

    listPartitions(dataset: SerializedDataset): Observable<Array<String>> {
        return this.dkuHttp.request("POST", "/datasets/list-partitions", {data: JSON.stringify(dataset)});
    }
}
