import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Workspace } from '@model-main/workspaces/workspace';
import { WorkspaceNonTaggableType, WorkspacesError } from '../../models';

interface AddObjectsModalOptions {
    workspace: Workspace;
    submit: (workspaceObjects: Workspace.WorkspaceObject[]) => Observable<Workspace>;
    error: WorkspacesError;
}

interface ObjectData {
    type: string;
    id: string;
}

@Component({
    selector: 'add-objects-modal',
    templateUrl: './add-objects-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddObjectsModalComponent {

    objects: ObjectData[] = [];
    workspace: Workspace;
    error?: WorkspacesError;


    constructor(
        private dialogRef: MatDialogRef<AddObjectsModalComponent, Workspace.WorkspaceObject[] | null>,
        @Inject(MAT_DIALOG_DATA) private data: AddObjectsModalOptions
    ) {
        this.workspace = data.workspace;
        this.error = data.error;

        // Clean up error on close
        this.dialogRef.beforeClosed().subscribe(() => this.error?.resetError());
    }

    onObjectsChanged(objects: any): void {
        this.objects = objects || [];
    }

    confirm(): void {
        if (this.objects) {
            const workspaceObjects = <Workspace.WorkspaceObject[]>this.objects.map(object => {
                switch (object.type) {
                    case WorkspaceNonTaggableType.LINK:
                        return { htmlLink: object };
                    case WorkspaceNonTaggableType.APP:
                        return { appId: object.id };
                    default:
                        return { reference: object };
                }
            });

            this.data.submit(workspaceObjects)
                .subscribe(() => this.dialogRef.close(workspaceObjects));
        }
    }

    cancel(): void {
        this.dialogRef.close(null);
    }
}
