export namespace UsersDAO {
    /**
     * Generated from com.dataiku.dip.dao.UsersDAO$UserSourceType
     */
    export enum UserSourceType {
        LOCAL = 'LOCAL',
        LOCAL_NO_AUTH = 'LOCAL_NO_AUTH',
        LDAP = 'LDAP',
        SAAS = 'SAAS',
        PAM = 'PAM'
    }
}